import React from "react";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";
import Moment from "react-moment";
import {valueFixer} from "../../helpers/valueFixer";
import EmptyData from "../_UI/emptyData";
import {useTranslation} from "react-i18next";



const CabinetInvestmentDetailsOperations = () => {
    const { t } = useTranslation();

    const investDetailsOperations = useSelector(store => store.cabinet.investDetailsOperations);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.cabinetLargeCardWS}`}>
                <div className={`${Styles.titleRow}`}>
                    <div className={`${Styles.cabinetTitle1}`}>{t('Операции')}</div>
                </div>
                <div className={`${Styles.tableTitleRow}`}>
                    <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Дата')}</div>
                    <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Процент')}</div>
                    <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Сумма')}</div>
                </div>
                <div className={`${Styles.contentBox} ${investDetailsOperations?.length > 0 ? Styles.contentBoxWV : null }`}>
                    {investDetailsOperations?.length > 0 ? investDetailsOperations.map((item, index)=>{
                        return(
                            <div className={`${Styles.contentBoxItem}`} key={'transactionData' + index}>
                                <div className={`${Styles.contentBoxItemDate}`}><Moment format="DD/MM/YYYY">{item.created_at}</Moment></div>
                                <div className={`${Styles.contentBoxItemDate}`}>{item.percent * 1}%</div>
                                <div className={`${Styles.contentBoxItemAmount}`}>{valueFixer(item?.amount, 4)} {item?.currency?.code}</div>
                            </div>
                        )
                    }) : <EmptyData text={t('У вас пока что нет транзакций')}/>}
                </div>
            </div>
        </section>
    );
};

export default CabinetInvestmentDetailsOperations;