import React from "react";
import Styles from './styles.module.scss';
import balanceIcon from '../../theme/assets/svg/oli_cabinet_balance_icon.svg';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {valueShorter} from "../../helpers/valueShorter";



const CabinetBalance = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const stats = useSelector(store => store.cabinet.mainStats);
    const userData = useSelector(store => store.cabinet.userData);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.cabinetCard}`}>
                <div className={`${Styles.balanceRow}`}>
                    <div className={`${Styles.balanceIconWrap}`}>
                        <img src={balanceIcon} alt="balance icon"/>
                    </div>
                    <div className={`${Styles.balanceContentWrap}`}>
                        <div className={`${Styles.balanceTitle}`}><span>{userData ? userData?.email : ' '}</span></div>
                        <div className={`${Styles.balance}`}>{t('Баланс')}: <span>${stats?.total_balance ? valueShorter(stats.total_balance, 2) : '0'}</span></div>
                    </div>
                </div>
                <div className={`${Styles.btnsRow}`}>
                    <div className={`${Styles.cabinetBtn}`} onClick={()=>{navigate('/cabinet/topup')}}>{t('Пополнить')}</div>
                    <div className={`${Styles.cabinetBtn}`} onClick={()=>{navigate('/cabinet/withdrawal')}}>{t('Вывод')}</div>
                </div>
            </div>
        </section>
    );
};

export default CabinetBalance;