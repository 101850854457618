import axios from '../../helpers/interceptors';



import {
    TEST,
    GET_WALLETS_STARTED,
    GET_WALLETS_SUCCESS,
    GET_WALLETS_FAILURE,
    GET_PARTNERS_STARTED,
    GET_PARTNERS_SUCCESS,
    GET_PARTNERS_FAILURE,
    GET_DEPOSIT_STARTED,
    GET_DEPOSIT_SUCCESS,
    GET_DEPOSIT_FAILURE,
    OPEN_DEPOSIT_STARTED,
    OPEN_DEPOSIT_SUCCESS,
    OPEN_DEPOSIT_FAILURE,
    SET_DEPOSIT_MODAL_STATUS,
    GET_USER_DEPOSIT_STARTED,
    GET_USER_DEPOSIT_SUCCESS,
    GET_USER_DEPOSIT_FAILURE,
    GET_TRANSACTIONS_STARTED,
    GET_TRANSACTIONS_SUCCESS,
    GET_TRANSACTIONS_FAILURE,
    LOGOUT_STARTED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    GET_AVAILABLE_CURRENCIES_STARTED,
    GET_AVAILABLE_CURRENCIES_SUCCESS,
    GET_AVAILABLE_CURRENCIES_FAILURE,
    TOPUP_STARTED,
    TOPUP_SUCCESS,
    TOPUP_FAILURE,
    SET_TOPUP_MODAL_STATUS,
    WITHDRAW_STARTED,
    WITHDRAW_SUCCESS,
    WITHDRAW_FAILURE,
    SET_WITHDRAW_MODAL_STATUS,
    GET_PARTNERS_DASHBOARD_STARTED,
    GET_PARTNERS_DASHBOARD_SUCCESS,
    GET_PARTNERS_DASHBOARD_FAILURE,
    GET_MAIN_STATS_STARTED,
    GET_MAIN_STATS_SUCCESS,
    GET_MAIN_STATS_FAILURE,
    GET_REFERRALS_STARTED,
    GET_REFERRALS_SUCCESS,
    GET_REFERRALS_FAILURE,
    SEND_HELP_FORM_STARTED,
    SEND_HELP_FORM_SUCCESS,
    SEND_HELP_FORM_FAILURE,
    SET_DEPOSIT_REQUEST_MODAL_STATUS,
    SEND_CHAT_MESSAGE,
    SET_PARTNERS_MODAL_STATUS,
    CHANGE_PASSWORD_STARTED,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    GET_USER_DATA_STARTED,
    GET_USER_DATA_SUCCESS,
    GET_USER_DATA_FAILURE,
    GET_INVEST_DETAILS_STARTED,
    GET_INVEST_DETAILS_SUCCESS,
    GET_INVEST_DETAILS_FAILURE,
    GET_INVEST_DETAILS_OPERATIONS_STARTED,
    GET_INVEST_DETAILS_OPERATIONS_SUCCESS,
    GET_INVEST_DETAILS_OPERATIONS_FAILURE,
    GET_INVEST_DETAILS_TRANSACTIONS_STARTED,
    GET_INVEST_DETAILS_TRANSACTIONS_SUCCESS,
    GET_INVEST_DETAILS_TRANSACTIONS_FAILURE,
    SET_NAVIGATION_STATUS,
    SET_INVEST_DETAILS_REINVEST_STARTED,
    SET_INVEST_DETAILS_REINVEST_SUCCESS,
    SET_INVEST_DETAILS_REINVEST_FAILURE,
    INVEST_DEPOSIT_STARTED,
    INVEST_DEPOSIT_SUCCESS,
    INVEST_DEPOSIT_FAILURE,
    SET_WITHDRAW_COMMISSION_MODAL_STATUS,
    GET_PARTNERS_ACCRUALS_STARTED,
    GET_PARTNERS_ACCRUALS_SUCCESS,
    GET_PARTNERS_ACCRUALS_FAILURE
} from "./types";

import {api} from "../../api";
import {setAuthorizationStatus} from "../main/action";
// import {toast} from "react-toastify";
const host = api.host;

const headWT = ()=>{
    const token = localStorage.getItem('oli-token');
    return {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }
}

const headWTFD = ()=>{
    const token = localStorage.getItem('oli-token');
    return {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        }
    }
}

export const test = data => ({
    type: TEST,
    payload: data
});

export const setNavigationStatus = status => ({
    type: SET_NAVIGATION_STATUS,
    payload: status
});

export const setDepositModalStatus = status => ({
    type: SET_DEPOSIT_MODAL_STATUS,
    payload: status
});

export const setWithdrawCommissionModalStatus = status => ({
    type: SET_WITHDRAW_COMMISSION_MODAL_STATUS,
    payload: status
});

export const setPartnersModalStatus = status => ({
    type: SET_PARTNERS_MODAL_STATUS,
    payload: status
});

export const sendChatMessage = message => ({
    type: SEND_CHAT_MESSAGE,
    payload: message
});

export const setDepositRequestModalStatus = status => ({
    type: SET_DEPOSIT_REQUEST_MODAL_STATUS,
    payload: status
});

export const setTopUpModalStatus = status => ({
    type: SET_TOPUP_MODAL_STATUS,
    payload: status
});

export const setWithdrawModalStatus = status => ({
    type: SET_WITHDRAW_MODAL_STATUS,
    payload: status
});

export const getWallets = () => {
    let url = host + api.cabinet.wallets;


    return dispatch => {
        dispatch(getWalletsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getWalletsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getWalletsFailure(err.response.data.errors));
            });
    };
};

const getWalletsStarted = () => ({
    type: GET_WALLETS_STARTED
});

export const getWalletsSuccess = serverReport => ({
    type: GET_WALLETS_SUCCESS,
    payload: serverReport
});

export const getWalletsFailure = error => ({
    type: GET_WALLETS_FAILURE,
    payload: error
});


export const getPartners = () => {
    let url = host + api.cabinet.partners;


    return dispatch => {
        dispatch(getPartnersStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getPartnersSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getPartnersFailure(err.response.data.errors));
            });
    };
};

const getPartnersStarted = () => ({
    type: GET_PARTNERS_STARTED
});

export const getPartnersSuccess = serverReport => ({
    type: GET_PARTNERS_SUCCESS,
    payload: serverReport
});

export const getPartnersFailure = error => ({
    type: GET_PARTNERS_FAILURE,
    payload: error
});

export const getPartnersDashboard = () => {
    let url = host + api.cabinet.partnersDashboard;


    return dispatch => {
        dispatch(getPartnersDashboardStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getPartnersDashboardSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getPartnersDashboardFailure(err.response.data.errors));
            });
    };
};

const getPartnersDashboardStarted = () => ({
    type: GET_PARTNERS_DASHBOARD_STARTED
});

export const getPartnersDashboardSuccess = serverReport => ({
    type: GET_PARTNERS_DASHBOARD_SUCCESS,
    payload: serverReport
});

export const getPartnersDashboardFailure = error => ({
    type: GET_PARTNERS_DASHBOARD_FAILURE,
    payload: error
});


export const getDeposits = () => {
    let url = host + api.cabinet.getDeposit;


    return dispatch => {
        dispatch(getDepositsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getDepositsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getDepositsFailure(err.response.data.errors));
            });
    };
};

const getDepositsStarted = () => ({
    type: GET_DEPOSIT_STARTED
});

export const getDepositsSuccess = serverReport => ({
    type: GET_DEPOSIT_SUCCESS,
    payload: serverReport
});

export const getDepositsFailure = error => ({
    type: GET_DEPOSIT_FAILURE,
    payload: error
});


export const openDeposit = (id, data) => {
    let url = host + api.cabinet.deposit + `/${id}`;


    return dispatch => {
        dispatch(openDepositStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(openDepositSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(openDepositFailure(err.response.data.errors));
            });
    };
};

const openDepositStarted = () => ({
    type: OPEN_DEPOSIT_STARTED
});

export const openDepositSuccess = serverReport => ({
    type: OPEN_DEPOSIT_SUCCESS,
    payload: serverReport
});

export const openDepositFailure = error => ({
    type: OPEN_DEPOSIT_FAILURE,
    payload: error
});


export const getUserDeposits = () => {
    let url = host + api.cabinet.userDeposit;

    return dispatch => {
        dispatch(getUserDepositsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getUserDepositsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getUserDepositsFailure(err.response.data.errors));
            });
    };
};

const getUserDepositsStarted = () => ({
    type: GET_USER_DEPOSIT_STARTED
});

export const getUserDepositsSuccess = serverReport => ({
    type: GET_USER_DEPOSIT_SUCCESS,
    payload: serverReport
});

export const getUserDepositsFailure = error => ({
    type: GET_USER_DEPOSIT_FAILURE,
    payload: error
});


export const getTransactions = () => {
    let url = host + api.cabinet.translations;

    return dispatch => {
        dispatch(getTransactionsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getTransactionsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getTransactionsFailure(err.response.data.errors));
            });
    };
};

const getTransactionsStarted = () => ({
    type: GET_TRANSACTIONS_STARTED
});

export const getTransactionsSuccess = serverReport => ({
    type: GET_TRANSACTIONS_SUCCESS,
    payload: serverReport
});

export const getTransactionsFailure = error => ({
    type: GET_TRANSACTIONS_FAILURE,
    payload: error
});

export const logout = () => {
    let url = host + api.logout;

    return dispatch => {
        dispatch(logoutStarted());
        axios.post(url, null, headWT())
            .then(res => {
                localStorage.removeItem('oli-token');
                dispatch(logoutSuccess(res))
                dispatch(setAuthorizationStatus({authorization: false}));
            })
            .catch(err => {
                localStorage.removeItem('oli-token');
                dispatch(setAuthorizationStatus({authorization: false}));
                dispatch(logoutFailure(err));
            });
    };
};

const logoutStarted = () => ({
    type: LOGOUT_STARTED
});

export const logoutSuccess = serverReport => ({
    type: LOGOUT_SUCCESS,
    payload: serverReport
});

export const logoutFailure = error => ({
    type: LOGOUT_FAILURE,
    payload: error
});


export const getAvailableCurrencies = () => {
    let url = host + api.cabinet.currencies;

    return dispatch => {
        dispatch(getAvailableCurrenciesStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getAvailableCurrenciesSuccess(res.data.response))
            })
            .catch(err => {
                dispatch(getAvailableCurrenciesFailure(err.response.data.errors));
            });
    };
};

const getAvailableCurrenciesStarted = () => ({
    type: GET_AVAILABLE_CURRENCIES_STARTED
});

export const getAvailableCurrenciesSuccess = serverReport => ({
    type: GET_AVAILABLE_CURRENCIES_SUCCESS,
    payload: serverReport
});

export const getAvailableCurrenciesFailure = error => ({
    type: GET_AVAILABLE_CURRENCIES_FAILURE,
    payload: error
});


export const topup = (id, data) => {
    let url = host + api.cabinet.topup + `/${id}`;


    return dispatch => {
        dispatch(topupStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(topupSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(topupFailure(err.response.data.errors));
            });
    };
};

const topupStarted = () => ({
    type: TOPUP_STARTED
});

export const topupSuccess = serverReport => ({
    type: TOPUP_SUCCESS,
    payload: serverReport
});

export const topupFailure = error => ({
    type: TOPUP_FAILURE,
    payload: error
});



export const withdraw = (id, data) => {
    let url = host + api.cabinet.withdraw + `/${id}`;


    return dispatch => {
        dispatch(withdrawStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(withdrawSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(withdrawFailure(err.response.data.errors));
            });
    };
};

const withdrawStarted = () => ({
    type: WITHDRAW_STARTED
});

export const withdrawSuccess = serverReport => ({
    type: WITHDRAW_SUCCESS,
    payload: serverReport
});

export const withdrawFailure = error => ({
    type: WITHDRAW_FAILURE,
    payload: error
});


export const getMainStats = () => {
    let url = host + api.cabinet.stats;

    return dispatch => {
        dispatch(getMainStatsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getMainStatsSuccess(res.data.response))
            })
            .catch(err => {
                dispatch(getMainStatsFailure(err.response.data.errors));
            });
    };
};

const getMainStatsStarted = () => ({
    type: GET_MAIN_STATS_STARTED
});

export const getMainStatsSuccess = serverReport => ({
    type: GET_MAIN_STATS_SUCCESS,
    payload: serverReport
});

export const getMainStatsFailure = error => ({
    type: GET_MAIN_STATS_FAILURE,
    payload: error
});


export const getReferrals = (withDep, page) => {
    const url = `${host}${api.cabinet.referrals}?${(withDep !== undefined ? `active=${withDep}` : '')}&page=${page}`;

    return dispatch => {
        dispatch(getReferralsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getReferralsSuccess(res.data.response))
            })
            .catch(err => {
                dispatch(getReferralsFailure(err.response.data.errors));
            });
    };
};

const getReferralsStarted = () => ({
    type: GET_REFERRALS_STARTED
});

export const getReferralsSuccess = serverReport => ({
    type: GET_REFERRALS_SUCCESS,
    payload: serverReport
});

export const getReferralsFailure = error => ({
    type: GET_REFERRALS_FAILURE,
    payload: error
});

export const getPartnersAccruals = () => {
    let url = host + api.cabinet.accruals;

    return dispatch => {
        dispatch(getPartnersAccrualsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getPartnersAccrualsSuccess(res.data.response))
            })
            .catch(err => {
                dispatch(getPartnersAccrualsFailure(err.response.data.errors));
            });
    };
};

const getPartnersAccrualsStarted = () => ({
    type: GET_PARTNERS_ACCRUALS_STARTED
});

export const getPartnersAccrualsSuccess = serverReport => ({
    type: GET_PARTNERS_ACCRUALS_SUCCESS,
    payload: serverReport
});

export const getPartnersAccrualsFailure = error => ({
    type: GET_PARTNERS_ACCRUALS_FAILURE,
    payload: error
});


export const sendHelpForm = (data) => {
    let url = host + api.cabinet.help;

    return dispatch => {
        dispatch(sendHelpFormStarted());
        axios.post(url, data, data?.file ? headWTFD() : headWT())
            .then(res => {
                dispatch(sendHelpFormSuccess(res.data.success))
            })
            .catch(err => {
                dispatch(sendHelpFormFailure(err.response.data.errors));
            });
    };
};

const sendHelpFormStarted = () => ({
    type: SEND_HELP_FORM_STARTED
});

export const sendHelpFormSuccess = serverReport => ({
    type: SEND_HELP_FORM_SUCCESS,
    payload: serverReport
});

export const sendHelpFormFailure = error => ({
    type: SEND_HELP_FORM_FAILURE,
    payload: error
});

export const changePassword = (data) => {
    let url = host + api.cabinet.changePassword;

    return dispatch => {
        dispatch(changePasswordStarted());
        axios.put(url, data, headWT())
            .then(res => {
                dispatch(changePasswordSuccess(res.data.success))
            })
            .catch(err => {
                dispatch(changePasswordFailure(err.response.data.errors));
            });
    };
};

const changePasswordStarted = () => ({
    type: CHANGE_PASSWORD_STARTED
});

export const changePasswordSuccess = serverReport => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: serverReport
});

export const changePasswordFailure = error => ({
    type: CHANGE_PASSWORD_FAILURE,
    payload: error
});

export const getUserData = () => {
    let url = host + api.cabinet.user;

    return dispatch => {
        dispatch(getUserDataStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getUserDataSuccess(res.data.response))
            })
            .catch(err => {
                dispatch(getUserDataFailure(err));
            });
    };
};

const getUserDataStarted = () => ({
    type: GET_USER_DATA_STARTED
});

export const getUserDataSuccess = serverReport => ({
    type: GET_USER_DATA_SUCCESS,
    payload: serverReport
});

export const getUserDataFailure = error => ({
    type: GET_USER_DATA_FAILURE,
    payload: error
});

export const getInvestDetails = (uuid) => {
    let url = host + api.cabinet.invest + `/${uuid}`;

    return dispatch => {
        dispatch(getInvestDetailsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getInvestDetailsSuccess(res.data.response))
            })
            .catch(err => {
                dispatch(getInvestDetailsFailure(err));
            });
    };
};

const getInvestDetailsStarted = () => ({
    type: GET_INVEST_DETAILS_STARTED
});

export const getInvestDetailsSuccess = serverReport => ({
    type: GET_INVEST_DETAILS_SUCCESS,
    payload: serverReport
});

export const getInvestDetailsFailure = error => ({
    type: GET_INVEST_DETAILS_FAILURE,
    payload: error
});

export const getInvestDetailsOperations = (uuid) => {
    let url = host + api.cabinet.invest + `/${uuid}/operations/latest`;

    return dispatch => {
        dispatch(getInvestDetailsOperationsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getInvestDetailsOperationsSuccess(res.data.response))
            })
            .catch(err => {
                dispatch(getInvestDetailsOperationsFailure(err));
            });
    };
};

const getInvestDetailsOperationsStarted = () => ({
    type: GET_INVEST_DETAILS_OPERATIONS_STARTED
});

export const getInvestDetailsOperationsSuccess = serverReport => ({
    type: GET_INVEST_DETAILS_OPERATIONS_SUCCESS,
    payload: serverReport
});

export const getInvestDetailsOperationsFailure = error => ({
    type: GET_INVEST_DETAILS_OPERATIONS_FAILURE,
    payload: error
});

export const getInvestDetailsTransactions = (uuid) => {
    let url = host + api.cabinet.invest + `/${uuid}/transactions/latest`;

    return dispatch => {
        dispatch(getInvestDetailsTransactionsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getInvestDetailsTransactionsSuccess(res.data.response))
            })
            .catch(err => {
                dispatch(getInvestDetailsTransactionsFailure(err));
            });
    };
};

const getInvestDetailsTransactionsStarted = () => ({
    type: GET_INVEST_DETAILS_TRANSACTIONS_STARTED
});

export const getInvestDetailsTransactionsSuccess = serverReport => ({
    type: GET_INVEST_DETAILS_TRANSACTIONS_SUCCESS,
    payload: serverReport
});

export const getInvestDetailsTransactionsFailure = error => ({
    type: GET_INVEST_DETAILS_TRANSACTIONS_FAILURE,
    payload: error
});


export const setInvestDetailsReinvest = (data, uuid) => {
    let url = host + api.cabinet.invest + `/${uuid}/change-auto-reinvest`;

    return dispatch => {
        dispatch(setInvestDetailsReinvestStarted());
        axios.put(url, data, headWT())
            .then(res => {
                dispatch(setInvestDetailsReinvestSuccess(res.data.success))
            })
            .catch(err => {
                dispatch(setInvestDetailsReinvestFailure(err));
            });
    };
};

const setInvestDetailsReinvestStarted = () => ({
    type: SET_INVEST_DETAILS_REINVEST_STARTED
});

export const setInvestDetailsReinvestSuccess = serverReport => ({
    type: SET_INVEST_DETAILS_REINVEST_SUCCESS,
    payload: serverReport
});

export const setInvestDetailsReinvestFailure = error => ({
    type: SET_INVEST_DETAILS_REINVEST_FAILURE,
    payload: error
});

export const investDeposit = (data, uuid) => {
    let url = host + api.cabinet.invest + `/${uuid}/invest`;

    return dispatch => {
        dispatch(investDepositStarted());
        axios.put(url, data, headWT())
            .then(res => {
                dispatch(investDepositSuccess(res.data.success))
            })
            .catch(err => {
                dispatch(investDepositFailure(err));
            });
    };
};

const investDepositStarted = () => ({
    type: INVEST_DEPOSIT_STARTED
});

export const investDepositSuccess = serverReport => ({
    type: INVEST_DEPOSIT_SUCCESS,
    payload: serverReport
});

export const investDepositFailure = error => ({
    type: INVEST_DEPOSIT_FAILURE,
    payload: error
});