import {
    TEST,
    SET_LINK_SCROLL_STATUS,
    SET_AUTHORIZATION_STATUS,
    LOGIN_STARTED,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    REGISTRATION_STARTED,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAILURE,
    GET_ABOUT_DATA_STARTED,
    GET_ABOUT_DATA_SUCCESS,
    GET_ABOUT_DATA_FAILURE,
    SEND_CONTACT_FORM_STARTED,
    SEND_CONTACT_FORM_SUCCESS,
    SEND_CONTACT_FORM_FAILURE,
    RESET_PASSWORD_STARTED,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    FORGOT_PASSWORD_STARTED,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    GET_NEWS_STARTED,
    GET_NEWS_SUCCESS,
    GET_NEWS_FAILURE,
    GET_ALL_NEWS_STARTED,
    GET_ALL_NEWS_SUCCESS,
    GET_ALL_NEWS_FAILURE,
    SET_ALL_NEWS_LAST_PAGE,
    GET_POST_STARTED,
    GET_POST_SUCCESS,
    GET_POST_FAILURE,
    FIRST_NEWS_CALL_STATUS,
    CLEAR_ALL_NEWS
} from "./types";


const initialState = {
    test: false,
    authorizationStatus: {authorization: !!localStorage.getItem('oli-token')},
    linkScrollStatus: false,
    registrationStarted: false,
    isFirstNewsCallStatus: true,
    registration: false,
    registrationErr: false,
    loginStarted: false,
    login: false,
    loginErr: false,
    aboutDataStarted: false,
    aboutData: false,
    aboutDataErr: false,
    newsStarted: false,
    news: false,
    newsErr: false,
    allNewsStarted: false,
    allNews: [],
    allNewsErr: false,
    allNewsLastPage: false,
    postStarted: false,
    post: false,
    postErr: false,
    contactStatusStarted: false,
    contactStatus: false,
    contactStatusErr: false,
    resetPasswordStatusStarted: false,
    resetPasswordStatus: false,
    resetPasswordStatusErr: false,
    forgotPasswordStatusStarted: false,
    forgotPasswordStatus: false,
    forgotPasswordStatusErr: false,
};

export function mainReducer(state = initialState, action) {
    switch (action.type) {
        case TEST:
            return {
                ...state,
                test: action.payload
            };
        case SET_AUTHORIZATION_STATUS:
            return {
                ...state,
                authorizationStatus: action.payload
            };
        case FIRST_NEWS_CALL_STATUS:
            return {
                ...state,
                isFirstNewsCallStatus: action.payload
            };
        case SET_LINK_SCROLL_STATUS:
            return {
                ...state,
                linkScrollStatus: action.payload
            };
        case REGISTRATION_STARTED:
            return {
                ...state,
                registrationStarted: true
            };
        case REGISTRATION_SUCCESS:
            return {
                ...state,
                registration: action.payload,
                registrationStarted: false
            };
        case REGISTRATION_FAILURE:
            return {
                ...state,
                registrationErr: action.payload,
                registrationStarted: false
            };
        case LOGIN_STARTED:
            return {
                ...state,
                loginStarted: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: action.payload,
                loginStarted: false
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                loginErr: action.payload,
                loginStarted: false
            };
        case GET_ABOUT_DATA_STARTED:
            return {
                ...state,
                aboutDataStarted: true
            };
        case GET_ABOUT_DATA_SUCCESS:
            return {
                ...state,
                aboutData: action.payload,
                aboutDataStarted: false
            };
        case GET_ABOUT_DATA_FAILURE:
            return {
                ...state,
                aboutDataErr: action.payload,
                aboutDataStarted: false
            };
        case SEND_CONTACT_FORM_STARTED:
            return {
                ...state,
                contactStatusStarted: true
            };
        case SEND_CONTACT_FORM_SUCCESS:
            return {
                ...state,
                contactStatus: action.payload,
                contactStatusStarted: false
            };
        case SEND_CONTACT_FORM_FAILURE:
            return {
                ...state,
                contactStatusErr: action.payload,
                contactStatusStarted: false
            };
        case RESET_PASSWORD_STARTED:
            return {
                ...state,
                resetPasswordStatusStarted: true
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordStatus: action.payload,
                resetPasswordStatusStarted: false
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordStatusErr: action.payload,
                resetPasswordStatusStarted: false
            };
        case FORGOT_PASSWORD_STARTED:
            return {
                ...state,
                forgotPasswordStatusStarted: true
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotPasswordStatus: action.payload,
                forgotPasswordStatusStarted: false
            };
        case FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                forgotPasswordStatusErr: action.payload,
                forgotPasswordStatusStarted: false
            };
        case GET_NEWS_STARTED:
            return {
                ...state,
                newsStarted: true
            };
        case GET_NEWS_SUCCESS:
            return {
                ...state,
                news: action.payload,
                newsStarted: false
            };
        case GET_NEWS_FAILURE:
            return {
                ...state,
                newsErr: action.payload,
                newsStarted: false
            };
        case GET_ALL_NEWS_STARTED:
            return {
                ...state,
                allNewsStarted: true
            };
        case GET_ALL_NEWS_SUCCESS:
            return {
                ...state,
                allNews: [...state.allNews, ...action.payload],
                allNewsStarted: false
            };
        case GET_ALL_NEWS_FAILURE:
            return {
                ...state,
                allNewsErr: action.payload,
                allNewsStarted: false
            };
        case SET_ALL_NEWS_LAST_PAGE:
            return {
                ...state,
                allNewsLastPage: action.payload
            };
        case GET_POST_STARTED:
            return {
                ...state,
                postStarted: true
            };
        case GET_POST_SUCCESS:
            return {
                ...state,
                post: action.payload,
                postStarted: false
            };
        case GET_POST_FAILURE:
            return {
                ...state,
                postErr: action.payload,
                postStarted: false
            };
        case CLEAR_ALL_NEWS:
            return {
                ...state,
                allNews: []
            };
        default:
            return state;
    }
}

