import React from "react";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";
import {valueFixer} from "../../helpers/valueFixer";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import EmptyData from "../_UI/emptyData";



const CabinetInvestments = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const userDeposit = useSelector(store => store.cabinet.userDeposit);
    // const deposit = useSelector(store => store.cabinet.deposit);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.cabinetLargeCardWS}`}>
                <div className={`${Styles.titleRow}`}>
                    <div className={`${Styles.cabinetTitle1}`}>{t('Инвестиции')}</div>
                    <div className={`${Styles.allBtn}`} onClick={()=>{navigate('/cabinet/investments')}}>{t('Показать все')}</div>
                </div>
                <div className={`${Styles.contentBox} ${userDeposit?.length > 0 ? null : Styles.contentBoxEmpty}`}>
                    {userDeposit?.length > 0 ? userDeposit.map((item, index)=>{
                        return(
                            <div className={`${Styles.contentBoxItem}`} key={'investData' + index}>
                                <div className={`${Styles.contentBoxItemTitle}`}>{item?.deposit?.name}</div>
                                <div className={`${Styles.contentBoxItemDaily}`}>{item?.deposit?.percent_from * 1}% <span>/{t('весь период')}</span></div>
                                <div className={`${Styles.contentBoxItemRowWrap}`}>
                                    <div className={`${Styles.contentBoxItemRow}`}>
                                        <div className={`${Styles.contentBoxItemRowName}`}>{t('Сумма')}:</div>
                                        <div className={`${Styles.contentBoxItemRowValue}`}>${valueFixer(item.amount)}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemRow}`}>
                                        <div className={`${Styles.contentBoxItemRowName}`}>{t('Доходность')}:</div>
                                        <div className={`${Styles.contentBoxItemRowValue}`}>${valueFixer(item.profit)}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemRow}`}>
                                        <div className={`${Styles.contentBoxItemRowName}`}>{t('Продолжительность')}:</div>
                                        <div className={`${Styles.contentBoxItemRowValue}`}>{item.duration} {t('Дней')}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemRow}`}>
                                        <div className={`${Styles.contentBoxItemRowName}`}>{t('Начисления')}:</div>
                                        <div className={`${Styles.contentBoxItemRowValue}`}>{item.worked_days ? item.worked_days : 0} / {item.duration}</div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxItemBg}`}/>
                                <div className={`${Styles.contentBoxItemAbsBtnWrap}`}>
                                    <div className={`${Styles.contentBoxItemAbsBtn}`} onClick={()=>{navigate(`/cabinet/investments/${item.uuid}`)}}>{t('Подробнее')}</div>
                                </div>
                            </div>
                        )
                    }) : <EmptyData text={t('У вас пока что нет активных инвестиций')} customJs={<div className={`${Styles.emptyText}`}>{t('У вас пока что нет активных инвестиций')}. <span onClick={()=>{navigate('/cabinet/deposit')}}>{t('Открыть депозит')}</span></div>}/>}
                    {/*{userDeposit[0] ? null : <EmptyInvest dep={deposit?.[0] ? deposit?.[0] : false}/>}*/}
                    {/*{userDeposit[1] ? null : <EmptyInvest dep={deposit?.[1] ? deposit?.[1] : false}/>}*/}
                    {/*{userDeposit[2] ? null : <EmptyInvest dep={deposit?.[2] ? deposit?.[2] : false}/>}*/}
                    {/*{userDeposit[3] ? null : <EmptyInvest dep={deposit?.[3] ? deposit?.[3] : false}/>}*/}
                </div>
            </div>
        </section>
    );
};

export default CabinetInvestments;