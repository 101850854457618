import React from "react";
import Styles from './styles.module.scss';

import sentIcon from '../../../theme/assets/svg/oli_cabinet_icon_sent.svg';
import topUpIcon from '../../../theme/assets/svg/oli_cabinet_topup_icon.svg';
import withdrawalIcon from '../../../theme/assets/svg/oli_cabinet_withdrawal_icon.svg';
import copyAIcon from '../../../theme/assets/svg/oli_copyA_icon.svg';
import copyPIcon from '../../../theme/assets/svg/oli_copyP_icon.svg';




const CabinetIcon = (props) => {
    const {name, mobileHideStatus} = props;

    const cabinetIconHandler = () =>{
        switch (name){
            case 'withdraw':
                return (<img src={withdrawalIcon} alt={name}/>)
            case 'top_up':
                return (<img src={topUpIcon} alt={name}/>)
            case 'Sent':
                return (<img src={sentIcon} alt={name}/>)
            case 'copyA':
                return (<img src={copyAIcon} alt='copied'/>)
            case 'copyP':
                return (<img src={copyPIcon} alt='copy'/>)
            default:
                return null
        }
    }

    return (
        <div
            className={`${Styles.cabinetIconWrap} ${mobileHideStatus ? Styles.cabinetIconWrapWH : null}`}
        >
            {cabinetIconHandler()}
        </div>
    );
};

export default CabinetIcon;