import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Styles from './styles.module.scss';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {sendChatMessage, setDepositRequestModalStatus} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";


const DepositRequestModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const modalStatus = useSelector(store => store.cabinet.depositRequestModalStatus);

    const closeModal = () =>{
        dispatch(setDepositRequestModalStatus({status: false, data: false}));
    }

    const mainBtnHandler = () =>{
        closeModal();
        dispatch(sendChatMessage(true));
    }

    return (
        <Popup
            open={modalStatus?.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.modalTitle}`}>{t('Открыть депозит')}</div>
                    <div className={`${Styles.modalP}`}>
                        {t('Чтобы открыть данный депозит, вам необходимо отправить запрос в службу поддержки и указать необходимые данные')}:
                    </div>
                    <div className={`${Styles.modalListWrap}`}>
                        <div className={`${Styles.modalListItem}`}>
                            <div className={`${Styles.modalListItemNumber}`}>1.</div>
                            <div className={`${Styles.modalListItemValue}`}>{t('Название депозита')};</div>
                        </div>
                        <div className={`${Styles.modalListItem}`}>
                            <div className={`${Styles.modalListItemNumber}`}>2.</div>
                            <div className={`${Styles.modalListItemValue}`}>{t('Сумму депозита')};</div>
                        </div>
                        <div className={`${Styles.modalListItem}`}>
                            <div className={`${Styles.modalListItemNumber}`}>3.</div>
                            <div className={`${Styles.modalListItemValue}`}>{t('Валюту депозита')}.</div>
                        </div>
                    </div>
                    <div className={`${Styles.cabinetBtn}`} onClick={()=>{mainBtnHandler()}}>{t('Поддержка')}</div>
                </div>
            </div>
        </Popup>
    );
};

export default DepositRequestModal;