import React from "react";
import Styles from './styles.module.scss';
import Slider from "react-slick";
import MainSliderItem from "../_UI/mainSliderItem";
import {useTranslation} from "react-i18next";

import reviewIcon1 from '../../theme/assets/other/ssx_main_review_icon1.png';
import reviewIcon2 from '../../theme/assets/other/ssx_main_review_icon2.png';
import reviewIcon3 from '../../theme/assets/other/ssx_main_review_icon3.png';
import reviewIcon4 from '../../theme/assets/other/ssx_main_review_icon4.png';
import reviewIcon5 from '../../theme/assets/other/ssx_main_review_icon5.png';
import reviewIcon6 from '../../theme/assets/other/ssx_main_review_icon6.png';
import reviewIcon7 from '../../theme/assets/other/ssx_main_review_icon7.png';
import reviewIcon8 from '../../theme/assets/other/ssx_main_review_icon8.png';
import reviewIcon9 from '../../theme/assets/other/ssx_main_review_icon9.png';
import reviewIcon10 from '../../theme/assets/other/ssx_main_review_icon10.png';
import reviewIcon11 from '../../theme/assets/other/ssx_main_review_icon11.png';
import reviewIcon12 from '../../theme/assets/other/ssx_main_review_icon12.png';




const MainReview = () => {
    const { t } = useTranslation();

    const showSliderHandler = () =>{
        if (window.screen.width < 768 ){
            return 1
        } else if(window.screen.width >= 768 && window.screen.width < 1920){
            return 2
        } else {
            return 3
        }
    }

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: showSliderHandler(),
        slidesToScroll: showSliderHandler(),
        autoplay: false,
        arrows: true,
        // beforeChange: (current, next) => dispatch(setCurrentWallet(next)),
        // afterChange: current => console.log({ activeSlide2: current })
    };

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageVerticalPadding}`}>
                <div className={`${Styles.titleRow} ${Styles.pageContainer}`}>
                    <div className={`${Styles.title2}`}>
                        {t('Что наши пользователи')} <br/>
                        {t('Говорят о нас')}
                    </div>
                    <div className={`${Styles.titleNote}`}>
                        {t('Не верьте нам на слово — послушайте наших лучших клиентов о том, как')} <br/>
                        {t('наши решения в области криптобанкинга упростили их финансовые пути')}.
                    </div>
                </div>
                <div className={`${Styles.sliderWrap} ${Styles.pageContainerWS3}`}>
                    <Slider {...settings}>
                        <MainSliderItem
                            stars={5}
                            review={t('SSX24 - это лучший крипто-сервис, которым я пользовалась. За полгода использования ни разу не возникло проблем. Платформа проста в использовании и обеспечивает высокий уровень безопасности. Рекомендую всем, кто ищет надежное место для хранения и обмена криптовалют')}
                            authorName={`Anna K.`}
                            authorPosition={`Entrepreneur`}
                            authorAvatar={reviewIcon1}
                        />
                        <MainSliderItem
                            stars={5}
                            review={t('Я очень доволен SSX24! Великолепные возможности для хранения и обмена криптовалют. Особенно нравится простота интерфейса и надежность системы. Быстрые транзакции и отличная поддержка делают SSX24 моим основным выбором для криптовалютных операций')}
                            authorName={`Ivan S.`}
                            authorPosition={`Freelancer`}
                            authorAvatar={reviewIcon2}
                        />
                        <MainSliderItem
                            stars={5}
                            review={t('идеальный выбор для тех, кто ценит безопасность и удобство. Интерфейс интуитивно понятен, а поддержка всегда готова помочь. Высокие проценты по депозитам делают использование платформы еще более привлекательным. Спасибо за отличный сервис')}
                            authorName={`Maria L.`}
                            authorPosition={`Entrepreneur`}
                            authorAvatar={reviewIcon3}
                        />
                        <MainSliderItem
                            stars={5}
                            review={t('SSX24 превзошел все мои ожидания. Простота использования, высокая безопасность и выгодные условия по депозитам делают его моим фаворитом. Пользуюсь уже несколько месяцев и ни разу не пожалел о своем выборе')}
                            authorName={`Alexander V.`}
                            authorPosition={`Freelancer`}
                            authorAvatar={reviewIcon4}
                        />
                        <MainSliderItem
                            stars={4}
                            review={t('SSX24 - это синоним надежности и безопасности. Очень довольна качеством обслуживания и скоростью транзакций. Платформа предлагает множество возможностей для управления криптовалютами, что делает ее отличным выбором для всех пользователей')}
                            authorName={`Catherine P.`}
                            authorPosition={`Entrepreneur`}
                            authorAvatar={reviewIcon5}
                        />
                        <MainSliderItem
                            stars={4}
                            review={t('SSX24 - инновационная платформа для всех моих криптовалютных нужд. Быстро, удобно и безопасно. Поддержка всегда готова помочь, что делает работу с платформой еще более комфортной. Очень доволен своим выбором')}
                            authorName={`Dmitry N.`}
                            authorPosition={`Freelancer`}
                            authorAvatar={reviewIcon6}
                        />
                        <MainSliderItem
                            stars={5}
                            review={t('Пользуюсь SSX24 уже несколько месяцев и полностью довольна. Платформа предлагает высокую степень защиты и удобный интерфейс. Особенно радуют выгодные условия по депозитам. Рекомендую всем, кто ищет надежное место для хранения криптовалют')}
                            authorName={`Lisa R.`}
                            authorPosition={`Entrepreneur`}
                            authorAvatar={reviewIcon7}
                        />
                        <MainSliderItem
                            stars={4}
                            review={t('SSX24 - это платформа, которой можно доверять. Высокий уровень безопасности и простота использования делают ее идеальным выбором для хранения и обмена криптовалют. Очень доволен сервисом и планирую продолжать пользоваться в будущем')}
                            authorName={`Sergey T.`}
                            authorPosition={`Freelancer`}
                            authorAvatar={reviewIcon8}
                        />
                        <MainSliderItem
                            stars={5}
                            review={t('SSX24 - лучший сервис для работы с криптовалютами. За время использования не возникло ни одной проблемы. Поддержка всегда оперативно отвечает на все вопросы. Платформа проста и удобна в использовании, а условия по депозитам радуют своей выгодностью')}
                            authorName={`Elena M.`}
                            authorPosition={`Entrepreneur`}
                            authorAvatar={reviewIcon9}
                        />
                        <MainSliderItem
                            stars={5}
                            review={t('SSX24 - это надежность и удобство в одном флаконе. Платформа предлагает высокую степень защиты и простой интерфейс. Особенно приятно работать с поддержкой, которая всегда готова помочь. Очень доволен выбором и рекомендую всем')}
                            authorName={`Andrew K.`}
                            authorPosition={`Freelancer`}
                            authorAvatar={reviewIcon10}
                        />
                        <MainSliderItem
                            stars={5}
                            review={t('SSX24 превзошел все мои ожидания. Простота использования, высокая безопасность и выгодные условия по депозитам делают его моим фаворитом. Пользуюсь уже несколько месяцев и ни разу не пожалела о своем выборе. Рекомендую')}
                            authorName={`Natalia F.`}
                            authorPosition={`Entrepreneur`}
                            authorAvatar={reviewIcon11}
                        />
                        <MainSliderItem
                            stars={4}
                            review={t('SSX24 - идеальная платформа для всех, кто ищет надежное место для хранения и обмена криптовалют. Высокий уровень безопасности, удобный интерфейс и отличная поддержка делают ее моим основным выбором. Очень доволен сервисом и планирую продолжать пользоваться')}
                            authorName={`Victor S.`}
                            authorPosition={`Freelancer`}
                            authorAvatar={reviewIcon12}
                        />
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default MainReview;