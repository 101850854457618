import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import CabinetHeader from "../../components/cabinetHeader";
import CabinetInvestments from "../../components/cabinetInvestments";
import CabinetTransactions from "../../components/cabinetTransactions";
import {useDispatch, useSelector} from "react-redux";
import {getMainStats, getTransactions, getUserData, getUserDeposits} from "../../store/cabinet/action";
import {useNavigate} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const CabinetMainPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);

    useEffect(()=>{
        if (!authStatus.authorization){
            navigate('/sign-in')
        }
    },[authStatus, navigate])


    useEffect(()=>{
        dispatch(getUserDeposits());
        dispatch(getTransactions());
        dispatch(getMainStats());
        dispatch(getUserData());
    },[dispatch])


    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Главная')}/>
            <div className={`${Styles.contentWrap} ${Styles.cabinetVerticalPadding}`}>
                <CabinetHeader/>
                <CabinetInvestments/>
                <CabinetTransactions/>
            </div>
        </section>
    );
};

export default CabinetMainPage;