import React from "react";
import Styles from './styles.module.scss';


const EmptyData = (props) => {
    const {text, customJs} = props;
    return (
        <div className={`${Styles.emptyWrap}`}>
            {customJs ? customJs : <div className={`${Styles.emptyText}`}>{text}</div>}
        </div>
    );
};

export default EmptyData;