import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';
import CabinetCustomInput from "../_UI/cabinetCustomInput";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {sendHelpForm, sendHelpFormFailure, sendHelpFormSuccess} from "../../store/cabinet/action";




const CabinetHelpForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const helpFormStatus = useSelector(store => store.cabinet.helpFormStatus);
    const helpFormStatusErr = useSelector(store => store.cabinet.helpFormStatusErr);


    const [file, setFile] = useState(null);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    // const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    //     if (e.target.files) {
    //         setFile(e.target.files[0]);
    //     }
    // };

    const sendFormHandler = () =>{
        if (file){
            const formData = new FormData();
            formData.append('file', file)
            formData.append("email", email);
            formData.append("name", name);
            formData.append("subject", title);
            formData.append("message", message);
            dispatch(sendHelpForm(formData));
        } else {
            dispatch(sendHelpForm({name, email, message, subject: title}))
        }
    }

    useEffect(()=>{
        if (helpFormStatus){
            setEmail('');
            setName('');
            setTitle('');
            setMessage('');
            setFile(null);
            toast(t('Ваше сообщение успешно отправленно!'));
            dispatch(sendHelpFormSuccess(false));
        }
    },[dispatch, helpFormStatus, t])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.cabinetLargeCard}`}>
                <div className={`${Styles.titleRow}`}>
                    <div className={`${Styles.cabinetTitle1}`}>{t('Связаться с нами')}</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.inputsWrap}`}>
                        <CabinetCustomInput
                            gender='input'
                            type='text'
                            title={t('Причина обращения')}
                            placeholder='Collaboration'
                            value={title}
                            setValue={setTitle}
                            errDispatch={sendHelpFormFailure}
                            errors={{full: helpFormStatusErr, type: 'subject'}}
                        />
                        <CabinetCustomInput
                            gender='input'
                            type='text'
                            title={t('Имя')}
                            placeholder='Mike Vyazovsky'
                            value={name}
                            setValue={setName}
                            errDispatch={sendHelpFormFailure}
                            errors={{full: helpFormStatusErr, type: 'name'}}
                        />
                        <CabinetCustomInput
                            gender='input'
                            type='text'
                            title={t('Почта')}
                            placeholder='example@mail.com'
                            value={email}
                            setValue={setEmail}
                            errDispatch={sendHelpFormFailure}
                            errors={{full: helpFormStatusErr, type: 'email'}}
                        />
                        <CabinetCustomInput
                            gender='area'
                            type='text'
                            title={t('Сообщение')}
                            placeholder='Example message for us'
                            value={message}
                            setValue={setMessage}
                            areaHeight='24.6'
                            errDispatch={sendHelpFormFailure}
                            errors={{full: helpFormStatusErr, type: 'message'}}
                        />
                    </div>
                    {/*<div className={`${Styles.fileWrap}`}>*/}
                    {/*    <div className={`${Styles.fileWrapTitle}`}>{t('Прикрепите файл (при наличии)')}</div>*/}
                    {/*    <div className={`${Styles.posWrap}`}>*/}
                    {/*        <div className={`${Styles.fakeInput}`}>{file ? `${file.name}` : 'No file chosen'}</div>*/}
                    {/*        <label htmlFor="file-upload" className={`${Styles.cabinetBtn}`}>*/}
                    {/*            {t('Выбрать файл')}*/}
                    {/*        </label>*/}
                    {/*        <input type="file" id="file-upload" onChange={handleFileChange} />*/}
                    {/*        <div className={`${Styles.fileWrapNote}`}>{t('Для более быстрой обработки вашего обращения, опишите проблемы максимально подробно и при необходимости прикрепите файл')}.</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={`${Styles.cabinetBtn}`} onClick={()=>{sendFormHandler()}}>{t('Отправить')}</div>
                </div>
            </div>
        </section>
    );
};

export default CabinetHelpForm;