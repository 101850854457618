import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Styles from './styles.module.scss';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {setTopUpModalStatus, topupSuccess} from "../../store/cabinet/action";

import successIcon from '../../theme/assets/svg/oli_cabinet_modal_success.svg';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";



const TopUpModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const modalStatus = useSelector(store => store.cabinet.topupModalStatus);

    const closeModal = () =>{
        dispatch(setTopUpModalStatus(false));
        dispatch(topupSuccess(false));
    }

    return (
        <Popup
            open={modalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.modalImgWrap}`}>
                        <img src={successIcon} alt="Success!"/>
                    </div>
                    <div className={`${Styles.modalTitle}`}>{t('Успех')}!</div>
                    <div className={`${Styles.modalNote}`}>
                        {t('Ваша транзакция обрабатывается. Следуйте инструкциям в новой вкладке и дождитесь завершения транзакции. Теперь вы можете легко отслеживать ее выполнение, перейдя на вкладку')} <span onClick={()=>{
                            closeModal();
                            navigate('/cabinet/transactions');
                        }}>{t('Транзакции')}</span> {t('Спасибо за ваш выбор и доверие')}!
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default TopUpModal;