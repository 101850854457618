import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';
import CabinetCustomInput from "../_UI/cabinetCustomInput";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    changePassword,
    changePasswordFailure,
    changePasswordSuccess
} from "../../store/cabinet/action";
import {toast} from "react-toastify";

// import declineIcon from '../../theme/assets/svg/oli_cabinet_setting_decline.svg';
// import acceptIcon from '../../theme/assets/svg/oli_cabinet_setting_accept.svg';




const CabinetSettingForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const changePasswordErr = useSelector(store => store.cabinet.changePasswordStatusErr);
    const changePasswordStatus = useSelector(store => store.cabinet.changePasswordStatus);

    const [old_password, setOld_password] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');

    const changePasswordHandler = () =>{
        dispatch(changePassword({old_password, password, password_confirmation}))
    }
    
    useEffect(()=>{
        if (changePasswordStatus){
            setOld_password('');
            setPassword('');
            setPassword_confirmation('');
            toast(t('Ваш пароль успешно изменен!'));
            dispatch(changePasswordSuccess(false));
        }
    }, [changePasswordStatus, dispatch, t])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.cabinetLargeCard}`}>
                {/*<div className={`${Styles.mainWrap}`}>*/}
                {/*    <div className={`${Styles.titleRow}`}>*/}
                {/*        <div className={`${Styles.cabinetTitle1}`}>Settings</div>*/}
                {/*    </div>*/}
                {/*    <div className={`${Styles.mainBox}`}>*/}
                {/*        <div className={`${Styles.mainRow}`}>*/}
                {/*            <CabinetCustomInput gender='input' type='text' placeholder='Nikola' title='Your Name'/>*/}
                {/*            <CabinetCustomInput gender='input' type='text' placeholder='Tesla' title='Your Surname'/>*/}
                {/*            <CabinetCustomInput gender='input' type='text' placeholder='email@gmail.com' title='Your Email'/>*/}
                {/*            <CabinetCustomInput gender='input' type='text' placeholder='_ _ _ _ _ _' title='Verification code'/>*/}
                {/*            <CabinetCustomInput gender='input' type='text' placeholder='newemail@gmail.com' title='New Email'/>*/}
                {/*            <div className={`${Styles.cabinetBtnDisable}`}>CHANGE EMAIL</div>*/}
                {/*        </div>*/}
                {/*        <div className={`${Styles.kycRow}`}>*/}
                {/*            <div className={`${Styles.kycRowTitle}`}>KYC Status</div>*/}
                {/*            <div className={`${Styles.kycInfoRow}`}>*/}
                {/*                <div className={`${Styles.kycStatus}`}>Not verified</div>*/}
                {/*                <div className={`${Styles.kycNote}`}>Non-verified users have a limit on deposits and withdrawals per day</div>*/}
                {/*            </div>*/}
                {/*            <div className={`${Styles.kycTable}`}>*/}
                {/*                <div className={`${Styles.kycTableRow}`}>*/}
                {/*                    <div className={`${Styles.kycTableRowName}`}>Account perks</div>*/}
                {/*                    <div className={`${Styles.kycTableRowBefore}`}>Current limit</div>*/}
                {/*                    <div className={`${Styles.kycTableRowAfter}`}>After verification</div>*/}
                {/*                </div>*/}
                {/*                <div className={`${Styles.kycTableRow}`}>*/}
                {/*                    <div className={`${Styles.kycTableRowName}`}>Withdrawal</div>*/}
                {/*                    <div className={`${Styles.kycTableRowBefore}`}>$100</div>*/}
                {/*                    <div className={`${Styles.kycTableRowAfter}`}>$1.000.000</div>*/}
                {/*                </div>*/}
                {/*                <div className={`${Styles.kycTableRow}`}>*/}
                {/*                    <div className={`${Styles.kycTableRowName}`}>Deposit</div>*/}
                {/*                    <div className={`${Styles.kycTableRowBefore}`}>$1000</div>*/}
                {/*                    <div className={`${Styles.kycTableRowAfter}`}>$1.000.000</div>*/}
                {/*                </div>*/}
                {/*                <div className={`${Styles.kycTableRow}`}>*/}
                {/*                    <div className={`${Styles.kycTableRowName}`}>P2P trading</div>*/}
                {/*                    <div className={`${Styles.kycTableRowBefore}`}><div className={`${Styles.imgWrap}`}><img src={declineIcon} alt="decline"/></div></div>*/}
                {/*                    <div className={`${Styles.kycTableRowAfter}`}><div className={`${Styles.imgWrap}`}><img src={acceptIcon} alt="accept"/></div></div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className={`${Styles.cabinetBtn}`}>Start verification process</div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={`${Styles.passwordWrap}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.cabinetTitle1}`}>{t('Смена пароля')}</div>
                    </div>
                    <div className={`${Styles.passwordBox}`}>
                        <CabinetCustomInput
                            gender='input'
                            type='password'
                            placeholder='********'
                            title={t('Старый пароль')}
                            value={old_password}
                            setValue={setOld_password}
                            errDispatch={changePasswordFailure}
                            errors={{full: changePasswordErr, type: 'old_password'}}
                        />
                        <CabinetCustomInput
                            gender='input'
                            type='password'
                            placeholder='********'
                            title={t('Новый пароль')}
                            value={password}
                            setValue={setPassword}
                            errDispatch={changePasswordFailure}
                            errors={{full: changePasswordErr, type: 'password'}}
                        />
                        <CabinetCustomInput
                            gender='input'
                            type='password'
                            placeholder='********'
                            title={t('Повторить новый пароль')}
                            value={password_confirmation}
                            setValue={setPassword_confirmation}
                            errDispatch={changePasswordFailure}
                            errors={{full: changePasswordErr, type: 'password_confirmation'}}
                        />
                        <div className={`${Styles.cabinetBtn}`} onClick={()=>{changePasswordHandler()}}>{t('Сменить пароль')}</div>
                    </div>
                </div>
                {/*<div className={`${Styles.walletWrap}`}>*/}
                {/*    <div className={`${Styles.titleRow}`}>*/}
                {/*        <div className={`${Styles.cabinetTitle1}`}>Wallet</div>*/}
                {/*    </div>*/}
                {/*    <div className={`${Styles.walletBox}`}>*/}
                {/*        <CabinetCustomInput gender='input' type='text' placeholder='0x0000000000000000000000' title='Add wallet'/>*/}
                {/*        <div className={`${Styles.cabinetBtnDisable}`}>ADD WALLET</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </section>
    );
};

export default CabinetSettingForm;