import React from "react";
import Styles from './styles.module.scss'
import NavItem from "../_UI/navItem";



const CabinetNavigationLinks = () => {

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <NavItem type='dashboard'/>
                <NavItem type='investments'/>
                <NavItem type='open_deposit'/>
                <NavItem type='wallets'/>
                <NavItem type='transactions'/>
                <NavItem type='partners'/>
                <NavItem type='settings'/>
            </div>
        </section>
    );
};

export default CabinetNavigationLinks;