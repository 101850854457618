import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import Seo from "../../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import ServicesContent from "../../../components/_UI/servicesContent";
import img1 from "../../../theme/assets/other/ssx_services_nt_img1.png";
import img2 from "../../../theme/assets/other/ssx_services_nt_img2.png";


const NftAndTokenizationPage = () => {
    const { t } = useTranslation();
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('NFT и токенизация')}/>
            <ServicesContent
                mainTitle={t('NFT и токенизация')}
                helloParagraph={t('NFT (невзаимозаменяемые токены) и услуги токенизации революционизируют способ представления собственности и подлинности в цифровом мире. Через технологию блокчейн уникальные цифровые активы, такие как произведения искусства, коллекционные предметы и недвижимость, могут быть токенизированы, создавая проверяемые и оборотные активы с неизменяемыми записями о собственности.')}
                helloList={false}
                mainImg={img1}
                middleTitle={t('Метрики для NFT и токенизации')}
                middleParagraph={t('Понимание этих метрик дает представление о развивающемся пейзаже NFT и токенизации, выявляя возможности для создателей, инвесторов и энтузиастов.')}
                middleArr={[
                    {title: t('$500 миллионов'), text: t('Общий объем продаж NFT')},
                    {title: '$2,500', text: t('Средняя цена продажи NFT')},
                    {title: t('1 миллион'), text: t('Количество транзакций с NFT')}
                ]}
                endImg={img2}
                endTitle={t('Рост рынка NFT')}
                endParagraph={t('Эти метрики демонстрируют эффективность и безопасность наших платежных систем. Высокий процент успешных транзакций и быстрые времена обработки обеспечивают беспроблемные операции, в то время как продвинутые системы обнаружения мошенничества обеспечивают надежную защиту и значительные экономии.')}
                endArr={[
                    {title: '300%', subTitle: t('Годовой рост продаж NFT'), text: t('Рынок NFT продолжает быстро расширяться благодаря увеличению осведомленности, технологическим достижениям и растущему интересу как создателей, так и коллекционеров.')},
                    {title: t('Лидирующие категории NFT'), subTitle: t('Искусство, Музыка, Коллекционные предметы, Виртуальная недвижимость'), text: t('Подчеркивает самые популярные категории NFT, показывая разнообразие цифровых активов, которые токенизируются и торгуются на блокчейн-платформах.')},
                    {title: t('$100 миллионов'), subTitle: t('Объем транзакций на рынке'), text: t('Представляет собой общий объем транзакций, обработанных ведущими рынками NFT, указывая на ликвидность и торговую активность в экосистеме NFT.')}
                ]}
            />
        </section>
    );
};

export default NftAndTokenizationPage;