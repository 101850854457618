import React from "react";
import Styles from './styles.module.scss';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Route, Routes, Outlet} from "react-router-dom";
import MainPage from "../../pages/mainPage";
import ContactPage from "../../pages/contactPage";
import Header from "../header";
import Footer from "../footer";
import CabinetMainPage from "../../pages/cabinetMainPage";
import CabinetNavigation from "../cabinetNavigation";
import CabinetDepositPage from "../../pages/cabinetDepositPage";
import CabinetTransactionsPage from "../../pages/cabinetTransactionsPage";
import CabinetPartnersPage from "../../pages/cabinetPartnersPage";
import CabinetSettingsPage from "../../pages/cabinetSettingsPage";
import CabinetHelpPage from "../../pages/cabinetHelpPage";
import LogPage from "../../pages/logPage";
import RegPage from "../../pages/regPage";
import ResetPage from "../../pages/resetPage";
import AboutPage from "../../pages/aboutPage";
import CabinetInvestmentsPage from "../../pages/cabinetInvestmentsPage";
import CabinetTopUpPage from "../../pages/cabinetTopupPage";
import CabinetWithdrawalPage from "../../pages/cabinetWithdrawalPage";
import CabinetWalletsPage from "../../pages/cabinetWalletsPage";
import ForgotPage from "../../pages/forgotPage";
import ExternalChat from "../externalChat";
import NewsPage from "../../pages/newsPage";
import PostPage from "../../pages/postPage";
import ServicesPage from "../../pages/servicesPage";
import CabinetInvestmentDetailsPage from "../../pages/cabinetInvestmentDetailsPage";
import AssetManagementPage from "../../pages/services/assetManagementPage";
import TransactionFeesPage from "../../pages/services/transactionFeesPage";
import CryptocurrencyExchangePage from "../../pages/services/cryptocurrencyExchangePage";
import PercentOnDepositsAndLoansPage from "../../pages/services/percentOnDepositsAndLoansPage";
import StakingAndMiningPage from "../../pages/services/stakingAndMiningPage";
import ConsultingServicesPage from "../../pages/services/consultingServicesPage";
import SmartContractsAndDefiPage from "../../pages/services/smartContractsAndDefiPage";
import CardsAndPaymentSystemsPage from "../../pages/services/cardsAndPaymentSystemsPage";
import EducationProgramsAndCoursesPage from "../../pages/services/educationProgramsAndCoursesPage";
import NftAndTokenizationPage from "../../pages/services/nftAndTokenizationPage";
import CustodialServicesPage from "../../pages/services/custodialServicesPage";


const MainLayout = () => (
    <div className={`${Styles.main}`}>
        <Header/>
        <Outlet/>
        <Footer/>
        <ToastContainer/>
    </div>
);

const CabinetLayout = () => (
    <div className={`${Styles.cabinet}`}>
        <div className={`${Styles.posWrap} ${Styles.pageContainer}`}>
            <CabinetNavigation/>
            <Outlet/>
            <ToastContainer/>
        </div>
    </div>
);


const App = () => {
    return (
        <section className={`${Styles.container}`}>
            <Routes>
                <Route element={<MainLayout/>}>
                    <Route exact path='/' element={<MainPage/>}/>
                    <Route exact path='/contacts' element={<ContactPage/>}/>
                    <Route exact path='/about' element={<AboutPage/>}/>
                    <Route exact path='/sign-in' element={<LogPage/>}/>
                    <Route exact path='/sign-up' element={<RegPage/>}/>
                    <Route exact path='/sign-up/ref/:code' element={<RegPage/>}/>
                    <Route exact path='/forgot-password' element={<ForgotPage/>}/>
                    <Route exact path='/reset-password/:email' element={<ResetPage/>}/>
                    <Route exact path='/news' element={<NewsPage/>}/>
                    <Route exact path='/news/:title' element={<PostPage/>}/>
                    <Route exact path='/services' element={<ServicesPage/>}/>
                    <Route exact path='/services/asset-management' element={<AssetManagementPage/>}/>
                    <Route exact path='/services/transaction-fees' element={<TransactionFeesPage/>}/>
                    <Route exact path='/services/cryptocurrency-exchange' element={<CryptocurrencyExchangePage/>}/>
                    <Route exact path='/services/percent-on-deposits-and-loans' element={<PercentOnDepositsAndLoansPage/>}/>
                    <Route exact path='/services/staking-and-mining' element={<StakingAndMiningPage/>}/>
                    <Route exact path='/services/consulting-services' element={<ConsultingServicesPage/>}/>
                    <Route exact path='/services/smart-contracts-and-defi' element={<SmartContractsAndDefiPage/>}/>
                    <Route exact path='/services/cards-and-payment-systems' element={<CardsAndPaymentSystemsPage/>}/>
                    <Route exact path='/services/education-programs-and-courses' element={<EducationProgramsAndCoursesPage/>}/>
                    <Route exact path='/services/nft-and-tokenization' element={<NftAndTokenizationPage/>}/>
                    <Route exact path='/services/custodial-services' element={<CustodialServicesPage/>}/>
                </Route>
                <Route element={<CabinetLayout/>}>
                    <Route exact path='/cabinet/dashboard' element={<CabinetMainPage/>}/>
                    <Route exact path='/cabinet/wallets' element={<CabinetWalletsPage/>}/>
                    <Route exact path='/cabinet/deposit' element={<CabinetDepositPage/>}/>
                    <Route exact path='/cabinet/transactions' element={<CabinetTransactionsPage/>}/>
                    <Route exact path='/cabinet/partners' element={<CabinetPartnersPage/>}/>
                    <Route exact path='/cabinet/settings' element={<CabinetSettingsPage/>}/>
                    <Route exact path='/cabinet/help' element={<CabinetHelpPage/>}/>
                    <Route exact path='/cabinet/investments' element={<CabinetInvestmentsPage/>}/>
                    <Route exact path='/cabinet/investments/:uuid' element={<CabinetInvestmentDetailsPage/>}/>
                    <Route exact path='/cabinet/topup' element={<CabinetTopUpPage/>}/>
                    <Route exact path='/cabinet/withdrawal' element={<CabinetWithdrawalPage/>}/>
                </Route>
            </Routes>
            <ExternalChat/>
        </section>
    );
};

export default App;