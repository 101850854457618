import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import Seo from "../../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import ServicesContent from "../../../components/_UI/servicesContent";
import img1 from "../../../theme/assets/other/ssx_services_ep_img1.png";
import img2 from "../../../theme/assets/other/ssx_services_ep_img2.png";


const EducationProgramsAndCoursesPage = () => {
    const { t } = useTranslation();
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Образовательные программы и курсы')}/>
            <ServicesContent
                mainTitle={t('Образовательные программы и курсы')}
                helloParagraph={t('Наши образовательные программы и курсы разработаны для того, чтобы дать людям знания и навыки, необходимые для успешной карьеры. Будь то желание улучшить профессиональные навыки, сменить карьеру или просто следовать своим увлечениям, наш разнообразный выбор курсов подходит для обучения студентов всех профилей и интересов. С опытными преподавателями, интерактивными учебными материалами и гибкими вариантами расписания мы готовы помочь вам достичь ваших образовательных целей.')}
                helloList={false}
                mainImg={img1}
                middleTitle={t('Участие в образовательных программах')}
                middleParagraph={t('Эти метрики подчеркивают широкое использование и удовлетворенность нашими услугами по карте. Высокие объемы транзакций и активные номера карт отражают доверие и надежность, в то время как высокая степень удовлетворенности указывает на положительный опыт пользователей.')}
                middleArr={[
                    {title: '5000', text: t('Общее количество участников')},
                    {title: t('4.8 из 5'), text: t('Средний рейтинг курса')},
                    {title: '100', text: t('Количество доступных курсов')},
                    {title: '80%', text: t('Процент успешно завершенных курсов')}
                ]}
                endImg={img2}
                endTitle={t('Развитие навыков и расширение знаний')}
                endParagraph={t('Эти метрики демонстрируют эффективность и безопасность наших платежных систем. Высокий процент успешных транзакций и быстрые времена обработки обеспечивают беспроблемные операции, в то время как продвинутые системы обнаружения мошенничества обеспечивают надежную защиту и значительные экономии.')}
                endArr={[
                    {title: '60%', text: t('Процент участников, которые улучшили свои навыки после завершения программы')},
                    {title: t('3 месяца'), text: t('Среднее время, затраченное на обучение')},
                    {title: '200', text: t('Количество участников, которые получили новую работу благодаря образовательным программам')},
                    {title: '15%', text: t('Процент участников, которые начали свой собственный бизнес после завершения программ')},
                ]}
                isRed={true}
            />
        </section>
    );
};

export default EducationProgramsAndCoursesPage;