import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import Seo from "../../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import ServicesContent from "../../../components/_UI/servicesContent";
import img1 from "../../../theme/assets/other/ssx_services_cs_img1.png";
import img2 from "../../../theme/assets/other/ssx_services_cs_img2.png";


const ConsultingServicesPage = () => {
    const { t } = useTranslation();
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Консалтинговые услуги')}/>
            <ServicesContent
                mainTitle={t('Консалтинговые услуги')}
                helloParagraph={t('Наши консалтинговые услуги предназначены для обеспечения всесторонней поддержки бизнеса и частных лиц, стремящихся оптимизировать свои финансовые стратегии. Мы предлагаем экспертные советы по широкому кругу финансовых вопросов, от планирования инвестиций до управления рисками, чтобы наши клиенты могли принимать обоснованные решения и достигать своих финансовых целей. Независимо от того, хотите ли вы оптимизировать свои операции, повысить прибыльность или более эффективно управлять рисками, наша команда опытных консультантов готова помочь вам на каждом этапе.')}
                helloList={false}
                mainImg={img1}
                middleTitle={t('Финансовые метрики планирования')}
                middleParagraph={t('Наши финансовые метрики планирования дают представление об эффективности наших консалтинговых услуг, демонстрируя преимущества, которые получают наши клиенты.')}
                middleArr={[
                    {title: t('8% в год'), text: t('Средняя рентабельность инвестиций в консалтинг')},
                    {title: '95%', text: t('Уровень удовлетворенности клиентов')},
                    {title: '1,200+', text: t('Количество успешных финансовых планов')},
                    {title: t('3 года'), text: t('Среднее время достижения финансовых целей')}
                ]}
                endImg={img2}
                endTitle={t('Метрики управления рисками')}
                endParagraph={t('Наши метрики управления рисками подчеркивают значительные улучшения и экономию затрат, которые клиенты достигают благодаря нашим экспертным стратегиям снижения рисков.')}
                endArr={[
                    {title: '120%', subTitle: t('Годовой темп роста ТВЛ'), text: t('Измеряет средний процент снижения операционных рисков для клиентов, использующих наши услуги по управлению рисками')},
                    {title: '30%', subTitle: t('Количество аудитов смарт-контрактов'), text: t('Указывает на среднее улучшение соблюдения нормативных требований, достигнутое благодаря нашим консалтинговым услугам.')},
                    {title: t('$250,000 на клиента'), subTitle: t('Средняя экономия затрат за счет снижения рисков'), text: t('Представляет собой среднюю сумму экономии средств, которую клиенты получают, внедряя наши стратегии управления рисками.')},
                    {title: '90%', subTitle: t('Уровень удержания клиентов'), text: t('Показывает процент клиентов, которые продолжают использовать наши консалтинговые услуги на протяжении многих лет благодаря успешным результатам.')},
                ]}
            />
        </section>
    );
};

export default ConsultingServicesPage;