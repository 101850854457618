import React from "react";
import Styles from './styles.module.scss';
import {useDispatch} from "react-redux";



const CabinetCustomInput = (props) => {
    const {gender, type, title, placeholder, inputNote, areaHeight, value, setValue, errors, errDispatch} = props;

    const dispatch = useDispatch();

    const inputGenderHandler = () =>{
        switch (gender){
            case 'input':
                return (<input
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e)=>{setValue(e.target.value)}}
                    onKeyDown={(event)=>{
                        if (errors){
                            if (errors.full?.[errors.type]){
                                delete errors.full[errors.type];
                                dispatch(errDispatch(errors.full));
                            }
                        }
                    }}
                />)
            case 'area':
                return (<textarea
                    placeholder={placeholder}
                    style={areaHeight ? {height: `${areaHeight}rem`} : null}
                    value={value}
                    onChange={(e)=>{setValue(e.target.value)}}
                    onKeyDown={(event)=>{
                        if (errors){
                            if (errors.full?.[errors.type]){
                                delete errors.full[errors.type];
                                dispatch(errDispatch(errors.full));
                            }
                        }
                    }}
                />)
            default :
                return (<input
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e)=>{setValue(e.target.value)}}
                    onKeyDown={(event)=>{
                        if (errors){
                            if (errors.full?.[errors.type]){
                                delete errors.full[errors.type];
                                dispatch(errDispatch(errors.full));
                            }
                        }
                    }}
                />)
        }
    }
    return (
        <div className={`${Styles.inputWrap}`}>
            {title ? <div className={`${Styles.inputTitle}`}>{title}</div> : null}
            {inputGenderHandler()}
            {inputNote ? <div className={`${Styles.inputNote}`}>{inputNote}</div> : null}
            <div className={`${Styles.inputErr}`}>{errors?.full?.[errors.type] ? errors?.full[errors.type] : null}</div>
        </div>
    );
};

export default CabinetCustomInput;