import React from "react";
import Styles from './styles.module.scss'
import ForgotForm from "../../components/forgotForm";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";



const ForgotPage = () => {
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Востановить пароль')}/>
            <ForgotForm/>
        </section>
    );
};

export default ForgotPage;