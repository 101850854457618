import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import CabinetHeader from "../../components/cabinetHeader";
import CabinetPartners from "../../components/cabinetPartners";
import {useDispatch, useSelector} from "react-redux";
import {
    getMainStats,
    getPartners,
    getPartnersAccruals,
    getPartnersDashboard,
    getReferrals,
    getUserData
} from "../../store/cabinet/action";
import {useNavigate} from "react-router-dom";
import PartnersModal from "../../components/partnersModal";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import CabinetPartnersAccruals from "../../components/cabinetPartnersAccruals";



const CabinetPartnersPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const referralsAll = useSelector(store => store.cabinet.referralsAll);

    const [withDep, setWithDep] = useState();
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);


    useEffect(()=>{
        if (!authStatus.authorization){
            navigate('/sign-in')
        }
    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getPartners());
        dispatch(getPartnersAccruals());
        dispatch(getPartnersDashboard());
        dispatch(getMainStats());
        dispatch(getUserData());
    },[dispatch])

    useEffect(()=>{
        dispatch(getReferrals(withDep))
    },[dispatch, withDep])
    
    useEffect(()=>{
        if (referralsAll?.current_page + 1 <= referralsAll?.last_page){
            dispatch(getReferrals(withDep, referralsAll?.current_page + 1))
        }

    },[dispatch, isScrolledToEnd, withDep]) // eslint-disable-line



    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Партнеры')}/>
            <div className={`${Styles.contentWrap} ${Styles.cabinetVerticalPadding}`}>
                <CabinetHeader/>
                <CabinetPartners filter={withDep} filterAction={setWithDep} scrollStatus={isScrolledToEnd} scrollAction={setIsScrolledToEnd}/>
                <CabinetPartnersAccruals/>
                <PartnersModal/>
            </div>
        </section>
    );
};

export default CabinetPartnersPage;