import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {valueFixer} from "../../helpers/valueFixer";
import CabinetCustomInput from "../_UI/cabinetCustomInput";

import dropArrow from '../../theme/assets/svg/oli_cabinet_drop_arrow.svg';
import {
    getDeposits,
    getWallets,
    openDeposit,
    setDepositModalStatus,
    setDepositRequestModalStatus
} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";



const CabinetDeposit = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const deposit = useSelector(store => store.cabinet.deposit);
    const openDepositStatus = useSelector(store => store.cabinet.openDeposit);
    const wallets = useSelector(store => store.cabinet.wallets);

    const [currentPlan, setCurrentPlan] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState( false);
    const [currencyList, setCurrencyList] = useState(false);
    const [dropDownStatus, setDropDownStatus] = useState(false);
    const [reInvest, setReInvest] = useState(false);
    const [availableBalance, setAvailableBalance] = useState(0);
    const [amount, setAmount] = useState('');

    const openDepositHandler = () =>{
        if (sendConditionHandler()){
            if (currentPlan?.is_available){
                dispatch(openDeposit(currentPlan.uuid, {amount: amount, auto_reinvest: reInvest, currency_id: currentCurrency.id}))
            } else {
                dispatch(setDepositRequestModalStatus({status: true, data: false}))
            }
        }
    }

    const sendConditionHandler = () =>{
        if (currentCurrency && amount > 0){
            return valueFixer(currentCurrency?.min_amount, 4) <= Number(amount) && valueFixer(currentCurrency?.max_amount, 4) >= Number(amount) && Number(amount) <= Number(availableBalance)
        }
    }
    
    useEffect(()=>{
        if (deposit){
            if (!currentPlan){
                setCurrentPlan(deposit[0]);
            }
            deposit.forEach((item)=>{
                if (item.uuid === currentPlan.uuid){
                    setCurrencyList(item?.currencies);
                    setCurrentCurrency(item?.currencies?.[0]);
                }
            })
        }
    },[currentPlan, deposit])
    
    useEffect(()=>{
        if (wallets && currentCurrency){
            wallets.some((item) => {
                if (item?.currency?.id === currentCurrency.id) {
                    setAvailableBalance(valueFixer(item.amount, 2));
                    return true;
                }
                return false;
            });
        }
    },[currentCurrency, wallets])
    
    useEffect(()=>{
        if (openDepositStatus){
            dispatch(setDepositModalStatus(true));
            setReInvest(false);
            setAmount('');
            dispatch(getDeposits());
            dispatch(getWallets());
        }
    },[dispatch, openDepositStatus])
    
    
    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.cabinetLargeCardWS}`}>
                <div className={`${Styles.titleRow}`}>
                    <div className={`${Styles.cabinetTitle1}`}>{t('Открыть депозит')}</div>
                </div>
                <div className={`${Styles.cabinetTitle2}`}>{t('Выберите план')}:</div>
                <div className={`${Styles.contentBox}`}>
                    {deposit ? deposit.map((item, index)=>{
                        return(
                            <div
                                className={`${Styles.contentBoxItem} ${currentPlan.uuid === item.uuid ? Styles.contentBoxItemActive : null} ${item.is_available ? null : Styles.contentBoxItemRequest}`}
                                key={'investData' + index}
                                onClick={()=>{
                                    if (item.is_available){
                                        setCurrentPlan(item)
                                    }
                                }}
                            >
                                <div className={`${Styles.contentBoxItemTitle}`}>{item.name}</div>
                                <div className={`${Styles.contentBoxItemDaily}`}>{item?.percent_from * 1}% <span>/{t('весь период')}</span></div>
                                <div className={`${Styles.contentBoxItemRowWrap}`}>
                                    {/*<div className={`${Styles.contentBoxItemRow}`}>*/}
                                    {/*    <div className={`${Styles.contentBoxItemRowName}`}>Min-Max:</div>*/}
                                    {/*    <div className={`${Styles.contentBoxItemRowValue}`}>{valueFixer(currentCurrency?.min_amount, 0)}-{valueFixer(currentCurrency?.max_amount, 0)} {currentCurrency?.code}</div>*/}
                                    {/*</div>*/}
                                    <div className={`${Styles.contentBoxItemRow}`}>
                                        <div className={`${Styles.contentBoxItemRowName}`}>{t('Продолжительность')}:</div>
                                        <div className={`${Styles.contentBoxItemRowValue}`}>{item.duration} {t('Дней')}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemRow}`}>
                                        <div className={`${Styles.contentBoxItemRowName}`}>{t('Начисления')}:</div>
                                        <div className={`${Styles.contentBoxItemRowValue}`}>{t('Ежедневно')}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemRow}`}>
                                        <div className={`${Styles.contentBoxItemRowName}`}>{t('Выплаты')}:</div>
                                        <div className={`${Styles.contentBoxItemRowValue}`}>{t('В конце')}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemRow}`}>
                                        <div className={`${Styles.contentBoxItemRowName}`}>{t('Сумма')} ({t('мин')}.):</div>
                                        <div className={`${Styles.contentBoxItemRowValue}`}>{item.min_amount_in_usd * 1}$</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemRow}`}>
                                        <div className={`${Styles.contentBoxItemRowName}`}>{t('Сумма')} ({t('макс')}.):</div>
                                        <div className={`${Styles.contentBoxItemRowValue}`}>{item.max_amount_in_usd * 1}$</div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxItemBg}`}/>
                                <div className={`${Styles.contentBoxItemAbsBtnWrap}`}>
                                    <div className={`${Styles.contentBoxItemAbsBtn}`} onClick={()=>{dispatch(setDepositRequestModalStatus({status: true, data: false}))}}>{t('Заявка')}</div>
                                </div>
                            </div>
                        )
                    }) : null}
                </div>
                <div className={`${Styles.currencyReinvestRow}`}>
                    <div className={`${Styles.currencyBox}`}>
                        <div className={`${Styles.cabinetTitle2}`}>{t('Выберите валюту')}:</div>
                        <div className={`${Styles.fakeInputWrap}`}>
                            <div
                                className={`${Styles.fakeInput}`}
                                onClick={()=>{setDropDownStatus(!dropDownStatus)}}
                            >
                                <div className={`${Styles.fakeInputValue}`}>{currentCurrency ? currentCurrency?.code : 'Loading...'}</div>
                                <div className={`${Styles.fakeInputArrowWrap} ${dropDownStatus ? Styles.fakeInputArrowWrapActive : null}`}><img src={dropArrow} alt="dropdown arrow"/></div>
                            </div>
                            <div className={`${Styles.absDropDown} ${dropDownStatus ? Styles.absDropDownActive : null}`}>
                                {currencyList ? currencyList.map((item, index)=>{
                                    return (
                                        <div
                                            className={`${Styles.absDropDownItem} ${currentCurrency?.id === item.id ? Styles.absDropDownItemActive : null}`}
                                            key={'currencyDrop' + index}
                                            onClick={()=>{
                                                setCurrentCurrency(item);
                                                setDropDownStatus(false);
                                            }}
                                        >
                                            {item.code}
                                        </div>
                                    )
                                }) : null}
                            </div>
                        </div>
                    </div>
                    {currentPlan?.setting?.is_auto_reinvest_available ?
                        <div className={`${Styles.reinvestBox}`}>
                            <div className={`${Styles.cabinetTitle2}`}>{t('Реинвестирование')}:</div>
                            <div className={`${Styles.reinvestRow}`}>
                                <div className={`${Styles.reinvestInput}`}>
                                    <input type="checkbox" onChange={(e)=>{setReInvest(e.target.checked)}}/>
                                </div>
                                <div className={`${Styles.reinvestNote}`}>
                                    {t('Вы можете включить функцию')} <span>"{t('Реинвестирование')}"</span> {t('чтобы автоматически реинвестировать полученные средства, обеспечивая непрерывный инвестиционный процесс')}.
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
                <div className={`${Styles.amountRow}`}>
                    <div className={`${Styles.cabinetTitle2}`}>{t('Сумма')}:</div>
                    <CabinetCustomInput
                        gender='input'
                        type='number'
                        placeholder={`Min: ${valueFixer(currentCurrency?.min_amount, 4)} ${currentCurrency?.code}`}
                        value={amount}
                        setValue={setAmount}
                    />
                    <div className={`${Styles.amountNote}`}>
                        <div className={`${Styles.amountNoteItem}`}>{t('Доступно')}: <span>{availableBalance} {currentCurrency?.code}</span></div>
                        <div className={`${Styles.amountNoteItem}`}>{`Min/Max: ${valueFixer(currentCurrency?.min_amount, 4)} - ${valueFixer(currentCurrency?.max_amount, 4)} ${currentCurrency?.code}`}</div>
                    </div>
                </div>
                <div className={`${Styles.btnRow}`} onClick={()=>{openDepositHandler()}}>
                    <div className={` ${sendConditionHandler() ? Styles.cabinetBtn : Styles.cabinetBtnDisable}`}>{t('Открыть депозит')}</div>
                </div>
            </div>
        </section>
    );
};

export default CabinetDeposit;