import React from "react";
import Styles from './styles.module.scss';
import ServicesItem from "../_UI/servicesItem";

import servicesIcon1 from '../../theme/assets/svg/ssx_services_icon1.svg';
import servicesIcon2 from '../../theme/assets/svg/ssx_services_icon2.svg';
import servicesIcon3 from '../../theme/assets/svg/ssx_services_icon3.svg';
import servicesIcon4 from '../../theme/assets/svg/ssx_services_icon4.svg';
import servicesIcon5 from '../../theme/assets/svg/ssx_services_icon5.svg';
import servicesIcon6 from '../../theme/assets/svg/ssx_services_icon6.svg';
import servicesIcon7 from '../../theme/assets/svg/ssx_services_icon7.svg';
import servicesIcon8 from '../../theme/assets/svg/ssx_services_icon8.svg';
import servicesIcon9 from '../../theme/assets/svg/ssx_services_icon9.svg';
import servicesIcon10 from '../../theme/assets/svg/ssx_services_icon10.svg';
import servicesIcon11 from '../../theme/assets/svg/ssx_services_icon11.svg';
import {useTranslation} from "react-i18next";




const ServicesMain = () => {
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} ${Styles.pageVerticalPadding}`}>
                <div className={`${Styles.title1}`}>{t('Наши сервисы')}</div>
                <div className={`${Styles.title2}`}>{t('Наши комплексные цифровые решения')}</div>
                <div className={`${Styles.titleNote}`}>
                    {t('SSX24 предоставляет инновационные финансовые решения на основе криптовалют, открывая перед пользователями множество возможностей для эффективного заработка и управления их цифровыми активами. Наша платформа ориентирована на обеспечение высочайшего уровня безопасности и удобства для каждого клиента. Мы стремимся предложить нашим пользователям целый спектр финансовых услуг, который не только удовлетворяет их текущие потребности, но и помогает им достигать финансовых целей, будь то сохранение или приумножение капитала')}.
                </div>
                <div className={`${Styles.titleNote}`}>
                    {t('Мы ориентированы на создание надежной экосистемы, в которой каждый может чувствовать себя уверенно, зная, что его активы защищены и находятся под контролем. SSX24 интегрирует самые современные технологии для предоставления уникальных возможностей заработка и эффективного управления криптовалютными активами')}.
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxRow}`}>
                        <ServicesItem
                            img={servicesIcon1}
                            title={t('Комиссии за транзакции')}
                            description={t('Криптобанки могут зарабатывать деньги, взимая комиссии за различные виды транзакций, такие как переводы между счетами, снятие средств, обмен криптовалют и другие операции.')}
                            link={'transaction-fees'}
                        />
                        <ServicesItem
                            img={servicesIcon2}
                            title={t('Обмен криптовалют')}
                            description={t('Предоставление услуг обмена различных криптовалют на платформе банка. За каждую операцию обмена может взиматься комиссия.')}
                            link={'cryptocurrency-exchange'}
                        />
                        <ServicesItem
                            img={servicesIcon3}
                            title={t('% на депозиты и кредиты')}
                            description={t('Как и традиционные банки, криптобанк может предоставлять кредиты, обеспеченные криптовалютой, и выплачивать проценты по депозитам. Разница между процентной ставкой по депозитам и ставкой по кредитам будет основным источником дохода.')}
                            link={'percent-on-deposits-and-loans'}
                        />
                        <ServicesItem
                            img={servicesIcon4}
                            title={t('Стейкинг и майнинг')}
                            description={t('Криптобанк может участвовать в стейкинге (размещении криптовалюты на блокчейне для подтверждения транзакций) и майнинге (добыче криптовалюты), зарабатывая вознаграждения от блокчейна.')}
                            link={'staking-and-mining'}
                        />
                    </div>
                    <div className={`${Styles.contentBoxRow}`}>
                        <ServicesItem
                            img={servicesIcon5}
                            title={t('Управление активами')}
                            description={t('Предоставление услуг управления криптоактивами для клиентов, помогая им инвестировать в различные криптовалюты и другие блокчейн-активы за плату.')}
                            link={'asset-management'}
                        />
                        <ServicesItem
                            img={servicesIcon6}
                            title={t('Консалтинговые услуги')}
                            description={t('Предоставление консультационных услуг по инвестициям в криптовалюту, управлению рисками, налоговому планированию и другим вопросам, связанным с криптовалютой.')}
                            link={'consulting-services'}
                        />
                        <ServicesItem
                            img={servicesIcon7}
                            title={t('Смарт-контракты и DeFi')}
                            description={t('Разработка и управление смарт-контрактами, а также предоставление услуг децентрализованного финансирования (DeFi), таких как децентрализованные займы и кредиты, обмен криптовалют и другие.')}
                            link={'smart-contracts-and-defi'}
                        />
                        <ServicesItem
                            img={servicesIcon8}
                            title={t('Карты и платежные системы')}
                            description={t('Выпуск криптовалютных карт, позволяющих клиентам использовать криптовалюту для повседневных покупок. Банк может получать комиссию за транзакции с использованием этих карт.')}
                            link={'cards-and-payment-systems'}
                        />
                    </div>
                    <div className={`${Styles.contentBoxRow}`}>
                        <ServicesItem
                            img={servicesIcon9}
                            title={t('Образовательные программы и курсы')}
                            description={t('Предоставление платных образовательных программ и курсов по криптовалютам, технологии блокчейн и инвестициям.')}
                            link={'education-programs-and-courses'}
                        />
                        <ServicesItem
                            img={servicesIcon10}
                            title={t('NFT и токенизация')}
                            description={t('Предоставление услуг по выпуску и управлению NFT (невзаимозаменяемыми токенами), а также токенизация и управление реальными активами (например, недвижимостью).')}
                            link={'nft-and-tokenization'}
                        />
                        <ServicesItem
                            img={servicesIcon11}
                            title={t('Кастодиальные услуги')}
                            description={t('Предоставление услуг безопасного хранения криптовалют и цифровых активов клиентов, обеспечивая высокий уровень защиты и надежности.')}
                            link={'custodial-services'}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesMain;