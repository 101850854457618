import React from "react";
import DOMPurify from "dompurify";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";




const PostMain = () => {

    const post = useSelector(store => store.main.post);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                {post ?
                    <div className={`${Styles.postWrap}`}>
                        <div className={`${Styles.postTitleRow}`}>
                            <div className={`${Styles.title2}`}>{post.title}</div>
                            <div className={`${Styles.postImgWrap}`}>
                                <img src={post.picture} alt={post.title}/>
                            </div>
                        </div>
                        <div
                            className={`${Styles.postContent}`}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(post.content),
                            }}
                        />
                    </div> : null
                }
            </div>
        </section>
    );
};

export default PostMain;