import React from "react";
import Styles from './styles.module.scss'
import NavItem from "../_UI/navItem";



const CabinetLogoutLinks = () => {

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <NavItem type='help'/>
                <NavItem type='logout'/>
            </div>
        </section>
    );
};

export default CabinetLogoutLinks;