import React from "react";
import Styles from './styles.module.scss';

import starIcon from '../../../theme/assets/svg/oli_main_review_star.svg';
import emptyStarIcon from '../../../theme/assets/svg/oli_main_review_empty_star.svg';



const MainSliderItem = (props) => {
    const {stars, review, authorName, authorAvatar} = props;

    const starsHandler = (stars) =>{
        switch (stars){
            case 1:
                return (
                    <div className={`${Styles.starPosWrap}`}>
                        <img src={starIcon} alt="star"/>
                        <img src={emptyStarIcon} alt="empty star"/>
                        <img src={emptyStarIcon} alt="empty star"/>
                        <img src={emptyStarIcon} alt="empty star"/>
                        <img src={emptyStarIcon} alt="empty star"/>
                    </div>
                )
            case 2:
                return (
                    <div className={`${Styles.starPosWrap}`}>
                        <img src={starIcon} alt="star"/>
                        <img src={starIcon} alt="star"/>
                        <img src={emptyStarIcon} alt="empty star"/>
                        <img src={emptyStarIcon} alt="empty star"/>
                        <img src={emptyStarIcon} alt="empty star"/>
                    </div>
                )
            case 3:
                return (
                    <div className={`${Styles.starPosWrap}`}>
                        <img src={starIcon} alt="star"/>
                        <img src={starIcon} alt="star"/>
                        <img src={starIcon} alt="star"/>
                        <img src={emptyStarIcon} alt="empty star"/>
                        <img src={emptyStarIcon} alt="empty star"/>
                    </div>
                )
            case 4:
                return (
                    <div className={`${Styles.starPosWrap}`}>
                        <img src={starIcon} alt="star"/>
                        <img src={starIcon} alt="star"/>
                        <img src={starIcon} alt="star"/>
                        <img src={starIcon} alt="star"/>
                        <img src={emptyStarIcon} alt="empty star"/>
                    </div>
                )
            case 5:
                return (
                    <div className={`${Styles.starPosWrap}`}>
                        <img src={starIcon} alt="star"/>
                        <img src={starIcon} alt="star"/>
                        <img src={starIcon} alt="star"/>
                        <img src={starIcon} alt="star"/>
                        <img src={starIcon} alt="star"/>
                    </div>
                )
            default:
                return null
        }
    }

    return (
        <div className={`${Styles.sliderItem}`}>
            <div className={`${Styles.sliderItemStarWrap}`}>
                {starsHandler(stars)}
            </div>
            <div className={`${Styles.sliderItemReview}`}>{review}</div>
            <div className={`${Styles.sliderItemAuthorWrap}`}>
                <div className={`${Styles.sliderItemAuthorAvatarWrap}`}>
                    <img src={authorAvatar} alt={authorName}/>
                </div>
                <div className={`${Styles.sliderItemAuthorDataWrap}`}>
                    <div className={`${Styles.sliderItemAuthorName}`}>{authorName}</div>
                    {/*<div className={`${Styles.sliderItemAuthorPosition}`}>{authorPosition}</div>*/}
                </div>
            </div>
        </div>
    );
};

export default MainSliderItem;