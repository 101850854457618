import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import Seo from "../../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import ServicesContent from "../../../components/_UI/servicesContent";
import img1 from "../../../theme/assets/other/ssx_services_custodial_img1.png";
import img2 from "../../../theme/assets/other/ssx_services_custodial_img2.png";


const CustodialServicesPage = () => {
    const { t } = useTranslation();
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Кастодиальные услуги')}/>
            <ServicesContent
                mainTitle={t('Кастодиальные услуги')}
                helloParagraph={t('Кастодиальные услуги — это финансовые услуги, предоставляемые банками или финансовыми учреждениями для обеспечения сохранности и управления активами от имени клиентов. Эти услуги особенно важны для институциональных инвесторов, таких как пенсионные фонды, паевые инвестиционные фонды и хедж-фонды, а также для лиц с высоким уровнем дохода. Кастодианы предлагают широкий спектр услуг, включая хранение активов, расчет по сделкам, сбор дивидендов и процентных выплат, а также отчетность по портфелям и транзакциям. Доверяя свои активы кастодиальным услугам, клиенты получают преимущества в виде повышенной безопасности, эффективного управления активами и соблюдения нормативных требований.')}
                helloList={false}
                mainImg={img1}
                middleTitle={t('Подробнее о метриках кастодиальных услуг')}
                middleParagraph={t('Погружение в мир кастодиальных услуг с этими ключевыми метриками, отражающими надежность и масштаб управления активами. От огромного общего объема активов под опекой до впечатляющих темпов роста — эти цифры подчеркивают значимость кастодиальных услуг в финансовой экосистеме.')}
                middleArr={[
                    {title: t('$10 триллионов'), text: t('Общий объем активов под опекой')},
                    {title: '50,000', text: t('Количество клиентских счетов')},
                    {title: '8%', text: t('Среднегодовой рост активов')},
                    {title: '80%', text: t('Процент институциональных клиентов')}
                ]}
                endImg={img2}
                endTitle={t('Метрики кастодиальных услуг')}
                endParagraph={false}
                endArr={[
                    {title: '$200,000', subTitle: t('Средний баланс счета'), text: t('Отражает среднюю стоимость средств на каждом клиентском счете, предоставляя представление об уровне капитализации и богатства клиентов.')},
                    {title: '95%', subTitle: t('Уровень удержания клиентов'), text: t('Показывает процент клиентов, которые продолжают пользоваться кастодиальными услугами после определенного периода, демонстрируя их удовлетворенность и доверие к услугам.')},
                    {title: '50%', subTitle: t('Средняя диверсификация портфеля'), text: t('Процент активов, распределенных по различным классам активов в клиентских портфелях, подчеркивая стратегии диверсификации, используемые клиентами с помощью кастодиальных услуг.')},
                    {title: '9.5/10', subTitle: t('Оценка удовлетворенности клиентов'), text: t('Представляет собой средний рейтинг, присваиваемый клиентами за их общую удовлетворенность предоставляемыми кастодиальными услугами, демонстрируя высокий уровень удовлетворенности и доверия среди клиентов.')},
                ]}
                isRed={true}
            />
        </section>
    );
};

export default CustodialServicesPage;