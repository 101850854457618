import React from "react";
import Styles from './styles.module.scss'
import ResetForm from "../../components/resetForm";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const ResetPage = () => {
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Сброс пароля')}/>
            <ResetForm/>
        </section>
    );
};

export default ResetPage;