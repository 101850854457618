import React, {useState} from "react";
import {scroller} from "react-scroll";
import Styles from './styles.module.scss'
import Logo from "../_UI/logo";
import LangSwitcher from "../langSwitcher";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setLinkScrollStatus} from "../../store/main/action";
import {pageScrollHandler} from "../../helpers/pageScrollHandler";
import {useTranslation} from "react-i18next";



const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);

    const [isNavOpen, setNavOpen] = useState(false);

    const scrollHandler = (element) =>{
        scroller.scrollTo(element, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    const navigationHandler = (page) =>{
        if (pageScrollHandler()){
            dispatch(setLinkScrollStatus(page));
            navigate('/');
        } else {
            scrollHandler(page);
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <div className={`${Styles.logoWrap}`}>
                    <Logo/>
                    <div className={`${Styles.langBox}`}>
                        <LangSwitcher/>
                    </div>
                </div>
                <div className={`${Styles.navWrap} ${isNavOpen ? Styles.navWrapOpen : null}`}>
                    <div className={`${Styles.navPosWrap}`}>
                        <div className={`${Styles.navBox}`}>
                            <div className={`${Styles.navBoxItem}`} onClick={()=>{navigate('/about')}}>{t('О нас')}</div>
                            <div className={`${Styles.navBoxItem}`} onClick={()=>{navigate('/services')}}>{t('Наши сервисы')}</div>
                            <div className={`${Styles.navBoxItem}`} onClick={()=>{navigationHandler('advantages')}}>{t('Преимущества')}</div>
                            <div className={`${Styles.navBoxItem}`} onClick={()=>{navigate('/contacts')}}>{t('Контакты')}</div>
                            <div className={`${Styles.navBoxItem}`} onClick={()=>{navigationHandler('faq')}}>FAQ</div>
                        </div>
                        {authStatus.authorization ?
                            <div className={`${Styles.btnWrap} ${Styles.btnWrapC}`}>
                                <div className={`${Styles.customBtnBlueT}`} onClick={()=>{navigate('/cabinet/dashboard')}}>{t('Кабинет')}</div>
                            </div> :
                            <div className={`${Styles.btnWrap}`}>
                                <div className={`${Styles.customBtnBlueT}`} onClick={()=>{navigate('/sign-up')}}>{t('Регистрация')}</div>
                                <div className={`${Styles.customBtnBlueT}`} onClick={()=>{navigate('/sign-in')}}>{t('Вход')}</div>
                            </div>
                        }
                        <div className={`${Styles.langBox}`}>
                            <LangSwitcher/>
                        </div>
                    </div>
                </div>
                <div
                    className={`${Styles.navBtn} ${isNavOpen ? Styles.navBtnOpen : null}`}
                    onClick={()=>{setNavOpen(!isNavOpen)}}
                >
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>
        </section>
    );
};

export default Header;