import React from "react";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";
import AboutSliderItem from "../_UI/aboutSliderItem";
import {useTranslation} from "react-i18next";




const NewsAll = (props) => {
    const {currentPage, setCurrentPage} = props;
    const { t } = useTranslation();

    const news = useSelector(store => store.main.allNews);
    const lastPage = useSelector(store => store.main.allNewsLastPage);

    const moreNewsBtnHandler = () =>{
        if (lastPage >= currentPage + 1){
            setCurrentPage(currentPage + 1)
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} ${Styles.pageVerticalPadding}`}>
                <div className={`${Styles.title2} ${Styles.pageContainer}`}>{t('Наш Блог')}</div>
                <div className={`${Styles.newsWrap}`}>
                    {news.length > 0 ? news?.map((item, index)=>{
                            return (
                                <AboutSliderItem
                                    key={'allNews' + index}
                                    id={item.id}
                                    title={item.title}
                                    description={item.content}
                                    date={item.date}
                                    img={item.picture}
                                />
                            )
                        }) : null
                    }
                </div>
                <div className={`${Styles.btnWrap} ${lastPage >= currentPage + 1 ? null : Styles.btnWrapHide}`}>
                    <div className={`${Styles.customBtnBlue}`} onClick={()=>{moreNewsBtnHandler()}}>{t('Больше новостей')}</div>
                </div>
            </div>
        </section>
    );
};

export default NewsAll;