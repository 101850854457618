import {
    TEST,
    GET_WALLETS_STARTED,
    GET_WALLETS_SUCCESS,
    GET_WALLETS_FAILURE,
    GET_PARTNERS_STARTED,
    GET_PARTNERS_SUCCESS,
    GET_PARTNERS_FAILURE,
    GET_DEPOSIT_STARTED,
    GET_DEPOSIT_SUCCESS,
    GET_DEPOSIT_FAILURE,
    OPEN_DEPOSIT_STARTED,
    OPEN_DEPOSIT_SUCCESS,
    OPEN_DEPOSIT_FAILURE,
    SET_DEPOSIT_MODAL_STATUS,
    GET_USER_DEPOSIT_STARTED,
    GET_USER_DEPOSIT_SUCCESS,
    GET_USER_DEPOSIT_FAILURE,
    GET_TRANSACTIONS_STARTED,
    GET_TRANSACTIONS_SUCCESS,
    GET_TRANSACTIONS_FAILURE,
    LOGOUT_STARTED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    GET_AVAILABLE_CURRENCIES_STARTED,
    GET_AVAILABLE_CURRENCIES_SUCCESS,
    GET_AVAILABLE_CURRENCIES_FAILURE,
    TOPUP_STARTED,
    TOPUP_SUCCESS,
    TOPUP_FAILURE,
    SET_TOPUP_MODAL_STATUS,
    WITHDRAW_STARTED,
    WITHDRAW_SUCCESS,
    WITHDRAW_FAILURE,
    SET_WITHDRAW_MODAL_STATUS,
    GET_PARTNERS_DASHBOARD_STARTED,
    GET_PARTNERS_DASHBOARD_SUCCESS,
    GET_PARTNERS_DASHBOARD_FAILURE,
    GET_MAIN_STATS_STARTED,
    GET_MAIN_STATS_SUCCESS,
    GET_MAIN_STATS_FAILURE,
    GET_REFERRALS_STARTED,
    GET_REFERRALS_SUCCESS,
    GET_REFERRALS_FAILURE,
    SEND_HELP_FORM_STARTED,
    SEND_HELP_FORM_SUCCESS,
    SEND_HELP_FORM_FAILURE,
    SET_DEPOSIT_REQUEST_MODAL_STATUS,
    SEND_CHAT_MESSAGE,
    SET_PARTNERS_MODAL_STATUS,
    CHANGE_PASSWORD_STARTED,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    GET_USER_DATA_STARTED,
    GET_USER_DATA_SUCCESS,
    GET_USER_DATA_FAILURE,
    GET_INVEST_DETAILS_STARTED,
    GET_INVEST_DETAILS_SUCCESS,
    GET_INVEST_DETAILS_FAILURE,
    GET_INVEST_DETAILS_OPERATIONS_STARTED,
    GET_INVEST_DETAILS_OPERATIONS_SUCCESS,
    GET_INVEST_DETAILS_OPERATIONS_FAILURE,
    GET_INVEST_DETAILS_TRANSACTIONS_STARTED,
    GET_INVEST_DETAILS_TRANSACTIONS_SUCCESS,
    GET_INVEST_DETAILS_TRANSACTIONS_FAILURE,
    SET_NAVIGATION_STATUS,
    SET_INVEST_DETAILS_REINVEST_STARTED,
    SET_INVEST_DETAILS_REINVEST_SUCCESS,
    SET_INVEST_DETAILS_REINVEST_FAILURE,
    INVEST_DEPOSIT_STARTED,
    INVEST_DEPOSIT_SUCCESS,
    INVEST_DEPOSIT_FAILURE,
    SET_WITHDRAW_COMMISSION_MODAL_STATUS,
    GET_PARTNERS_ACCRUALS_STARTED,
    GET_PARTNERS_ACCRUALS_SUCCESS,
    GET_PARTNERS_ACCRUALS_FAILURE
} from "./types";


const initialState = {
    test: false,
    depositModalStatus: false,
    navigationStatus: false,
    partnersModalStatus: false,
    chatMessage: false,
    depositRequestModalStatus: {status: false, data: false},
    topupModalStatus: false,
    withdrawModalStatus: false,
    withdrawCommissionModalStatus: {status: false, data: false},
    userDataStarted: false,
    userData: false,
    userDataErr: false,
    walletsStarted: false,
    wallets: false,
    walletsErr: false,
    partnersStarted: false,
    partners: false,
    partnersErr: false,
    partnersAccrualsStarted: false,
    partnersAccruals: false,
    partnersAccrualsErr: false,
    partnersDashboardStarted: false,
    partnersDashboard: false,
    partnersDashboardErr: false,
    depositStarted: false,
    deposit: false,
    depositErr: false,
    openDepositStarted: false,
    openDeposit: false,
    openDepositErr: false,
    userDepositStarted: false,
    userDeposit: false,
    userDepositErr: false,
    transactionsStarted: false,
    transactions: false,
    transactionsErr: false,
    logoutStarted: false,
    logout: false,
    logoutErr: false,
    availableCurrenciesStarted: false,
    availableCurrencies: false,
    availableCurrenciesErr: false,
    topupStatusStarted: false,
    topupStatus: false,
    topupStatusErr: false,
    withdrawStatusStarted: false,
    withdrawStatus: false,
    withdrawStatusErr: false,
    mainStatsStarted: false,
    mainStats: false,
    mainStatsErr: false,
    referralsAllStarted: false,
    referralsAll: false,
    referralsAllErr: false,
    helpFormStatusStarted: false,
    helpFormStatus: false,
    helpFormStatusErr: false,
    changePasswordStatusStarted: false,
    changePasswordStatus: false,
    changePasswordStatusErr: false,
    investDetailsStarted: false,
    investDetails: false,
    investDetailsErr: false,
    investDetailsOperationsStarted: false,
    investDetailsOperations: false,
    investDetailsOperationsErr: false,
    investDetailsTransactionsStarted: false,
    investDetailsTransactions: false,
    investDetailsTransactionsErr: false,
    setInvestDetailsReinvestStatusStarted: false,
    setInvestDetailsReinvestStatus: false,
    setInvestDetailsReinvestStatusErr: false,
    investDepositStatusStarted: false,
    investDepositStatus: false,
    investDepositStatusErr: false,
};

export function cabinetReducer(state = initialState, action) {
    switch (action.type) {
        case TEST:
            return {
                ...state,
                test: action.payload
            };
        case SET_DEPOSIT_MODAL_STATUS:
            return {
                ...state,
                depositModalStatus: action.payload
            };
        case SET_WITHDRAW_COMMISSION_MODAL_STATUS:
            return {
                ...state,
                withdrawCommissionModalStatus: action.payload
            };
        case SET_NAVIGATION_STATUS:
            return {
                ...state,
                navigationStatus: action.payload
            };
        case SET_PARTNERS_MODAL_STATUS:
            return {
                ...state,
                partnersModalStatus: action.payload
            };
        case SEND_CHAT_MESSAGE:
            return {
                ...state,
                chatMessage: action.payload
            };
        case SET_DEPOSIT_REQUEST_MODAL_STATUS:
            return {
                ...state,
                depositRequestModalStatus: action.payload
            };
        case SET_TOPUP_MODAL_STATUS:
            return {
                ...state,
                topupModalStatus: action.payload
            };
        case SET_WITHDRAW_MODAL_STATUS:
            return {
                ...state,
                withdrawModalStatus: action.payload
            };
        case GET_WALLETS_STARTED:
            return {
                ...state,
                walletsStarted: true
            };
        case GET_WALLETS_SUCCESS:
            return {
                ...state,
                wallets: action.payload,
                walletsStarted: false
            };
        case GET_WALLETS_FAILURE:
            return {
                ...state,
                walletsErr: action.payload,
                walletsStarted: false
            };
        case GET_PARTNERS_STARTED:
            return {
                ...state,
                partnersStarted: true
            };
        case GET_PARTNERS_SUCCESS:
            return {
                ...state,
                partners: action.payload,
                partnersStarted: false
            };
        case GET_PARTNERS_FAILURE:
            return {
                ...state,
                partnersErr: action.payload,
                partnersStarted: false
            };
        case GET_PARTNERS_ACCRUALS_STARTED:
            return {
                ...state,
                partnersAccrualsStarted: true
            };
        case GET_PARTNERS_ACCRUALS_SUCCESS:
            return {
                ...state,
                partnersAccruals: action.payload,
                partnersAccrualsStarted: false
            };
        case GET_PARTNERS_ACCRUALS_FAILURE:
            return {
                ...state,
                partnersAccrualsErr: action.payload,
                partnersAccrualsStarted: false
            };
        case GET_DEPOSIT_STARTED:
            return {
                ...state,
                depositStarted: true
            };
        case GET_DEPOSIT_SUCCESS:
            return {
                ...state,
                deposit: action.payload,
                depositStarted: false
            };
        case GET_DEPOSIT_FAILURE:
            return {
                ...state,
                depositErr: action.payload,
                depositStarted: false
            };
        case OPEN_DEPOSIT_STARTED:
            return {
                ...state,
                openDepositStarted: true
            };
        case OPEN_DEPOSIT_SUCCESS:
            return {
                ...state,
                openDeposit: action.payload,
                openDepositStarted: false
            };
        case OPEN_DEPOSIT_FAILURE:
            return {
                ...state,
                openDepositErr: action.payload,
                openDepositStarted: false
            };
        case GET_USER_DEPOSIT_STARTED:
            return {
                ...state,
                userDepositStarted: true
            };
        case GET_USER_DEPOSIT_SUCCESS:
            return {
                ...state,
                userDeposit: action.payload,
                userDepositStarted: false
            };
        case GET_USER_DEPOSIT_FAILURE:
            return {
                ...state,
                userDepositErr: action.payload,
                userDepositStarted: false
            };
        case GET_TRANSACTIONS_STARTED:
            return {
                ...state,
                transactionsStarted: true
            };
        case GET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                transactions: action.payload,
                transactionsStarted: false
            };
        case GET_TRANSACTIONS_FAILURE:
            return {
                ...state,
                transactionsErr: action.payload,
                transactionsStarted: false
            };
        case LOGOUT_STARTED:
            return {
                ...state,
                logoutStarted: true
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                logout: action.payload,
                logoutStarted: false
            };
        case LOGOUT_FAILURE:
            return {
                ...state,
                logoutErr: action.payload,
                logoutStarted: false
            };
        case GET_AVAILABLE_CURRENCIES_STARTED:
            return {
                ...state,
                availableCurrenciesStarted: true
            };
        case GET_AVAILABLE_CURRENCIES_SUCCESS:
            return {
                ...state,
                availableCurrencies: action.payload,
                availableCurrenciesStarted: false
            };
        case GET_AVAILABLE_CURRENCIES_FAILURE:
            return {
                ...state,
                availableCurrenciesErr: action.payload,
                availableCurrenciesStarted: false
            };
        case TOPUP_STARTED:
            return {
                ...state,
                topupStatusStarted: true
            };
        case TOPUP_SUCCESS:
            return {
                ...state,
                topupStatus: action.payload,
                topupStatusStarted: false
            };
        case TOPUP_FAILURE:
            return {
                ...state,
                topupStatusErr: action.payload,
                topupStatusStarted: false
            };
        case WITHDRAW_STARTED:
            return {
                ...state,
                withdrawStatusStarted: true
            };
        case WITHDRAW_SUCCESS:
            return {
                ...state,
                withdrawStatus: action.payload,
                withdrawStatusStarted: false
            };
        case WITHDRAW_FAILURE:
            return {
                ...state,
                withdrawStatusErr: action.payload,
                withdrawStatusStarted: false
            };
        case GET_PARTNERS_DASHBOARD_STARTED:
            return {
                ...state,
                partnersDashboardStarted: true
            };
        case GET_PARTNERS_DASHBOARD_SUCCESS:
            return {
                ...state,
                partnersDashboard: action.payload,
                partnersDashboardStarted: false
            };
        case GET_PARTNERS_DASHBOARD_FAILURE:
            return {
                ...state,
                partnersDashboardErr: action.payload,
                partnersDashboardStarted: false
            };
        case GET_MAIN_STATS_STARTED:
            return {
                ...state,
                mainStatsStarted: true
            };
        case GET_MAIN_STATS_SUCCESS:
            return {
                ...state,
                mainStats: action.payload,
                mainStatsStarted: false
            };
        case GET_MAIN_STATS_FAILURE:
            return {
                ...state,
                mainStatsErr: action.payload,
                mainStatsStarted: false
            };
        case GET_REFERRALS_STARTED:
            return {
                ...state,
                referralsAllStarted: true
            };
        case GET_REFERRALS_SUCCESS:
            return {
                ...state,
                referralsAll: action.payload,
                referralsAllStarted: false
            };
        case GET_REFERRALS_FAILURE:
            return {
                ...state,
                referralsAllErr: action.payload,
                referralsAllStarted: false
            };
        case SEND_HELP_FORM_STARTED:
            return {
                ...state,
                helpFormStatusStarted: true
            };
        case SEND_HELP_FORM_SUCCESS:
            return {
                ...state,
                helpFormStatus: action.payload,
                helpFormStatusStarted: false
            };
        case SEND_HELP_FORM_FAILURE:
            return {
                ...state,
                helpFormStatusErr: action.payload,
                helpFormStatusStarted: false
            };
        case CHANGE_PASSWORD_STARTED:
            return {
                ...state,
                changePasswordStatusStarted: true
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordStatus: action.payload,
                changePasswordStatusStarted: false
            };
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePasswordStatusErr: action.payload,
                changePasswordStatusStarted: false
            };
        case GET_USER_DATA_STARTED:
            return {
                ...state,
                userDataStarted: true
            };
        case GET_USER_DATA_SUCCESS:
            return {
                ...state,
                userData: action.payload,
                userDataStarted: false
            };
        case GET_USER_DATA_FAILURE:
            return {
                ...state,
                userDataErr: action.payload,
                userDataStarted: false
            };
        case GET_INVEST_DETAILS_STARTED:
            return {
                ...state,
                investDetailsStarted: true
            };
        case GET_INVEST_DETAILS_SUCCESS:
            return {
                ...state,
                investDetails: action.payload,
                investDetailsStarted: false
            };
        case GET_INVEST_DETAILS_FAILURE:
            return {
                ...state,
                investDetailsErr: action.payload,
                investDetailsStarted: false
            };
        case GET_INVEST_DETAILS_OPERATIONS_STARTED:
            return {
                ...state,
                investDetailsOperationsStarted: true
            };
        case GET_INVEST_DETAILS_OPERATIONS_SUCCESS:
            return {
                ...state,
                investDetailsOperations: action.payload,
                investDetailsOperationsStarted: false
            };
        case GET_INVEST_DETAILS_OPERATIONS_FAILURE:
            return {
                ...state,
                investDetailsOperationsErr: action.payload,
                investDetailsOperationsStarted: false
            };
        case GET_INVEST_DETAILS_TRANSACTIONS_STARTED:
            return {
                ...state,
                investDetailsTransactionsStarted: true
            };
        case GET_INVEST_DETAILS_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                investDetailsTransactions: action.payload,
                investDetailsTransactionsStarted: false
            };
        case GET_INVEST_DETAILS_TRANSACTIONS_FAILURE:
            return {
                ...state,
                investDetailsTransactionsErr: action.payload,
                investDetailsTransactionsStarted: false
            };
        case SET_INVEST_DETAILS_REINVEST_STARTED:
            return {
                ...state,
                setInvestDetailsReinvestStatusStarted: true
            };
        case SET_INVEST_DETAILS_REINVEST_SUCCESS:
            return {
                ...state,
                setInvestDetailsReinvestStatus: action.payload,
                setInvestDetailsReinvestStatusStarted: false
            };
        case SET_INVEST_DETAILS_REINVEST_FAILURE:
            return {
                ...state,
                setInvestDetailsReinvestStatusErr: action.payload,
                setInvestDetailsReinvestStatusStarted: false
            };
        case INVEST_DEPOSIT_STARTED:
            return {
                ...state,
                investDepositStatusStarted: true
            };
        case INVEST_DEPOSIT_SUCCESS:
            return {
                ...state,
                investDepositStatus: action.payload,
                investDepositStatusStarted: false
            };
        case INVEST_DEPOSIT_FAILURE:
            return {
                ...state,
                investDepositStatusErr: action.payload,
                investDepositStatusStarted: false
            };
        default:
            return state;
    }
}

