import React from "react";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import statsIcon1 from '../../theme/assets/svg/oli_cabinet_stats_icon1.svg';
import statsIcon2 from '../../theme/assets/svg/oli_cabinet_stats_icon2.svg';
import statsIcon3 from '../../theme/assets/svg/oli_cabinet_stats_icon3.svg';
import statsIcon4 from '../../theme/assets/svg/oli_cabinet_stats_icon4.svg';
import {valueShorter} from "../../helpers/valueShorter";



const CabinetHeader = () => {
    const { t } = useTranslation();

    const stats = useSelector(store => store.cabinet.mainStats);

    return (
        <section className={`${Styles.container} ${window.location.pathname.indexOf('dashboard') !== -1 ? Styles.containerM : null}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.headerItem} ${Styles.cabinetCard}`}>
                    <div className={`${Styles.balanceRow}`}>
                        <div className={`${Styles.balanceRowPosWrap}`}>
                            <div className={`${Styles.balanceIconWrap}`}>
                                <img src={statsIcon2} alt="earned from deposit"/>
                            </div>
                            <div className={`${Styles.balanceContentWrap}`}>
                                <div className={`${Styles.balanceTitle}`}>{t('Персональный доход')}</div>
                                <div className={`${Styles.balance}`}>${stats?.earned_from_deposit ? valueShorter(stats?.earned_from_deposit, 2) : '0'}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.headerItem} ${Styles.cabinetCard}`}>
                    <div className={`${Styles.balanceRow}`}>
                        <div className={`${Styles.balanceRowPosWrap}`}>
                            <div className={`${Styles.balanceIconWrap}`}>
                                <img src={statsIcon4} alt="earned from referrals"/>
                            </div>
                            <div className={`${Styles.balanceContentWrap}`}>
                                <div className={`${Styles.balanceTitle}`}>{t('Реферальный доход')}</div>
                                <div className={`${Styles.balance}`}>${stats?.earned_from_referrals ? valueShorter(stats?.earned_from_referrals, 2) : '0'}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.headerItem} ${Styles.cabinetCard}`}>
                    <div className={`${Styles.balanceRow}`}>
                        <div className={`${Styles.balanceRowPosWrap}`}>
                            <div className={`${Styles.balanceIconWrap}`}>
                                <img src={statsIcon3} alt="partners count"/>
                            </div>
                            <div className={`${Styles.balanceContentWrap}`}>
                                <div className={`${Styles.balanceTitle}`}>{t('Привлеченных партнеров')}</div>
                                <div className={`${Styles.balance}`}>{stats?.partners_count ? valueShorter(stats?.partners_count, 2) : '0'}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.headerItem} ${Styles.cabinetCard}`}>
                    <div className={`${Styles.balanceRow}`}>
                        <div className={`${Styles.balanceRowPosWrap}`}>
                            <div className={`${Styles.balanceIconWrap}`}>
                                <img src={statsIcon1} alt="total invested"/>
                            </div>
                            <div className={`${Styles.balanceContentWrap}`}>
                                <div className={`${Styles.balanceTitle}`}>{t('Всего инвестировано')}</div>
                                <div className={`${Styles.balance}`}>${stats?.total_invested ? valueShorter(stats?.total_invested, 2) : '0'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CabinetHeader;