import React, {useEffect, useState, useCallback} from "react";
import Styles from './styles.module.scss';
import CustomInput from "../_UI/customInput";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {login, loginFailure} from "../../store/main/action";
import {useTranslation} from "react-i18next";



const LogForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const formErr = useSelector(store => store.main.loginErr);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const sendFormHandler = useCallback(()=>{
        dispatch(login({email, password}));
    }, [email, password, dispatch])

    useEffect(()=>{
        if (authStatus.authorization){
            navigate('/cabinet/dashboard');
        }
    },[authStatus, navigate])

    // useEffect(() => {
    //     const onKeyDown = e => {
    //         if(e.keyCode === 13) {
    //             sendFormHandler();
    //         }
    //     };
    //     document.addEventListener('keydown', onKeyDown);
    //     return () => {
    //         document.removeEventListener('keydown', onKeyDown);
    //     };
    // }, [sendFormHandler]);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} `}>
                <div className={`${Styles.title2}`}>{t('Вход')}</div>
                <div className={`${Styles.contentBox}`}>
                    <CustomInput
                        gender='input'
                        type='text'
                        title={t('Почта')}
                        placeholder='example@mail.com'
                        value={email}
                        setValue={setEmail}
                        errDispatch={loginFailure}
                        errors={{full: formErr, type: 'email'}}
                    />
                    <CustomInput
                        gender='input'
                        type='password'
                        title={t('Пароль')}
                        placeholder='***********'
                        value={password}
                        setValue={setPassword}
                        errDispatch={loginFailure}
                        errors={{full: formErr, type: 'password'}}
                    />
                    <div className={Styles.customBtnBlue} style={{width: '100%'}} onClick={()=>{sendFormHandler()}}>{t('Вход')}</div>
                </div>
                <div className={`${Styles.linkWrap}`}>
                    <div className={`${Styles.linkRow}`}>{t('Забыли пароль')}? <span onClick={()=>{navigate('/forgot-password')}}>{t('Востановить пароль')}</span></div>
                    <div className={`${Styles.linkRow}`}>{t('Еще нет аккаунта?')} <span onClick={()=>{navigate('/sign-up')}}>{t('Регистрация')}</span></div>
                </div>
            </div>
        </section>
    );
};

export default LogForm;