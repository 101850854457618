import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';
import {useDispatch, useSelector} from "react-redux";
import CryptoIcon from "../_UI/cryptoIcon";

import checkP from '../../theme/assets/svg/oli_checkbox_passive.svg';
import checkA from '../../theme/assets/svg/oli_checkbox_active.svg';
import CabinetCustomInput from "../_UI/cabinetCustomInput";
import {valueFixer} from "../../helpers/valueFixer";
import {setTopUpModalStatus, topup} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";



const CabinetTopUp = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const availableCurrencies = useSelector(store => store.cabinet.availableCurrencies);
    const wallets = useSelector(store => store.cabinet.wallets);
    const topupStatus = useSelector(store => store.cabinet.topupStatus);


    const [currentCurrency, setCurrentCurrency] = useState(false);
    const [availableBalance, setAvailableBalance] = useState(0);
    const [amount, setAmount] = useState('');

    const sendСonditionHandler = () =>{
        if (currentCurrency){
            return valueFixer(currentCurrency?.min_top_up_amount, 4) <= Number(amount) && valueFixer(currentCurrency?.max_top_up_amount, 4) >= Number(amount)
        }
    }

    const topUpHandler = () =>{
        if (sendСonditionHandler()){
            dispatch(topup(currentCurrency.id, {amount: amount}))
        }
    }

    useEffect(()=>{
        if (wallets && currentCurrency){
            wallets.forEach((item)=>{
                if (item?.currency?.id === currentCurrency.id){
                    setAvailableBalance(valueFixer(item.amount, 2))
                }
            })
        }
    },[currentCurrency, wallets])
    
    useEffect(()=>{
        if (topupStatus){
            dispatch(setTopUpModalStatus(true));
            setCurrentCurrency(false);
            setAvailableBalance(0);
            setAmount('');
            window.location.assign(`${topupStatus.url}`)
            // window.open(`${topupStatus.url}`, "_blank");
        }
    },[dispatch, topupStatus])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.cabinetLargeCardMS}`}>
                <div className={`${Styles.titleRow}`}>
                    <div className={`${Styles.cabinetTitle1}`}>{t('Пополнение')}</div>
                    <div className={`${Styles.titleNote}`}>
                        {t('Для активации инвестиционного пакета Вам необходимо Пополнить свой баланс и активируйте пакет в разделе «Открыть депозит»')}
                    </div>
                </div>
                <div className={`${Styles.currencyList}`}>
                    <div className={`${Styles.cabinetTitle2}`}>1. {t('Выберите валюту')}</div>
                    <div className={`${Styles.contentBox}`}>
                        {availableCurrencies ? availableCurrencies.map((item, index)=>{
                            return (
                                <div
                                    className={`${Styles.contentBoxItem} ${currentCurrency?.id === item.id ? Styles.contentBoxItemActive : null}`}
                                    key={'availableCurrenciesTU' + index}
                                    onClick={()=>{setCurrentCurrency(item)}}
                                >
                                    <div className={`${Styles.currencyWrap}`}>
                                        <div className={`${Styles.currencyImgWrap}`}>
                                            <CryptoIcon name={item.name}/>
                                        </div>
                                        <div className={`${Styles.currencyBox}`}>
                                            <div className={`${Styles.currencyBoxShortName}`}>{item.code}</div>
                                            <div className={`${Styles.currencyBoxFullName}`}>{item.name}</div>
                                        </div>
                                    </div>
                                    <div className={`${Styles.checkWrap}`}>
                                        {currentCurrency?.id === item.id ? <img src={checkA} alt="checkbox on"/> : <img src={checkP} alt="checkbox off"/>}
                                    </div>
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
                <div className={`${Styles.amountRow}`}>
                    <div className={`${Styles.cabinetTitle2}`}>2. {t('Сумма')}</div>
                    <CabinetCustomInput
                        gender='input'
                        type='number'
                        placeholder={currentCurrency ? `Min: ${valueFixer(currentCurrency?.min_top_up_amount, 4)} ${currentCurrency?.code}` : t('Выберите валюту')}
                        value={amount}
                        setValue={setAmount}
                    />
                    {currentCurrency ?
                        <div className={`${Styles.amountNote}`}>
                            <div className={`${Styles.amountNoteItem}`}>{t('Доступно')}: <span>{availableBalance} {currentCurrency?.code}</span></div>
                            <div className={`${Styles.amountNoteItem}`}>{`Min/Max: ${valueFixer(currentCurrency?.min_top_up_amount, 4)} - ${valueFixer(currentCurrency?.max_top_up_amount, 4)} ${currentCurrency?.code}`}</div>
                        </div> :
                        <div className={`${Styles.amountNote}`}>
                            <div className={`${Styles.amountNoteItem}`}>{t('Выберите валюту')}</div>
                        </div>
                    }

                </div>
                <div className={` ${sendСonditionHandler() ? Styles.cabinetBtn : Styles.cabinetBtnDisable}`} onClick={()=>{topUpHandler()}}>{t('Пополнить')}</div>
            </div>
        </section>
    );
};

export default CabinetTopUp;