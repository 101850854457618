import React from "react";
import {linkTranslate} from "../../../helpers/linkTranslite";
import Moment from "react-moment";
import DOMPurify from "dompurify";
import Styles from './styles.module.scss';
import {useNavigate} from "react-router-dom";




const AboutSliderItem = (props) => {
    const {id, title, description, img, date} = props;

    const navigate = useNavigate();

    const postLinkHandler = (title, id) => {
        const correctUrl = linkTranslate(title, id);
        navigate(`/news/${correctUrl}`);
    };

    return (
        <div className={`${Styles.sliderItem}`}>
            <div className={`${Styles.sliderItemImgWrap}`}>
                <img src={img} alt={title}/>
            </div>
            <div className={`${Styles.sliderItemContentBox}`}>
                <div className={`${Styles.sliderItemTitle}`}>{title}</div>
                <div className={`${Styles.sliderItemDate}`}><Moment format="DD/MM/YYYY">{date}</Moment></div>
                <div
                    className={`${Styles.sliderItemDescription}`}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(description),
                    }}
                />
                <div className={`${Styles.sliderItemBtn}`} onClick={()=>{postLinkHandler(title, id)}}>READ MORE</div>
            </div>
        </div>
    );
};

export default AboutSliderItem;