import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import Seo from "../../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import ServicesContent from "../../../components/_UI/servicesContent";
import img1 from "../../../theme/assets/other/ssx_services_tf_img1.png";
import img2 from "../../../theme/assets/other/ssx_services_tf_img2.png";


const TransactionFeesPage = () => {
    const { t } = useTranslation();
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Комиссии за транзакции')}/>
            <ServicesContent
                mainTitle={t('Комиссии за транзакции')}
                helloParagraph={t('Криптобанки могут зарабатывать деньги, взимая комиссии за различные типы транзакций, такие как переводы между счетами, снятие средств, обмен криптовалют и другие транзакции. Эти комиссии могут быть структурированы по-разному:')}
                helloList={[
                    {hlTitle: t('Фиксированные комиссии:'), hlContent: t('Фиксированная сумма, взимаемая за каждую транзакцию, независимо от ее размера. Это обычно применяется для простых действий, таких как вывод или внесение средств.')},
                    {hlTitle: t('Процентные комиссии:'), hlContent: t('Комиссия, рассчитанная как процент от суммы транзакции. Это часто используется для крупных транзакций, таких как обмен между различными криптовалютами или конвертации фиатных валют.')},
                    {hlTitle: t('Динамические комиссии:'), hlContent: t('Комиссии, которые меняются в зависимости от загруженности сети или срочности транзакции. Например, более высокие комиссии могут взиматься за более быстрые подтверждения транзакций на блокчейне.')},
                ]}
                mainImg={img1}
                middleTitle={t('Статистика комиссий за транзакции')}
                middleParagraph={t('Мы уверены в стабильности нашей системы, которая была разработана для соответствия высоким стандартам безопасности и эффективности. Наши пользователи могут рассчитывать на прозрачные и справедливые комиссии за транзакции, адаптированные к различным потребностям и условиям. Непрерывный мониторинг и оптимизация позволяют нам поддерживать высокий уровень обслуживания и удовлетворенности клиентов.')}
                middleArr={[
                    {title: '1.000.000', text: t('Транзакций в месяц')},
                    {title: '$75,000', text: t('Общий доход от комиссий за транзакции')},
                    {title: '$2.5', text: t('Средняя комиссия за транзакцию')},
                    {title: '20%', text: t('Рост объема транзакций')}
                ]}
                endImg={img2}
                endTitle={t('Выгодные комиссии за транзакции')}
                endParagraph={t('Мы стремимся предложить нашим пользователям самые выгодные условия комиссий за транзакции по нескольким причинам. В заключение, мы создаем условия, которые делают наши комиссии за транзакции выгодными для наших пользователей, способствуя их успешному развитию и росту.')}
                endArr={[
                    {title: t('Прозрачность и справедливость'), text: t('Мы устанавливаем прозрачные и справедливые тарифы, основанные на реальных затратах на обработку транзакций. Наша цель - предоставить честные и равноправные условия для всех пользователей.')},
                    {title: t('Конкурентоспособность'), text: t('Мы постоянно анализируем рынок и стремимся предложить самые конкурентоспособные тарифы. Это помогает нашим пользователям экономить на комиссиях и увеличивать свою прибыльность.')},
                    {title: t('Инновации и оптимизация'), text: t('Мы постоянно работаем над улучшением наших технологий и оптимизацией процессов для снижения затрат на обработку транзакций. Это позволяет нам предлагать более выгодные условия для наших пользователей.')},
                    {title: t('Поддержка пользователей'), text: t('Мы ценим наших пользователей и стремимся поддерживать их в их деловых начинаниях. Предлагая выгодные комиссии за транзакции, мы помогаем нашим пользователям развивать их бизнес и достигать успеха.')},
                ]}
            />
        </section>
    );
};

export default TransactionFeesPage;