import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Element, scroller} from 'react-scroll';
import {getNews, setLinkScrollStatus} from "../../store/main/action";
import Styles from './styles.module.scss'
import MainHello from "../../components/mainHello";
import MainAdvantages from "../../components/mainAdvantages";
import MainAchievements from "../../components/mainAchievements";
import MainReview from "../../components/mainReview";
import MainAnswer from "../../components/mainAnswer";
import AboutNews from "../../components/aboutNews";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const MainPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const linkScrollStatus = useSelector(store => store.main.linkScrollStatus);

    const scrollHandler = (element) =>{
        scroller.scrollTo(element, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    useEffect(()=>{
        if (linkScrollStatus){
            scrollHandler(linkScrollStatus);
            dispatch(setLinkScrollStatus(false));
        }
    },[dispatch, linkScrollStatus])

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    useEffect(()=>{
        dispatch(getNews(8));
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={`${t('Ваш мост')} ${t('в мир ĸриптовалют')}`}/>
            <MainHello/>
            <Element name="advantages">
                <MainAdvantages/>
            </Element>
            <MainAchievements/>
            <MainReview/>
            <AboutNews/>
            <Element name="faq">
                <MainAnswer/>
            </Element>
        </section>
    );
};

export default MainPage;