import React from "react";
import Styles from './styles.module.scss';
import {useTranslation} from "react-i18next";



const CabinetStatus = (props) => {
    const {status} = props;

    const { t } = useTranslation();

    const cabinetIconHandler = () =>{
        switch (status){
            case 'created':
                return (
                    <div className={`${Styles.cabinetStatusBox} ${Styles.cabinetStatusBoxCreated}`}>
                        <div className={`${Styles.cabinetStatusSent}`}>
                            {t('Создан')}
                        </div>
                    </div>
                )
            case 'unconfirmed':
                return (
                    <div className={`${Styles.cabinetStatusBox} ${Styles.cabinetStatusBoxUnconfirmed}`}>
                        <div className={`${Styles.cabinetStatusSent}`}>
                            {t('Не подтвержден')}
                        </div>
                    </div>
                )
            case 'confirmed':
                return (
                    <div className={`${Styles.cabinetStatusBox} ${Styles.cabinetStatusBoxConfirmed}`}>
                        <div className={`${Styles.cabinetStatusSent}`}>
                            {t('Подтвержден')}
                        </div>
                    </div>
                )
            case 'out_of_energy':
                return (
                    <div className={`${Styles.cabinetStatusBox} ${Styles.cabinetStatusBoxOutOfEnergy}`}>
                        <div className={`${Styles.cabinetStatusSent}`}>
                            {t('out_of_energy')}
                        </div>
                    </div>
                )
            case 'unknown':
                return (
                    <div className={`${Styles.cabinetStatusBox} ${Styles.cabinetStatusBoxUnknown}`}>
                        <div className={`${Styles.cabinetStatusSent}`}>
                            {t('Неизвестно')}
                        </div>
                    </div>
                )
            case 'canceled':
                return (
                    <div className={`${Styles.cabinetStatusBox} ${Styles.cabinetStatusBoxCanceled}`}>
                        <div className={`${Styles.cabinetStatusCanceled}`}>
                            {t('Отменен')}
                        </div>
                    </div>
                )
            default:
                return (
                    <div className={`${Styles.cabinetStatusBox} ${Styles.cabinetStatusBoxUnknown}`}>
                        <div className={`${Styles.cabinetStatusSent}`}>
                            {t('Неизвестно')}
                        </div>
                    </div>
                )
        }
    }

    return (
        <div
            className={`${Styles.cabinetStatusWrap}`}
        >
            {cabinetIconHandler()}
        </div>
    );
};

export default CabinetStatus;