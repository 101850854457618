export const TEST = 'TEST';
export const SET_DEPOSIT_MODAL_STATUS = 'SET_DEPOSIT_MODAL_STATUS';
export const SET_WITHDRAW_COMMISSION_MODAL_STATUS = 'SET_WITHDRAW_COMMISSION_MODAL_STATUS';
export const SET_NAVIGATION_STATUS = 'SET_NAVIGATION_STATUS';
export const SET_PARTNERS_MODAL_STATUS = 'SET_PARTNERS_MODAL_STATUS';
export const SET_DEPOSIT_REQUEST_MODAL_STATUS = 'SET_DEPOSIT_REQUEST_MODAL_STATUS';
export const SET_TOPUP_MODAL_STATUS = 'SET_TOPUP_MODAL_STATUS';
export const SET_WITHDRAW_MODAL_STATUS = 'SET_WITHDRAW_MODAL_STATUS';
export const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE';
export const SEND_API_CHAT_MESSAGE = 'SEND_API_CHAT_MESSAGE';
export const GET_WALLETS_STARTED = 'GET_WALLETS_STARTED';
export const GET_WALLETS_SUCCESS = 'GET_WALLETS_SUCCESS';
export const GET_WALLETS_FAILURE = 'GET_WALLETS_FAILURE';

export const GET_PARTNERS_STARTED = 'GET_PARTNERS_STARTED';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_FAILURE = 'GET_PARTNERS_FAILURE';

export const GET_PARTNERS_DASHBOARD_STARTED = 'GET_PARTNERS_DASHBOARD_STARTED';
export const GET_PARTNERS_DASHBOARD_SUCCESS = 'GET_PARTNERS_DASHBOARD_SUCCESS';
export const GET_PARTNERS_DASHBOARD_FAILURE = 'GET_PARTNERS_DASHBOARD_FAILURE';

export const GET_DEPOSIT_STARTED = 'GET_DEPOSIT_STARTED';
export const GET_DEPOSIT_SUCCESS = 'GET_DEPOSIT_SUCCESS';
export const GET_DEPOSIT_FAILURE = 'GET_DEPOSIT_FAILURE';

export const OPEN_DEPOSIT_STARTED = 'OPEN_DEPOSIT_STARTED';
export const OPEN_DEPOSIT_SUCCESS = 'OPEN_DEPOSIT_SUCCESS';
export const OPEN_DEPOSIT_FAILURE = 'OPEN_DEPOSIT_FAILURE';

export const GET_USER_DEPOSIT_STARTED = 'GET_USER_DEPOSIT_STARTED';
export const GET_USER_DEPOSIT_SUCCESS = 'GET_USER_DEPOSIT_SUCCESS';
export const GET_USER_DEPOSIT_FAILURE = 'GET_USER_DEPOSIT_FAILURE';

export const GET_TRANSACTIONS_STARTED = 'GET_TRANSACTIONS_STARTED';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILURE = 'GET_TRANSACTIONS_FAILURE';

export const LOGOUT_STARTED = 'LOGOUT_STARTED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const GET_AVAILABLE_CURRENCIES_STARTED = 'GET_AVAILABLE_CURRENCIES_STARTED';
export const GET_AVAILABLE_CURRENCIES_SUCCESS = 'GET_AVAILABLE_CURRENCIES_SUCCESS';
export const GET_AVAILABLE_CURRENCIES_FAILURE = 'GET_AVAILABLE_CURRENCIES_FAILURE';

export const TOPUP_STARTED = 'TOPUP_STARTED';
export const TOPUP_SUCCESS = 'TOPUP_SUCCESS';
export const TOPUP_FAILURE = 'TOPUP_FAILURE';

export const WITHDRAW_STARTED = 'WITHDRAW_STARTED';
export const WITHDRAW_SUCCESS = 'WITHDRAW_SUCCESS';
export const WITHDRAW_FAILURE = 'WITHDRAW_FAILURE';

export const GET_MAIN_STATS_STARTED = 'GET_MAIN_STATS_STARTED';
export const GET_MAIN_STATS_SUCCESS = 'GET_MAIN_STATS_SUCCESS';
export const GET_MAIN_STATS_FAILURE = 'GET_MAIN_STATS_FAILURE';

export const GET_REFERRALS_STARTED = 'GET_REFERRALS_STARTED';
export const GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS';
export const GET_REFERRALS_FAILURE = 'GET_REFERRALS_FAILURE';

export const GET_PARTNERS_ACCRUALS_STARTED = 'GET_PARTNERS_ACCRUALS_STARTED';
export const GET_PARTNERS_ACCRUALS_SUCCESS = 'GET_PARTNERS_ACCRUALS_SUCCESS';
export const GET_PARTNERS_ACCRUALS_FAILURE = 'GET_PARTNERS_ACCRUALS_FAILURE';

export const SEND_HELP_FORM_STARTED = 'SEND_HELP_FORM_STARTED';
export const SEND_HELP_FORM_SUCCESS = 'SEND_HELP_FORM_SUCCESS';
export const SEND_HELP_FORM_FAILURE = 'SEND_HELP_FORM_FAILURE';

export const CHANGE_PASSWORD_STARTED = 'CHANGE_PASSWORD_STARTED';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const GET_USER_DATA_STARTED = 'GET_USER_DATA_STARTED';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

export const GET_INVEST_DETAILS_STARTED = 'GET_INVEST_DETAILS_STARTED';
export const GET_INVEST_DETAILS_SUCCESS = 'GET_INVEST_DETAILS_SUCCESS';
export const GET_INVEST_DETAILS_FAILURE = 'GET_INVEST_DETAILS_FAILURE';

export const GET_INVEST_DETAILS_OPERATIONS_STARTED = 'GET_INVEST_DETAILS_OPERATIONS_STARTED';
export const GET_INVEST_DETAILS_OPERATIONS_SUCCESS = 'GET_INVEST_DETAILS_OPERATIONS_SUCCESS';
export const GET_INVEST_DETAILS_OPERATIONS_FAILURE = 'GET_INVEST_DETAILS_OPERATIONS_FAILURE';

export const GET_INVEST_DETAILS_TRANSACTIONS_STARTED = 'GET_INVEST_DETAILS_TRANSACTIONS_STARTED';
export const GET_INVEST_DETAILS_TRANSACTIONS_SUCCESS = 'GET_INVEST_DETAILS_TRANSACTIONS_SUCCESS';
export const GET_INVEST_DETAILS_TRANSACTIONS_FAILURE = 'GET_INVEST_DETAILS_TRANSACTIONS_FAILURE';

export const SET_INVEST_DETAILS_REINVEST_STARTED = 'SET_INVEST_DETAILS_REINVEST_STARTED';
export const SET_INVEST_DETAILS_REINVEST_SUCCESS = 'SET_INVEST_DETAILS_REINVEST_SUCCESS';
export const SET_INVEST_DETAILS_REINVEST_FAILURE = 'SET_INVEST_DETAILS_REINVEST_FAILURE';

export const INVEST_DEPOSIT_STARTED = 'INVEST_DEPOSIT_STARTED';
export const INVEST_DEPOSIT_SUCCESS = 'INVEST_DEPOSIT_SUCCESS';
export const INVEST_DEPOSIT_FAILURE = 'INVEST_DEPOSIT_FAILURE';