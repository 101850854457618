import React from "react";
import Styles from './styles.module.scss'
import RegForm from "../../components/regForm";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const RegPage = () => {
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Регистрация')}/>
            <RegForm/>
        </section>
    );
};

export default RegPage;