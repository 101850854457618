import { combineReducers } from 'redux';
import {mainReducer} from "./main/reducer";
import {cabinetReducer} from "./cabinet/reducer";
import {chatReducer} from "./chat/reducer";



export const rootReducer = combineReducers({
    main: mainReducer,
    cabinet: cabinetReducer,
    chat: chatReducer
})
