import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import Seo from "../../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import ServicesContent from "../../../components/_UI/servicesContent";
import img1 from "../../../theme/assets/other/ssx_services_sm_img1.png";
import img2 from "../../../theme/assets/other/ssx_services_sm_img2.png";


const StakingAndMiningPage = () => {
    const { t } = useTranslation();
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Стейкинг и майнинг')}/>
            <ServicesContent
                mainTitle={t('Стейкинг и майнинг')}
                helloParagraph={t('Стейкинг и майнинг являются важными механизмами в криптовалютной экосистеме, обеспечивая безопасность сети и проверку транзакций. Эти процессы не только поддерживают функциональность и целостность блокчейн-сетей, но и предоставляют участникам возможности для получения вознаграждений. За счет стейкинга своих активов или участия в вычислительной мощности пользователи могут активно участвовать в обслуживании и работе децентрализованных сетей.')}
                helloList={false}
                mainImg={img1}
                middleTitle={t('Ключевые метрики стейкинга и майнинга')}
                middleParagraph={t('Эти метрики предоставляют ценную информацию о эффективности и привлекательности стейкинга и майнинга. Понимание их помогает пользователям и инвесторам принимать обоснованные решения относительно их участия в этих критически важных процессах, обеспечивая эффективный вклад в здоровье и устойчивость криптовалютной экосистемы.')}
                middleArr={[
                    {title: t('7% в год'), text: t('Средняя годовая доходность от стейкинга')},
                    {title: '150 EH/s', text: t('Сетевая хэш-скорость')},
                    {title: '6.25 BTC', text: t('Среднее вознаграждение за блок')},
                    {title: '60%', text: t('Уровень участия в стейкинге')}
                ]}
                endImg={img2}
                endTitle={t('Показатели эффективности стейкинга и майнинга')}
                endParagraph={t('Стейкинг и майнинг являются фундаментальными элементами криптовалютной экосистемы, улучшая безопасность сети и проверку транзакций. Эти действия важны для поддержания целостности блокчейна и предоставления вознаграждений участникам.')}
                endArr={[
                    {title: t('25 миллиардов долларов'), subTitle: t('Общая заблокированная стоимость'), text: t('Представляет собой общую стоимость всех криптовалют, в настоящее время заблокированных на различных сетях с подтверждением доли (PoS), что указывает на уровень вовлеченности и доверия со стороны сообщества.')},
                    {title: t('100 тераватт-часов в год'), subTitle: t('Потребление энергии'), text: t('Измеряет общее количество энергии, потребляемой майнинговыми операциями в сетях с подтверждением работы (PoW). Этот показатель важен для оценки воздействия на окружающую среду и устойчивости майнинговых операций.')},
                    {title: t('10,000 валидаторов'), subTitle: t('Количество валидаторов'), text: t('Указывает на количество активных валидаторов, участвующих в сети PoS. Большее количество валидаторов повышает децентрализацию и безопасность сети.')},
                    {title: t('20 терагешей'), subTitle: t('Сложность майнинга'), text: t('Отражает уровень сложности майнинга новых блоков в сети PoW. По мере того как к сети присоединяются новые майнеры, сложность увеличивается, обеспечивая стабильную и предсказуемую скорость создания блоков.')},
                ]}
            />
        </section>
    );
};

export default StakingAndMiningPage;