import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';
import {useDispatch, useSelector} from "react-redux";
import CryptoIcon from "../_UI/cryptoIcon";

import checkP from '../../theme/assets/svg/oli_checkbox_passive.svg';
import checkA from '../../theme/assets/svg/oli_checkbox_active.svg';
import CabinetCustomInput from "../_UI/cabinetCustomInput";
// import {useNavigate} from "react-router-dom";
import {valueFixer} from "../../helpers/valueFixer";
import {
    setWithdrawCommissionModalStatus,
    setWithdrawModalStatus,
    withdrawFailure
} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";



const CabinetWithdrawal = () => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const availableCurrencies = useSelector(store => store.cabinet.availableCurrencies);
    const wallets = useSelector(store => store.cabinet.wallets);
    const withdrawStatus = useSelector(store => store.cabinet.withdrawStatus);
    const withdrawStatusErr = useSelector(store => store.cabinet.withdrawStatusErr);

    const [currentCurrency, setCurrentCurrency] = useState(false);
    const [currentWallet, setCurrentWallet] = useState(false);
    const [availableBalance, setAvailableBalance] = useState(0);
    const [amount, setAmount] = useState('');
    const [address, setAddress] = useState('');

    const sendСonditionHandler = () =>{
        if (currentCurrency){
            return valueFixer(currentCurrency?.min_withdraw_amount, 4) <= Number(amount) && valueFixer(currentCurrency?.max_withdraw_amount, 4) >= Number(amount) && Number(amount) <= Number(availableBalance) && address.length > 5
        }
    }

    const withdrawHandler = () =>{
        if (sendСonditionHandler()){
            dispatch(setWithdrawCommissionModalStatus({status: true, data: {id: currentWallet.id, amount: amount, address_for_withdraw: address, commission: currentCurrency?.commission, currencyCode: currentCurrency?.code}}))
            // dispatch(withdraw(currentWallet.id, {amount: amount, address_for_withdraw: address}))
        }
    }

    useEffect(()=>{
        if (wallets && currentCurrency){
            wallets.forEach((item)=>{
                if (item?.currency?.id === currentCurrency.id){
                    setAvailableBalance(valueFixer(item.amount, 2));
                    setCurrentWallet(item);
                    if (item.address_for_withdraw){
                        setAddress(item.address_for_withdraw)
                    }
                }
            })
        }
    },[currentCurrency, wallets])

    useEffect(()=>{
        if (withdrawStatus){
            dispatch(setWithdrawModalStatus(true));
            setCurrentCurrency(false);
            setAvailableBalance(0);
            setAmount('');
            setAddress('');
        }
    },[dispatch, withdrawStatus])
    
    useEffect(()=>{
        if (withdrawStatusErr.amount){
            toast(withdrawStatusErr.amount[0])
            dispatch(withdrawFailure(false));
        }
    },[dispatch, withdrawStatusErr])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.cabinetLargeCardMS}`}>
                <div className={`${Styles.titleRow}`}>
                    <div className={`${Styles.cabinetTitle1}`}>{t('Вывод')}</div>
                </div>
                <div className={`${Styles.currencyList}`}>
                    <div className={`${Styles.currencyListTitleRow}`}>
                        <div className={`${Styles.cabinetTitle2}`}>1. {t('Выберите валюту')}</div>
                        <div className={`${Styles.cabinetTitle2}`}>{currentCurrency?.commission ? `${t('Комиссия')}: ${currentCurrency?.commission * 1} ${currentCurrency?.code}` : null}</div>
                    </div>

                    <div className={`${Styles.contentBox}`}>
                        {availableCurrencies ? availableCurrencies.map((item, index)=>{
                            return (
                                <div
                                    className={`${Styles.contentBoxItem} ${currentCurrency?.id === item.id ? Styles.contentBoxItemActive : null}`}
                                    key={'availableCurrenciesWW' + index}
                                    onClick={()=>{setCurrentCurrency(item)}}
                                >
                                    <div className={`${Styles.currencyWrap}`}>
                                        <div className={`${Styles.currencyImgWrap}`}>
                                            <CryptoIcon name={item.name}/>
                                        </div>
                                        <div className={`${Styles.currencyBox}`}>
                                            <div className={`${Styles.currencyBoxShortName}`}>{item.code}</div>
                                            <div className={`${Styles.currencyBoxFullName}`}>{item.name}</div>
                                        </div>
                                    </div>
                                    <div className={`${Styles.checkWrap}`}>
                                        {currentCurrency?.id === item.id ? <img src={checkA} alt="checkbox on"/> : <img src={checkP} alt="checkbox off"/>}
                                    </div>
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
                <div className={`${Styles.amountRow}`}>
                    <div className={`${Styles.cabinetTitle2}`}>2. {t('Сумма')}:</div>
                    <CabinetCustomInput
                        gender='input'
                        type='number'
                        placeholder={currentCurrency ? `Min: ${valueFixer(currentCurrency?.min_withdraw_amount, 4)} ${currentCurrency?.code}` : t('Выберите валюту')}
                        value={amount}
                        setValue={setAmount}
                    />
                    {currentCurrency ?
                        <div className={`${Styles.amountNote}`}>
                            <div className={`${Styles.amountNoteItem}`}>{t('Доступно')}: <span>{availableBalance} {currentCurrency?.code}</span></div>
                            <div className={`${Styles.amountNoteItem}`}>{`Min/Max: ${valueFixer(currentCurrency?.min_withdraw_amount, 4)} - ${valueFixer(currentCurrency?.max_withdraw_amount, 4)} ${currentCurrency?.code}`}</div>
                        </div> :
                        <div className={`${Styles.amountNote}`}>
                            <div className={`${Styles.amountNoteItem}`}>{t('Выберите валюту')}</div>
                        </div>
                    }
                </div>
                <div className={`${Styles.walletRow}`}>
                    <div className={`${Styles.cabinetTitle2}`}>3. {t('Адрес вывода')}:</div>
                    <div className={`${Styles.posWrap}`}>
                        <CabinetCustomInput
                            gender='input'
                            type='text'
                            placeholder={t('Адрес вывода')}
                            value={address}
                            setValue={setAddress}
                        />
                        <div className={`${sendСonditionHandler() ? Styles.cabinetBtn : Styles.cabinetBtnDisable}`} onClick={()=>{withdrawHandler()}}>{t('Вывод')}</div>
                    </div>
                </div>
                {/*<div className={`${Styles.noteRow}`}>*/}
                {/*    Check if you have a wallet in the settings and make sure it is connected for withdrawals.*/}
                {/*    If you don't have a wallet, you should go to the <span onClick={()=>{navigate('/cabinet/settings')}}>settings</span> section and perform*/}
                {/*    the process of connecting a wallet. Otherwise, you may not be able to withdraw funds.*/}
                {/*</div>*/}
            </div>
        </section>
    );
};

export default CabinetWithdrawal;