import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import Seo from "../../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import ServicesContent from "../../../components/_UI/servicesContent";
import img1 from "../../../theme/assets/other/ssx_services_am_img1.png";
import img2 from "../../../theme/assets/other/ssx_services_am_img2.png";


const AssetManagementPage = () => {
    const { t } = useTranslation();
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Управление активами')}/>
            <ServicesContent
                mainTitle={t('Управление активами')}
                helloParagraph={t('Этот процесс включает в себя тщательный анализ и тщательный отбор инвестиционных возможностей, балансируя риск и вознаграждение для достижения как краткосрочных, так и долгосрочных финансовых целей. Кроме того, эффективное управление активами обеспечивает гибкость для адаптации к изменяющимся экономическим условиям, тем самым защищая и увеличивая богатство со временем. В целом, это критически важный компонент для лиц и учреждений, стремящихся обеспечить свое финансовое будущее и добиться устойчивого роста.')}
                helloList={false}
                mainImg={img1}
                middleTitle={t('Метрики для финансового планирования')}
                middleParagraph={t('Эффективное управление активами необходимо для максимизации доходов от инвестиций и обеспечения долгосрочной финансовой стабильности. Вот ключевые метрики, которые следует учитывать в контексте управления активами:')}
                middleArr={[
                    {title: t('$2 миллиарда'), text: t('Общая стоимость активов под управлением (AUM)')},
                    {title: t('8% в год'), text: t('Годовая доходность инвестиций (ROI)')},
                    {title: t('90% в год'), text: t('Коэффициент удержания клиентов')},
                    {title: t('0.75% в год'), text: t('Коэффициент расходов')}
                ]}
                endImg={img2}
                endTitle={t('Ключевые показатели эффективности в управлении активами')}
                endParagraph={t('Управление активами включает в себя стратегический подход к управлению инвестициями для максимизации доходности и достижения финансовых целей. Вот дополнительные ключевые показатели эффективности (KPI), которые подчеркивают эффективность управления активами.')}
                endArr={[
                    {title: t('Соотношение: 1.5'), subTitle: t('Коэффициент Шарпа'), text: t('Измеряет скорректированную на риск доходность инвестиционного портфеля, указывая, сколько избыточной доходности получено за дополнительную волатильность.')},
                    {title: t('Значение: 0.85'), subTitle: t('Коэффициент Бета'), text: t('Указывает чувствительность доходности портфеля к рыночным движениям, при значениях меньше 1, что свидетельствует о меньшей волатильности по сравнению с рынком.')},
                    {title: t('Значение: 2.0%'), subTitle: t('Альфа'), text: t('Представляет собой активную доходность инвестиций, измеряя производительность портфеля относительно эталонного индекса')},
                    {title: t('25% в год'), subTitle: t('Коэффициент оборота'), text: t('Отражает процент активов портфеля, которые были заменены в течение года, указывая на уровень торговой активности.')}
                ]}
            />
        </section>
    );
};

export default AssetManagementPage;