import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch} from "react-redux";
import {clearAllNews, getAllNews} from "../../store/main/action";
import NewsAll from "../../components/newsAll";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const NewsPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        return(()=>{dispatch(clearAllNews())})
    },[dispatch])
    
    useEffect(()=>{
        dispatch(getAllNews(4, currentPage));

    },[currentPage, dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Наш Блог')}/>
            <NewsAll currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </section>
    );
};

export default NewsPage;