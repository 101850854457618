import React from "react";
import Styles from './styles.module.scss';

import {useTranslation} from "react-i18next";

import team1 from '../../theme/assets/other/ssx_team_icon1.png';
import team2 from '../../theme/assets/other/ssx_team_icon2.png';
import team3 from '../../theme/assets/other/ssx_team_icon3.png';
import team4 from '../../theme/assets/other/ssx_team_icon4.png';



const AboutTeam = () => {
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} ${Styles.pageVerticalPadding}`}>
                <div className={`${Styles.title2}`}>{t('Наша команда')}</div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemImgWrap}`}>
                            <img src={team1} alt="Jon Doe"/>
                        </div>
                        <div className={`${Styles.contentBoxItemName}`}>Henry Johnson</div>
                        <div className={`${Styles.contentBoxItemPosition}`}>Founder</div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemImgWrap}`}>
                            <img src={team2} alt="Anita Mola"/>
                        </div>
                        <div className={`${Styles.contentBoxItemName}`}>Michael Bennett</div>
                        <div className={`${Styles.contentBoxItemPosition}`}>CTO</div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemImgWrap}`}>
                            <img src={team3} alt="Henry Montes"/>
                        </div>
                        <div className={`${Styles.contentBoxItemName}`}>David Carter</div>
                        <div className={`${Styles.contentBoxItemPosition}`}>COO</div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemImgWrap}`}>
                            <img src={team4} alt="Arif Akmal"/>
                        </div>
                        <div className={`${Styles.contentBoxItemName}`}>Robert Harrison</div>
                        <div className={`${Styles.contentBoxItemPosition}`}>CFO</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutTeam;