import React from "react";
import Styles from './styles.module.scss'
import Logo from "../_UI/logo";
import CabinetBalance from "../cabinetBalance";
import CabinetNavigationLinks from "../cabinetNavigationLinks";
import CabinetLogoutLinks from "../cabinetLogoutLinks";
import LangSwitcher from "../langSwitcher";
import {useDispatch, useSelector} from "react-redux";
import {setNavigationStatus} from "../../store/cabinet/action";



const CabinetNavigation = () => {
    const dispatch = useDispatch();

    const navigationStatus = useSelector(store => store.cabinet.navigationStatus);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <div className={`${Styles.navWrap}`}>
                    <Logo isCabinet={true}/>
                    <div
                        className={`${Styles.navBtn} ${navigationStatus ? Styles.navBtnOpen : null}`}
                        onClick={()=>{dispatch(setNavigationStatus(!navigationStatus))}}
                    >
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
                <div className={`${Styles.positionWrap} ${navigationStatus ? Styles.positionWrapOpen : null}`}>
                    <CabinetBalance/>
                    <CabinetNavigationLinks/>
                    <CabinetLogoutLinks/>
                    <LangSwitcher isCabinet={true}/>
                </div>
            </div>
        </section>
    );
};

export default CabinetNavigation;