import axios from '../../helpers/interceptors';

import {
    TEST,
    SET_LINK_SCROLL_STATUS,
    REGISTRATION_STARTED,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAILURE,
    LOGIN_STARTED,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SET_AUTHORIZATION_STATUS,
    GET_ABOUT_DATA_STARTED,
    GET_ABOUT_DATA_SUCCESS,
    GET_ABOUT_DATA_FAILURE,
    SEND_CONTACT_FORM_STARTED,
    SEND_CONTACT_FORM_SUCCESS,
    SEND_CONTACT_FORM_FAILURE,
    RESET_PASSWORD_STARTED,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    FORGOT_PASSWORD_STARTED,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    GET_NEWS_STARTED,
    GET_NEWS_SUCCESS,
    GET_NEWS_FAILURE,
    GET_ALL_NEWS_STARTED,
    GET_ALL_NEWS_SUCCESS,
    GET_ALL_NEWS_FAILURE,
    SET_ALL_NEWS_LAST_PAGE,
    GET_POST_STARTED,
    GET_POST_SUCCESS,
    GET_POST_FAILURE,
    FIRST_NEWS_CALL_STATUS,
    CLEAR_ALL_NEWS
} from "./types";

import {api} from "../../api";
// // axios.defaults.withCredentials = true;
const host = api.host;


export const test = data => ({
    type: TEST,
    payload: data
});

export const setLinkScrollStatus = status => ({
    type: SET_LINK_SCROLL_STATUS,
    payload: status
});

export const clearAllNews = status => ({
    type: CLEAR_ALL_NEWS,
    payload: status
});

export const firstNewsCallStatus = status => ({
    type: FIRST_NEWS_CALL_STATUS,
    payload: status
});

export const setAuthorizationStatus = status => ({
    type: SET_AUTHORIZATION_STATUS,
    payload: status
});

export const registration = (formData) => {
    let url = host + api.registration;


    return dispatch => {
        dispatch(registrationStarted());
        axios.post(url, formData)
            .then(res => {
                localStorage.setItem('oli-token', res.data.response.token);
                dispatch(registrationSuccess(res.data.response));
                dispatch(setAuthorizationStatus({authorization: true}));
            })
            .catch(err => {
                dispatch(registrationFailure(err.response.data.errors));
            });
    };
};

const registrationStarted = () => ({
    type: REGISTRATION_STARTED
});

export const registrationSuccess = serverReport => ({
    type: REGISTRATION_SUCCESS,
    payload: serverReport
});

export const registrationFailure = error => ({
    type: REGISTRATION_FAILURE,
    payload: error
});


export const login = (formData) => {
    let url = host + api.login;


    return dispatch => {
        dispatch(loginStarted());
        axios.post(url, formData)
            .then(res => {
                localStorage.setItem('oli-token', res.data.response.token);
                dispatch(loginSuccess(res.data.response));
                dispatch(setAuthorizationStatus({authorization: true}));
            })
            .catch(err => {
                dispatch(loginFailure(err.response.data.errors));
            });
    };
};

const loginStarted = () => ({
    type: LOGIN_STARTED
});

export const loginSuccess = serverReport => ({
    type: LOGIN_SUCCESS,
    payload: serverReport
});

export const loginFailure = error => ({
    type: LOGIN_FAILURE,
    payload: error
});


export const getAboutData = () => {
    let url = host + api.about;


    return dispatch => {
        dispatch(getAboutDataStarted());
        axios.get(url)
            .then(res => {
                dispatch(getAboutDataSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getAboutDataFailure(err));
            });
    };
};

const getAboutDataStarted = () => ({
    type: GET_ABOUT_DATA_STARTED
});

export const getAboutDataSuccess = serverReport => ({
    type: GET_ABOUT_DATA_SUCCESS,
    payload: serverReport
});

export const getAboutDataFailure = error => ({
    type: GET_ABOUT_DATA_FAILURE,
    payload: error
});


export const sendContactForm = (data) => {
    let url = host + api.contact;


    return dispatch => {
        dispatch(sendContactFormStarted());
        axios.post(url, data)
            .then(res => {
                dispatch(sendContactFormSuccess(res.data.success));
            })
            .catch(err => {
                dispatch(sendContactFormFailure(err.response.data.errors));
            });
    };
};

const sendContactFormStarted = () => ({
    type: SEND_CONTACT_FORM_STARTED
});

export const sendContactFormSuccess = serverReport => ({
    type: SEND_CONTACT_FORM_SUCCESS,
    payload: serverReport
});

export const sendContactFormFailure = error => ({
    type: SEND_CONTACT_FORM_FAILURE,
    payload: error
});

export const forgotPassword = (data) => {
    let url = host + api.forgotPassword;


    return dispatch => {
        dispatch(forgotPasswordStarted());
        axios.post(url, data)
            .then(res => {
                dispatch(forgotPasswordSuccess(res.data.success));
            })
            .catch(err => {
                dispatch(forgotPasswordFailure(err.response.data.errors));
            });
    };
};

const forgotPasswordStarted = () => ({
    type: FORGOT_PASSWORD_STARTED
});

export const forgotPasswordSuccess = serverReport => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: serverReport
});

export const forgotPasswordFailure = error => ({
    type: FORGOT_PASSWORD_FAILURE,
    payload: error
});


export const resetPassword = (data) => {
    let url = host + api.resetPassword;



    return dispatch => {
        dispatch(resetPasswordStarted());
        axios.post(url, data)
            .then(res => {
                dispatch(resetPasswordSuccess(res.data.success));
            })
            .catch(err => {
                dispatch(resetPasswordFailure(err.response.data.errors));
            });
    };
};

const resetPasswordStarted = () => ({
    type: RESET_PASSWORD_STARTED
});

export const resetPasswordSuccess = serverReport => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: serverReport
});

export const resetPasswordFailure = error => ({
    type: RESET_PASSWORD_FAILURE,
    payload: error
});

export const getNews = (limit) => {
    let url;
    if (limit){
        url = host + api.latestNews  + `?limit=${limit}`
    } else {
        url = host + api.latestNews  + `?limit=${4}`
    }


    return dispatch => {
        dispatch(getNewsStarted());
        axios.get(url)
            .then(res => {
                dispatch(getNewsSuccess(res.data.response.posts));
            })
            .catch(err => {
                dispatch(getNewsFailure(err));
            });
    };
};

const getNewsStarted = () => ({
    type: GET_NEWS_STARTED
});

export const getNewsSuccess = serverReport => ({
    type: GET_NEWS_SUCCESS,
    payload: serverReport
});

export const getNewsFailure = error => ({
    type: GET_NEWS_FAILURE,
    payload: error
});

export const getAllNews = (limit, page) => {
    let url;
    if (page){
        if (limit){
            url = host + api.allNews + `?page=${page}&limit=${limit}`;
        } else {
            url = host + api.allNews + `?page=${page}&limit=${4}`;
        }
    } else {
        if (limit){
            url = host + api.allNews  + `?limit=${limit}`
        } else {
            url = host + api.allNews  + `?limit=${4}`
        }
    }



    return dispatch => {
        dispatch(getAllNewsStarted());
        axios.get(url)
            .then(res => {
                dispatch(getAllNewsSuccess(res.data.response.posts.data));
                dispatch(setAllNewsLastPage(res.data.response.posts.last_page));
            })
            .catch(err => {
                dispatch(getAllNewsFailure(err));
            });
    };
};

const getAllNewsStarted = () => ({
    type: GET_ALL_NEWS_STARTED
});

export const getAllNewsSuccess = serverReport => ({
    type: GET_ALL_NEWS_SUCCESS,
    payload: serverReport
});

export const getAllNewsFailure = error => ({
    type: GET_ALL_NEWS_FAILURE,
    payload: error
});

export const setAllNewsLastPage = error => ({
    type: SET_ALL_NEWS_LAST_PAGE,
    payload: error
});


export const getPost = (id) => {
    let url = host + api.post + `/${id}`;


    return dispatch => {
        dispatch(getPostStarted());
        axios.get(url)
            .then(res => {
                dispatch(getPostSuccess(res.data.response.post));
            })
            .catch(err => {
                dispatch(getPostFailure(err));
            });
    };
};

const getPostStarted = () => ({
    type: GET_POST_STARTED
});

export const getPostSuccess = serverReport => ({
    type: GET_POST_SUCCESS,
    payload: serverReport
});

export const getPostFailure = error => ({
    type: GET_POST_FAILURE,
    payload: error
});






