import React, {useState} from "react";
import Styles from './styles.module.scss';
import i18n from "i18next";
import listArrowIcon from '../../theme/assets/svg/oly_drop_arrow.svg';
import listArrowIconC from '../../theme/assets/svg/oly_drop_arrowC.svg';


const LangSwitcher = (props) => {
    const {isVertical, isAuthorization, isCabinet} = props;
    const [currentLng, setCurrentLng] = useState(i18n.language);
    const [dropDownStatus, setDropDownStatus] = useState(false);

    const allLngs = ['ru', 'en', 'de', 'es', 'zh'];

    const changeLanguage = (lng) => {
        const html = document.getElementsByTagName('html')[0];
        if (lng === 'ru'){
            if (!html.classList.contains('withRuFont')){
                html.classList.add("withRuFont")
            }
        } else {
            if (html.classList.contains('withRuFont')){
                html.classList.remove("withRuFont")
            }
        }
        i18n.changeLanguage(lng);
        setCurrentLng(lng);
    }

    return (
        <section className={`${Styles.container} ${isVertical ? Styles.containerVertical : null} ${isAuthorization ? Styles.containerAuthorization : null} ${isCabinet ? Styles.containerCabinet : null}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.currentLangBox}`} onClick={()=>{setDropDownStatus(!dropDownStatus)}}>
                    <div className={`${Styles.currentLang}`}>{currentLng}</div>
                    <div className={`${Styles.imgWrap} ${dropDownStatus ? Styles.imgWrapActive : null}`}>
                        <img src={isCabinet ? listArrowIconC : listArrowIcon} alt="drop arrow"/>
                    </div>
                </div>
                <div className={`${Styles.allLangWrap} ${dropDownStatus ? Styles.allLangWrapActive : null}`}>
                    <div className={`${Styles.allLangBox}`}>
                        {allLngs.map((item, index)=>{
                            return (
                                <div
                                    className={`${Styles.allLangBoxItem} ${currentLng === item ? Styles.allLangBoxItemActive : null}`}
                                    key={'allLngs' + index}
                                    onClick={()=>{
                                        changeLanguage(item);
                                        setDropDownStatus(false);
                                        localStorage.setItem('oliLng', item)
                                    }}
                                >
                                    {item}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={`${Styles.contentWrapM}`}>
                <div className={`${Styles.contentBoxM}`}>
                    <div className={`${Styles.allLangBoxM}`}>
                        {allLngs.map((item, index)=>{
                            return (
                                <div
                                    className={`${Styles.allLangBoxItemM} ${currentLng === item ? Styles.allLangBoxItemMActive : null}`}
                                    key={'allLngsM' + index}
                                    onClick={()=>{
                                        changeLanguage(item);
                                        setDropDownStatus(false);
                                        localStorage.setItem('oliLng', item)
                                    }}
                                >
                                    {item}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LangSwitcher;