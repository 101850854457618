import {
    GET_CHAT_TOKEN_STARTED,
    GET_CHAT_TOKEN_SUCCESS,
    GET_CHAT_TOKEN_FAILURE,
    SEND_CHAT_MESSAGE_STARTED,
    SEND_CHAT_MESSAGE_SUCCESS,
    SEND_CHAT_MESSAGE_FAILURE
} from "./types";


const initialState = {
    chatTokenStarted: false,
    chatToken: false,
    chatTokenErr: false,
    chatMessageStatusStarted: false,
    chatMessageStatus: false,
    chatMessageStatusErr: false
};

export function chatReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CHAT_TOKEN_STARTED:
            return {
                ...state,
                chatTokenStarted: true
            };
        case GET_CHAT_TOKEN_SUCCESS:
            return {
                ...state,
                chatToken: action.payload,
                chatTokenStarted: false
            };
        case GET_CHAT_TOKEN_FAILURE:
            return {
                ...state,
                chatTokenErr: action.payload,
                chatTokenStarted: false
            };
        case SEND_CHAT_MESSAGE_STARTED:
            return {
                ...state,
                chatMessageStatusStarted: true
            };
        case SEND_CHAT_MESSAGE_SUCCESS:
            return {
                ...state,
                chatMessageStatus: action.payload,
                chatMessageStatusStarted: false
            };
        case SEND_CHAT_MESSAGE_FAILURE:
            return {
                ...state,
                chatMessageStatusErr: action.payload,
                chatMessageStatusStarted: false
            };
        default:
            return state;
    }
}

