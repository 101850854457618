import React from "react";
import {useNavigate} from "react-router-dom";
import Styles from './styles.module.scss';
import logoIcon from '../../../theme/assets/svg/oli_ssx_logo.svg';
import cabinetLogoIcon from '../../../theme/assets/svg/oli_ssx_cabinet_logo.svg';



const Logo = (props) => {
    const {isCabinet} = props;
    const navigate = useNavigate();

    return (
        <div
            className={`${Styles.logoWrap} ${isCabinet ? Styles.logoWrapCabinet : null}`}
            onClick={()=>{navigate('/')}}
        >
            <img src={isCabinet ? cabinetLogoIcon : logoIcon} alt="SSX"/>
        </div>
    );
};

export default Logo;