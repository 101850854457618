import React from "react";
import Styles from './styles.module.scss';

import bnbIcon from '../../../theme/assets/crypto/oli_bnb_icon.svg';
import btcIcon from '../../../theme/assets/crypto/oli_btc_icon.svg';
import dogeIcon from '../../../theme/assets/crypto/oli_doge_icon.svg';
import ethIcon from '../../../theme/assets/crypto/oli_eth_icon.svg';
import ltcIcon from '../../../theme/assets/crypto/oli_ltc_icon.svg';
import maticIcon from '../../../theme/assets/crypto/oli_matic_icon.svg';
import trxIcon from '../../../theme/assets/crypto/oli_trx_icon.svg';
import twtIcon from '../../../theme/assets/crypto/oli_twt_icon.svg';
import usdcBep20Icon from '../../../theme/assets/crypto/oli_usdc_bep20_icon.svg';
import usdcTrc20Icon from '../../../theme/assets/crypto/oli_usdc_trc20_icon.svg';
import usdtBep20Icon from '../../../theme/assets/crypto/oli_usdt_bep20_icon.svg';
import usdtEthIcon from '../../../theme/assets/crypto/oli_usdt_eth_icon.svg';
import usdtTrc20Icon from '../../../theme/assets/crypto/oli_usdt_trc20_icon.svg';
import usdtErcIcon from '../../../theme/assets/crypto/omt_crypto_icon_erc.svg';
import usdcErc20Icon from '../../../theme/assets/crypto/oli_usdc_erc20_icon.svg';
import xrpIcon from '../../../theme/assets/crypto/oli_xrp_icon.svg';



const CabinetIcon = (props) => {
    const {name} = props;

    const cabinetIconHandler = () =>{
        switch (name){
            case 'Bitcoin':
                return (<img src={btcIcon} alt={name}/>)
            case 'USDC ERC20':
                return (<img src={usdcErc20Icon} alt="erc"/>);
            case 'USDT ERC20':
                return (<img src={usdtErcIcon} alt="erc"/>);
            case 'Ethereum':
                return (<img src={ethIcon} alt={name}/>)
            case 'USDT TRC20':
                return (<img src={usdtTrc20Icon} alt={name}/>)
            case 'USDT_Ethereum':
                return (<img src={usdtEthIcon} alt={name}/>)
            case 'USDT BEP20':
                return (<img src={usdtBep20Icon} alt={name}/>)
            case 'Tron':
                return (<img src={trxIcon} alt={name}/>)
            case 'Binance_Chain':
                return (<img src={bnbIcon} alt={name}/>)
            case 'Ripple':
                return (<img src={xrpIcon} alt={name}/>)
            case 'Litecoin':
                return (<img src={ltcIcon} alt={name}/>)
            case 'Dogecoin':
                return (<img src={dogeIcon} alt={name}/>)
            case 'USDC BEP20':
                return (<img src={usdcBep20Icon} alt={name}/>)
            case 'USDC TRC20':
                return (<img src={usdcTrc20Icon} alt={name}/>)
            case 'Polygon':
                return (<img src={maticIcon} alt={name}/>)
            case 'Trust_Wallet_Token':
                return (<img src={twtIcon} alt={name}/>)
            case 'Dollar':
                return (<img src={usdtBep20Icon} alt={name}/>)
            default:
                return null
        }
    }

    return (
        <div
            className={`${Styles.cryptoIconWrap}`}
        >
            {cabinetIconHandler()}
        </div>
    );
};

export default CabinetIcon;