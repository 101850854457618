import React from "react";
import Styles from './styles.module.scss'
import LogForm from "../../components/logForm";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const LogPage = () => {
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Вход')}/>
            <LogForm/>
        </section>
    );
};

export default LogPage;