import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import ContactForm from "../../components/contactForm";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const ContactPage = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Связаться с нами')}/>
            <ContactForm/>
        </section>
    );
};

export default ContactPage;