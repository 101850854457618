import React from "react";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";
import Moment from "react-moment";
import CabinetIcon from "../_UI/cabinetIcon";
import CabinetStatus from "../_UI/cabinetStatus";
import {useNavigate} from "react-router-dom";
import {valueFixer} from "../../helpers/valueFixer";
import EmptyData from "../_UI/emptyData";
import {useTranslation} from "react-i18next";
import {typeTranslateHandler} from "../../helpers/backTranslate";



const CabinetTransactions = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const transactions = useSelector(store => store.cabinet.transactions);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.cabinetLargeCardWS}`}>
                <div className={`${Styles.titleRow}`}>
                    <div className={`${Styles.cabinetTitle1}`}>{t('Транзакции')}</div>
                    <div className={`${Styles.allBtn}`} onClick={()=>{navigate('/cabinet/transactions')}}>{t('Показать все')}</div>
                </div>
                <div className={`${Styles.tableTitleRow}`}>
                    <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Тип операции')}</div>
                    <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Дата')}</div>
                    <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Статус')}</div>
                    <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Сумма')}</div>
                </div>
                <div className={`${Styles.contentBox} ${transactions?.length > 0 ? Styles.contentBoxWV : null }`}>
                    {transactions?.length > 0 ? transactions.map((item, index)=>{
                        return(
                            <div className={`${Styles.contentBoxItem}`} key={'transactionData' + index}>
                                <div className={`${Styles.contentBoxItemNameWrap}`}>
                                    <CabinetIcon name={item.type}/>
                                    <div className={`${Styles.contentBoxItemName}`}>{typeTranslateHandler(t, item.type)}</div>
                                </div>
                                <div className={`${Styles.contentBoxItemDate}`}><Moment format="DD/MM/YYYY">{item.date}</Moment></div>
                                <div className={`${Styles.contentBoxItemStatusWrap}`}>
                                    <CabinetStatus status={item.status}/>
                                </div>
                                <div className={`${Styles.contentBoxItemAmount}`}>{valueFixer(item?.amount - item.commission, 4)} {item?.currency?.code}</div>
                            </div>
                        )
                    }) : <EmptyData text={t('У вас пока что нет транзакций')}/>}
                </div>
            </div>
        </section>
    );
};

export default CabinetTransactions;