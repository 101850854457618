import React, {useEffect} from "react";
import Styles from './styles.module.scss';
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getPost} from "../../store/main/action";
import PostMain from "../../components/postMain";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const PostPage = () => {
    const dispatch = useDispatch();
    let {title} = useParams();
    const { t } = useTranslation();


    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])
    
    useEffect(()=>{
        dispatch(getPost(title.slice(title.lastIndexOf('n') + 1, title.length)));
    },[dispatch, title])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Наш Блог')}/>
            <PostMain/>
        </section>
    );
};

export default PostPage;