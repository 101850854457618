import React, {useRef, useEffect, useState} from "react";
import Styles from './styles.module.scss';
import CustomBar from "../_UI/customBar";
import CabinetIcon from "../_UI/cabinetIcon";
import {useDispatch, useSelector} from "react-redux";
import {valueFixer} from "../../helpers/valueFixer";
import {useTranslation} from "react-i18next";
import EmptyData from "../_UI/emptyData";
import {setPartnersModalStatus} from "../../store/cabinet/action";



const CabinetPartners = (props) => {
    const {filter, filterAction, scrollAction} = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const scrollRef = useRef(null);

    const partners = useSelector(store => store.cabinet.partners);
    const partnersDashboard = useSelector(store => store.cabinet.partnersDashboard);
    const referralsAll = useSelector(store => store.cabinet.referralsAll);

    const [copyLinkStatus, setCopyLinkStatus] = useState(false);
    const [copyCodeStatus, setCopyCodeStatus] = useState(false);
    const [list, setList] = useState([]);

    const copyLinkHandler = () =>{
        setCopyLinkStatus(true);
        setCopyCodeStatus(false);
        navigator.clipboard.writeText(`${window.location.origin}/sign-up/ref/${partnersDashboard?.referral_code}`);
    }

    const copyCodeHandler = () =>{
        setCopyLinkStatus(false);
        setCopyCodeStatus(true);
        navigator.clipboard.writeText(partnersDashboard?.referral_code);
    }

    useEffect(() => {
        const handleScroll = () => {
            if (scrollRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
                if (scrollTop + clientHeight >= scrollHeight) {
                    scrollAction(true);
                } else {
                    scrollAction(false);
                }
            }
        };

        const currentRef = scrollRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [dispatch, scrollAction]);

    useEffect(()=>{
        setList([...list, ...(referralsAll.data || [])] || [])
    },[referralsAll]) // eslint-disable-line

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.cabinetLargeCard}`}>
                <div className={`${Styles.titleRow}`}>
                    <div className={`${Styles.cabinetTitle1}`}>{t('Партнеры')}</div>
                </div>
                <div className={`${Styles.infoBox}`}>
                    <div className={`${Styles.infoBoxItem}`}>
                        <div className={`${Styles.titleRow}`}>
                            <div className={`${Styles.cabinetTitle2}`}>{t('Уровень')}: <span>{partnersDashboard ? partnersDashboard?.referral_program?.rang : '-'}</span></div>
                            <div className={`${Styles.cabinetTitle2}`}>{t('Бонус')}: <span>{partners ? valueFixer(partnersDashboard?.referral_program?.bonus_amount) : '-'}$</span></div>
                        </div>
                        <div className={`${Styles.formLink}`} onClick={()=>{dispatch(setPartnersModalStatus(true))}}>{t('Узнать больше про уровни и бонусы')}</div>
                        <CustomBar current={partnersDashboard ? valueFixer(partnersDashboard?.personal_turnover) : 0} max={partnersDashboard ? valueFixer(partnersDashboard?.next_referral_program?.personal_turnover) : 0} title={t('Личный объем торгов')}/>
                    </div>
                    <div className={`${Styles.infoBoxItem}`}>
                        <div className={`${Styles.titleRow}`}>
                            <div className={`${Styles.cabinetTitle2}`}>{t('Структура')}:</div>
                        </div>
                        <div className={`${Styles.structureNote}`}>{t('Реф. уровни')}: <span>5%-3%-1%</span></div>
                        <div className={`${Styles.formLink}`}/>
                        <CustomBar current={partnersDashboard ? valueFixer(partnersDashboard?.referral_turnover) : 0} max={partnersDashboard ? valueFixer(partnersDashboard?.next_referral_program?.referral_turnover) : 0} title={t('Объем торгов структуры')}/>
                    </div>
                    <div className={`${Styles.infoBoxItem}`}>
                        <div className={`${Styles.titleRow}`}>
                            <div className={`${Styles.cabinetTitle2}`}>{t('Пригласить партнеров')}:</div>
                        </div>
                        <div className={`${Styles.copyBox}`}>
                            <div className={`${Styles.copyBoxItem}`}>
                                <div className={`${Styles.copyBoxItemLink}`}><span>{`${window.location.origin}/sign-up/ref/${partnersDashboard?.referral_code}`}</span></div>
                                <div className={`${Styles.copyBoxItemBtn}`} onClick={()=>{copyLinkHandler()}}>
                                    {copyLinkStatus ?<CabinetIcon name='copyA'/> : <CabinetIcon name='copyP'/>}
                                </div>
                            </div>
                            <div className={`${Styles.copyBoxItem}`}>
                                <div className={`${Styles.copyBoxItemLink}`}><span>{partnersDashboard ? partnersDashboard?.referral_code : 'Loading...'}</span></div>
                                <div className={`${Styles.copyBoxItemBtn}`} onClick={()=>{copyCodeHandler()}}>
                                    {copyCodeStatus ?<CabinetIcon name='copyA'/> : <CabinetIcon name='copyP'/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.tableBox}`}>
                    <div className={`${Styles.navRow}`}>
                        <div className={`${Styles.navRowDataWrap}`}>
                            <div className={`${Styles.navRowDataWrapItem} ${Styles.cabinetTitle2}`}>{t('Количество приглашенных')}: <span>{partnersDashboard?.total_referrals ? partnersDashboard.total_referrals : '0'}</span></div>
                            <div className={`${Styles.navRowDataWrapItem} ${Styles.cabinetTitle2}`}>{t('Внесли депозит')}: <span>{partnersDashboard?.active_referrals ? partnersDashboard.active_referrals : '0'}</span></div>
                        </div>
                        <div className={`${Styles.navRowFilterWrap}`}>
                            <div className={`${Styles.cabinetTitle2}`}>{t('Фильтр')}:</div>
                            <div className={`${Styles.navRowFilterBox}`}>
                                <div
                                    className={`${Styles.navRowFilterBoxItem} ${filter ? Styles.navRowFilterBoxItemActive : null}`}
                                    onClick={()=>{
                                        setList([]);
                                        filterAction(filter ? undefined : true)
                                    }}
                                >
                                    {t('Внесли депозит')}
                                </div>
                                <div
                                    className={`${Styles.navRowFilterBoxItem} ${!filter ? Styles.navRowFilterBoxItemActive : null}`}
                                    onClick={()=>{
                                        setList([]);
                                        filterAction(filter === false ? undefined : false);
                                    }}
                                >
                                    {t('Без депозита')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.tableContent}`}>
                        <div className={`${Styles.tableTitleRow}`}>
                            <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Имя')}</div>
                            <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Уровень')}</div>
                            <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Сумма депозита')}</div>
                        </div>
                        <div className={`${Styles.tableContentBox} ${referralsAll?.data?.length > 0 ? Styles.tableContentBoxCenter : null}`} ref={scrollRef}>
                            {list.length ? list.map((item, index)=>{
                                return (
                                    <div className={`${Styles.tableContentBoxItem}`} key={'partnersData' + index}>
                                        <div className={`${Styles.tableContentBoxItemValue} ${Styles.tableContentBoxItemName}`}>{item.name}</div>
                                        <div className={`${Styles.tableContentBoxItemValue} ${Styles.tableContentBoxItemValueWH} ${item.level ? Styles.colorGreen : Styles.colorRed}`}>
                                            {item.partners?.length > 0 ?
                                                <div className={`${Styles.tableContentBoxItemRefRow}`}>
                                                    {item.partners.map((itemR, indexR)=>{
                                                        return (
                                                            <div className={`${Styles.ref}`} key={'ref' + indexR}>{itemR}</div>
                                                        )
                                                    })}
                                                </div> : null
                                            }
                                            {item.level}
                                        </div>
                                        <div className={`${Styles.tableContentBoxItemValue} ${item.invested * 1 ? Styles.colorGreen : Styles.colorRed}`}>${item.invested ? (item.invested * 1).toFixed(2) : '0'}</div>
                                    </div>
                                )
                            }) : <EmptyData text={t('У вас пока что рефералов прошедших процедуру регистрации')}/>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CabinetPartners;