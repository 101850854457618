import React from "react";
import Styles from './styles.module.scss';



const CustomBar = (props) => {
    const {current, max, title} = props;

    const percentBarHandler = () =>{
        let currentPercent = (current / max) * 100;

        if (currentPercent > 100){
            return 100
        } else if (currentPercent > 5){
            return currentPercent
        } else if (currentPercent < 5 && currentPercent > 0){
            return 5
        } else {
            return 0
        }
    }

    return (
        <div className={`${Styles.barWrap}`}>
            {title ? <div className={`${Styles.barTitle}`}>{title}</div> : null}
            <div className={`${Styles.bar}`}>
                <div className={`${Styles.barValues}`}>${current} / ${max}</div>
                <div
                    className={`${Styles.barBg}`}
                    style={{width: `${percentBarHandler()}%`}}
                />
            </div>
        </div>
    );
};

export default CustomBar;