import React from "react";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";
import CryptoIcon from "../_UI/cryptoIcon";
import {valueFixer} from "../../helpers/valueFixer";
import {useTranslation} from "react-i18next";



const CabinetWalletsAll = () => {
    const { t } = useTranslation();

    const walletsData = useSelector(store => store.cabinet.wallets);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.cabinetLargeCardWS}`}>
                <div className={`${Styles.titleRow}`}>
                    <div className={`${Styles.cabinetTitle1}`}>{t('Кошельки')}</div>
                </div>
                <div className={`${Styles.tableTitleRow}`}>
                    <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Кошелек')}</div>
                    <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Сумма')}</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    {walletsData ? walletsData.map((item, index)=>{
                        return(
                            <div className={`${Styles.contentBoxItem}`} key={'transactionData' + index}>
                                <div className={`${Styles.contentBoxItemNameWrap}`}>
                                    <CryptoIcon name={item?.currency?.name}/>
                                    <div className={`${Styles.contentBoxItemName}`}>{item?.currency?.code}</div>
                                </div>
                                <div className={`${Styles.contentBoxItemAmount}`}>{valueFixer(item.amount, 4)} {item?.currency?.code}</div>
                            </div>
                        )
                    }) : null}
                </div>
            </div>
        </section>
    );
};

export default CabinetWalletsAll;