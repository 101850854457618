import React from "react";
import Styles from './styles.module.scss';
import Slider from "react-slick";
import {useSelector} from "react-redux";
import AboutSliderItem from "../_UI/aboutSliderItem";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";




const AboutNews = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const news = useSelector(store => store.main.news);

    const showSliderHandler = () =>{
        if (window.screen.width < 768 ){
            return news.length > 1 ? 1 : news.length
        } else if(window.screen.width >= 768 && window.screen.width < 1350){
            return news.length > 2 ? 2 : news.length
        } else if(window.screen.width >= 1350 && window.screen.width < 1920){
            return news.length > 3 ? 3 : news.length
        }else {
            return news.length > 4 ? 4 : news.length
        }
    }

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: showSliderHandler(),
        slidesToScroll: showSliderHandler(),
        autoplay: false,
        arrows: false,
        // beforeChange: (current, next) => dispatch(setCurrentWallet(next)),
        // afterChange: current => console.log({ activeSlide2: current })
    };

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageVerticalPadding}`}>
                <div className={`${Styles.title2} ${Styles.pageContainer}`}>{t('Наш Блог')}</div>
                <div className={`${Styles.sliderWrap} ${Styles.pageContainerWS4}`}>
                    {news ?
                        <Slider {...settings}>
                            {news?.map((item, index)=>{
                                return (
                                    <AboutSliderItem
                                        key={'aboutNews' + index}
                                        id={item.id}
                                        title={item.title}
                                        description={item.content}
                                        date={item.date}
                                        img={item.picture}
                                    />
                                )
                            })}
                        </Slider> : null
                    }
                </div>
                <div className={`${Styles.btnWrap}`}>
                    <div className={`${Styles.customBtnBlue}`} onClick={()=>{navigate('/news')}}>{t('Все новости')}</div>
                </div>
            </div>
        </section>
    );
};

export default AboutNews;