import React from "react";
import Styles from './styles.module.scss';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";




const ServicesItem = (props) => {
    // const {title, description, img} = props;
    const {title, description, img, link} = props;

    const { t } = useTranslation();
    const navigate = useNavigate();


    return (
        <div className={`${Styles.servicesItem}`}>
            <div className={`${Styles.servicesItemContent}`}>
                <div className={`${Styles.servicesItemImgWrap}`}>
                    <img src={img} alt={title}/>
                </div>
                <div className={`${Styles.servicesItemTitle}`}>{title}</div>
                <div className={`${Styles.servicesItemDescription}`}>{description}</div>
                <div className={`${Styles.servicesItemBtn}`} onClick={()=>{navigate(`/services/${link}`)}}>{t('Подробнее')}</div>
            </div>
        </div>
    );
};

export default ServicesItem;