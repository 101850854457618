import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import Seo from "../../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import ServicesContent from "../../../components/_UI/servicesContent";
import img1 from "../../../theme/assets/other/ssx_services_ce_img1.png";
import img2 from "../../../theme/assets/other/ssx_services_ce_img2.png";


const CryptocurrencyExchangePage = () => {
    const { t } = useTranslation();
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Обмен криптовалют')}/>
            <ServicesContent
                mainTitle={t('Обмен криптовалют')}
                helloParagraph={t('Отслеживание статистики обмена криптовалют помогает понять текущее состояние и динамику криптовалютного рынка. Ниже приведены ключевые метрики, отражающие объем торгов, разнообразие зарегистрированных криптовалют, доминирование основных криптовалют, а также средние комиссии и активность пользователей. Эти метрики играют важную роль для трейдеров и инвесторов, помогая принимать обоснованные решения и анализировать рыночные тенденции.')}
                helloList={false}
                mainImg={img1}
                middleTitle={t('Статистика обмена криптовалют')}
                middleParagraph={t('Эти метрики предоставляют ценную информацию о деятельности и тенденциях на криптовалютных биржах, помогая трейдерам и инвесторам ориентироваться в динамичной среде криптовалютного рынка.')}
                middleArr={[
                    {title: t('$50 миллиардов'), text: t('Ежедневный объем торгов')},
                    {title: '1.000.000', text: t('Количество транзакций в день')},
                    {title: t('10 миллионов'), text: t('Количество активных пользователей')},
                    {title: '20%', text: t('Рост объема транзакций')}
                ]}
                endImg={img2}
                endTitle={t('Тенденции обмена криптовалют')}
                endParagraph={t('Оставаться в курсе тенденций обмена криптовалютами необходимо для навигации в постоянно меняющейся среде цифровых активов. Изучите следующие тенденции, чтобы получить представление о рыночном поведении и потенциальных возможностях:')}
                endArr={[
                    {title: t('Децентрализованные финансы (DeFi)'), text: t('Многие биржи интегрируют протоколы и токены DeFi, предлагая пользователям доступ к децентрализованным услугам по кредитованию, заимствованию и торговле непосредственно с платформы биржи.')},
                    {title: t('Усиление регулирования'), text: t('Регуляторные изменения продолжают формировать пространство обмена криптовалют, и биржи адаптируются для соблюдения развивающихся правил по всему миру, способствуя доверию и легитимности в отрасли.')},
                    {title: t('Фокус на мерах безопасности'), text: t('Безопасность остается главным приоритетом для бирж, многие из которых внедряют передовые меры безопасности, такие как многофакторная аутентификация, решения для холодного хранения и страховое покрытие для защиты средств пользователей от потенциальных угроз.')},
                    {title: t('Рост NFT'), text: t('Популярность NFT способствует увеличению активности торговли на биржах, которые поддерживают эти уникальные цифровые активы, создавая новые возможности для художников, создателей и коллекционеров для участия в рынке.')}
                ]}
                isRed={true}
            />
        </section>
    );
};

export default CryptocurrencyExchangePage;