import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Styles from './styles.module.scss';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {setPartnersModalStatus} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";


const PartnersModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const modalStatus = useSelector(store => store.cabinet.partnersModalStatus);
    const partners = useSelector(store => store.cabinet.partners);

    const closeModal = () =>{
        dispatch(setPartnersModalStatus(false));
    }



    return (
        <Popup
            open={modalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.title}`}>{t('Приглашайте друзей, чтобы зарабатывать вместе!')}</div>
                    <div className={`${Styles.subTitle}`}>{t('Реферальная программа SSX предлагает множество способов заработка для Вас и Вашей команды:')}</div>
                    <div className={`${Styles.tableWrap}`}>
                        <div className={`${Styles.titleRow}`}>
                            <div className={`${Styles.titleRowItem}`}>{t('Ранг')}</div>
                            <div className={`${Styles.titleRowItem}`}>{t('Объем торгов структуры')}</div>
                            <div className={`${Styles.titleRowItem}`}>{t('Личный объем торгов')}</div>
                            <div className={`${Styles.titleRowItem}`}>{t('Награды')}</div>
                        </div>
                        <div className={`${Styles.tableBox}`}>
                            {partners ? partners.map((item, index)=>{
                                return (
                                    <div className={`${Styles.tableBoxRow}`} key={'refInfo' + index}>
                                        <div className={`${Styles.tableBoxRowItem} ${Styles.tableBoxRowItemColored}`}>{item.rang}</div>
                                        <div className={`${Styles.tableBoxRowItem}`}>{item.referral_turnover * 1}$</div>
                                        <div className={`${Styles.tableBoxRowItem}`}>{item.personal_turnover * 1}$</div>
                                        <div className={`${Styles.tableBoxRowItem}`}>{item.bonus_amount * 1}$</div>
                                    </div>
                                )
                            }): null}
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default PartnersModal;