import React from "react";
import Styles from './styles.module.scss';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
// import {useNavigate} from "react-router-dom";
// import {useTranslation} from "react-i18next";




const ServicesContent = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);

    const {
        mainTitle,
        helloParagraph,
        helloList,
        mainImg,
        middleTitle,
        middleParagraph,
        middleArr,
        endImg,
        endTitle,
        endParagraph,
        endArr,
        isRed
    } = props;



    return (
        <div className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} ${Styles.pageVerticalPadding} ${isRed ? Styles.contentWrapRed : null}`}>
                <div className={`${Styles.helloWrap}`}>
                    <div className={`${Styles.helloContent}`}>
                        <div className={`${Styles.helloContentSubTitle}`}>{t('О сервисе')}</div>
                        <div className={`${Styles.title2}`}>{mainTitle}</div>
                        <div className={`${Styles.helloContentParagraph}`}>{helloParagraph}</div>
                        {helloList ?
                            <div className={`${Styles.helloContentList}`}>
                                {helloList.map((item, index)=>{
                                    return (
                                        <div className={`${Styles.helloContentListItem}`} key={mainTitle + `hello${index}`}>
                                            <div className={`${Styles.helloContentListItemDot}`}>.</div>
                                            <span>{item.hlTitle}</span> {item.hlContent}
                                        </div>
                                    )
                                })}
                            </div> : null
                        }
                    </div>
                    <div className={`${Styles.helloImgWrap}`}>
                        <img src={mainImg} alt={mainTitle}/>
                    </div>
                </div>
                <div className={`${Styles.middleWrap}`}>
                    <div className={`${Styles.middleTextBox}`}>
                        <div className={`${Styles.title2}`}>{middleTitle}</div>
                        <div className={`${Styles.middleParagraph}`}>{middleParagraph}</div>
                        <div
                            className={`${Styles.customBtnBlueT}`}
                            onClick={()=>{
                                authStatus.authorization ? navigate('/cabinet/deposit') : navigate('/sign-in');
                            }}
                        >
                            {t('Депозит')}
                        </div>
                    </div>
                    <div className={`${Styles.middleValueBox}`}>
                        {middleArr ? middleArr.map((item, index)=>{
                            return (
                                <div className={`${Styles.middleValueBoxItem}`} key={mainTitle + `middle${index}`}>
                                    <div className={`${Styles.middleValueBoxItemTitle}`}>{item.title}</div>
                                    <div className={`${Styles.middleValueBoxItemText}`}>{item.text}</div>
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
                <div className={`${Styles.endWrap}`}>
                    <div className={`${Styles.endContent}`}>
                        <div className={`${Styles.title2}`}>{endTitle}</div>
                        <div className={`${Styles.endParagraph}`}>{endParagraph}</div>
                        <div className={`${Styles.endBox}`}>
                            {endArr ? endArr.map((item, index)=>{
                                return (
                                    <div className={`${Styles.endBoxItem}`} key={mainTitle + `end${index}`}>
                                        {item.title ? <div className={`${Styles.endBoxItemTitle}`}>{item.title}</div> : null}
                                        {item.subTitle ? <div className={`${Styles.endBoxItemSubTitle}`}>{item.subTitle}</div> : null}
                                        {item.text ? <div className={`${Styles.endBoxItemText}`}>{item.text}</div> : null}
                                    </div>
                                )
                            }) : null}
                        </div>
                    </div>
                    <div className={`${Styles.endImgWrap}`}>
                        <img src={endImg} alt={endTitle}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesContent;