import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import AboutHello from "../../components/aboutHello";
import AboutTeam from "../../components/aboutTeam";
import AboutDocuments from "../../components/aboutDocuments";
import AboutNews from "../../components/aboutNews";
import {useDispatch} from "react-redux";
import {getNews} from "../../store/main/action";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const AboutPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])
    
    useEffect(()=>{
        // dispatch(getAboutData());
        dispatch(getNews(8));
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('О нас')}/>
            <AboutHello/>
            <AboutTeam/>
            <AboutDocuments/>
            <AboutNews/>
        </section>
    );
};

export default AboutPage;