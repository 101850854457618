import React from "react";
import Styles from './styles.module.scss';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";




const MainHello = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <div className={`${Styles.title1}`}>{t('Ваш мост в мир ĸриптовалют')}</div>
                <div className={`${Styles.note}`}>
                    {t('SSX - надежный партнер в мире цифровых финансов. Удобные и безопасные решения для хранения и управления ĸриптоаĸтивами')}.
                </div>
                <div className={`${Styles.btnWrap}`}>
                    <div className={`${Styles.customBtnBlue}`} onClick={()=>{navigate('/about')}}>{t('о нас')}</div>
                    <div
                        className={`${Styles.customBtnBlueT}`}
                        onClick={()=>{
                            authStatus.authorization ? navigate('/cabinet/deposit') : navigate('/sign-in');
                        }}
                    >
                        {t('Депозит')}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainHello;