import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';
import CustomInput from "../_UI/customInput";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {sendContactForm, sendContactFormFailure, sendContactFormSuccess} from "../../store/main/action";
import {toast} from "react-toastify";



const ContactForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const contactStatus = useSelector(store => store.main.contactStatus);
    const contactStatusErr = useSelector(store => store.main.contactStatusErr);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const sendFormHandler = () =>{
        dispatch(sendContactForm({name, email, message, subject: title}))
    }
    
    useEffect(()=>{
        if (contactStatus){
            setEmail('');
            setName('');
            setTitle('');
            setMessage('');
            toast(t('Ваше сообщение успешно отправленно!'));
            dispatch(sendContactFormSuccess(false));
        }
    },[contactStatus, dispatch, t])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} `}>
                <div className={`${Styles.title2}`}>{t('Связаться с нами')}</div>
                <div className={`${Styles.contentBox}`}>
                    <CustomInput
                        gender='input'
                        type='text'
                        title={t('Имя')}
                        placeholder='Mike Vyazovsky'
                        value={name}
                        setValue={setName}
                        errDispatch={sendContactFormFailure}
                        errors={{full: contactStatusErr, type: 'name'}}
                    />
                    <CustomInput
                        gender='input'
                        type='text'
                        title={t('Почта')}
                        placeholder='example@mail.com'
                        value={email}
                        setValue={setEmail}
                        errDispatch={sendContactFormFailure}
                        errors={{full: contactStatusErr, type: 'email'}}
                    />
                    <CustomInput
                        gender='input'
                        type='text'
                        title={t('Причина обращения')}
                        placeholder='Collaboration'
                        value={title}
                        setValue={setTitle}
                        errDispatch={sendContactFormFailure}
                        errors={{full: contactStatusErr, type: 'subject'}}
                    />
                    <CustomInput
                        gender='area'
                        type='text'
                        title={t('Сообщение')}
                        placeholder='Example message for us'
                        value={message}
                        setValue={setMessage}
                        errDispatch={sendContactFormFailure}
                        errors={{full: contactStatusErr, type: 'message'}}
                    />
                    <div className={Styles.customBtnBlue} style={{width: '100%'}} onClick={()=>{sendFormHandler()}}>{t('Отправить')}</div>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;