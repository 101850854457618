import React from "react";
import Styles from './styles.module.scss';
import {useTranslation} from "react-i18next";



const MainAdvantages = () => {
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} ${Styles.pageVerticalPadding}`}>
                <div className={`${Styles.title2}`}>{t('Достижения')}</div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.result}`}>10K+</div>
                        <div className={`${Styles.resultNote}`}>{t('Успешно отĸрытых депозитов')}</div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.result}`}>100M+</div>
                        <div className={`${Styles.resultNote}`}>{t('USD в управлении')}</div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.result}`}>99.9%</div>
                        <div className={`${Styles.resultNote}`}>{t('Удовлетворенных ĸлиентов')}</div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.result}`}># 1</div>
                        <div className={`${Styles.resultNote}`}>{t('Лет на рынĸе')}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainAdvantages;