import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import Seo from "../../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import ServicesContent from "../../../components/_UI/servicesContent";
import img1 from "../../../theme/assets/other/ssx_services_sc_img1.png";
import img2 from "../../../theme/assets/other/ssx_services_sc_img2.png";


const SmartContractsAndDefiPage = () => {
    const { t } = useTranslation();
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Смарт-контракты и DeFi')}/>
            <ServicesContent
                mainTitle={t('Смарт-контракты и DeFi')}
                helloParagraph={t('Смарт-контракты и децентрализованные финансы (DeFi) революционизируют финансовую индустрию, обеспечивая автоматизированные, прозрачные и безопасные транзакции без посредников. Смарт-контракты являются самовыполняющимися контрактами, условия которых напрямую записаны в коде, обеспечивая доверие и эффективность. DeFi использует эти контракты для предоставления широкого спектра финансовых услуг, включая кредитование, заимствование, торговлю и получение процентов, все в рамках децентрализованной экосистемы.')}
                helloList={false}
                mainImg={img1}
                middleTitle={t('Ключевые показатели использования смарт-контрактов')}
                middleParagraph={t('Эти показатели подчеркивают значительное принятие и активность в сфере DeFi, демонстрируя его влияние на финансовую индустрию. Увеличение общей заблокированной стоимости и числа пользователей отражает доверие и растущий интерес, в то время как быстрое время исполнения и многочисленные протоколы демонстрируют эффективность и разнообразие доступных в рынке DeFi.')}
                middleArr={[
                    {title: t('80 миллиардов $'), text: t('Общая заблокированная стоимость (TVL) в DeFi')},
                    {title: t('4 миллиона'), text: t('Количество пользователей DeFi:')},
                    {title: t('15 секунд'), text: t('Среднее время исполнения смарт-контракта')},
                    {title: t('250 Протоколов'), text: t('Общее количество протоколов DeFi')}
                ]}
                endImg={img2}
                endTitle={t('Метрики роста и безопасности DeFi')}
                endParagraph={t('Высокий темп роста и объем транзакций подчеркивают расширение сектора, в то время как количество аудитов и страховое покрытие отражают усилия по улучшению безопасности и доверия пользователей.')}
                endArr={[
                    {title: '40%', subTitle: t('Снижение операционных рисков'), text: t('Показывает быстрый рост и увеличение доверия к решениям DeFi, поскольку в них заблокировано все больше активов.')},
                    {title: t('500 в год'), subTitle: t('Темп улучшения соблюдения правил'), text: t('Отражает акцент на безопасность и надежность, с регулярными аудитами, обеспечивающими целостность смарт-контрактов.')},
                    {title: t('1 триллион долларов в год'), subTitle: t('Объем транзакций DeFi'), text: t('Демонстрирует огромный масштаб сделок, проводимых платформами DeFi, подчеркивая их важность в финансовой экосистеме.')},
                    {title: '30%', subTitle: t('Процент DeFi-платформ с страхованием'), text: t('Показывает растущую тенденцию включения механизмов страхования в платформы DeFi для защиты пользователей от потенциальных потерь.')},
                ]}
                isRed={true}
            />
        </section>
    );
};

export default SmartContractsAndDefiPage;