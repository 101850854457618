export const valueShorter = (amount, precision) =>{
    if (amount === 0) {
        return 0;
    }

    let value2 = Math.pow(10, precision);
    amount = Math.floor(amount * value2);
    amount /= value2;

    return amount.toFixed(precision) * 1;
}