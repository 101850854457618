import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import CabinetHeader from "../../components/cabinetHeader";
import CabinetTransactionsAll from "../../components/cabinetTransactionAll";
import {getMainStats, getTransactions, getUserData} from "../../store/cabinet/action";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const CabinetTransactionsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);

    useEffect(()=>{
        if (!authStatus.authorization){
            navigate('/sign-in')
        }
    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getTransactions());
        dispatch(getMainStats());
        dispatch(getUserData());
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Транзакции')}/>
            <div className={`${Styles.contentWrap} ${Styles.cabinetVerticalPadding}`}>
                <CabinetHeader/>
                <CabinetTransactionsAll/>
            </div>
        </section>
    );
};

export default CabinetTransactionsPage;