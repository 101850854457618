import React, {useEffect, useCallback, useState} from "react";
import Styles from './styles.module.scss';
import CustomInput from "../_UI/customInput";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {registration, registrationFailure} from "../../store/main/action";
import {useTranslation} from "react-i18next";



const RegForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {code} = useParams();
    const { t } = useTranslation();

    const formErr = useSelector(store => store.main.registrationErr);
    const authStatus = useSelector(store => store.main.authorizationStatus);


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [referral_code, setReferral_code] = useState(code ? code : '');

    const sendFormHandler = useCallback(()=>{
        dispatch(registration({email, password, password_confirmation, referral_code, agree_policy: true}));
    }, [email, password, password_confirmation, referral_code, dispatch])

    useEffect(()=>{
        if (authStatus.authorization){
            navigate('/cabinet/dashboard');
        }
    },[authStatus, navigate])

    // useEffect(() => {
    //     const onKeyDown = e => {
    //         if(e.keyCode === 13) {
    //             sendFormHandler();
    //         }
    //     };
    //     document.addEventListener('keydown', onKeyDown);
    //     return () => {
    //         document.removeEventListener('keydown', onKeyDown);
    //     };
    // }, [sendFormHandler]);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} `}>
                <div className={`${Styles.title2}`}>{t('Регистрация')}</div>
                <div className={`${Styles.contentBox}`}>
                    {code ?
                        <div className={`${Styles.fakeInputWrap}`}>
                            <div className={`${Styles.fakeInputTitle}`}>{t('Реферал')} ID (t{'необязательно'})</div>
                            <div className={`${Styles.fakeInput}`}>{code}</div>
                        </div> :
                        <CustomInput
                            gender='input'
                            type='text'
                            title={`${t('Реферал')} ID (${t('необязательно')})`}
                            placeholder='9845g76b'
                            value={referral_code}
                            setValue={setReferral_code}
                            errDispatch={registrationFailure}
                            errors={false}
                        />
                    }
                    {/*<CustomInput gender='input' type='text' title='Username' placeholder='Username' setValue/>*/}
                    <CustomInput
                        gender='input'
                        type='text'
                        title={t('Почта')}
                        placeholder='example@mail.com'
                        value={email}
                        setValue={setEmail}
                        errDispatch={registrationFailure}
                        errors={{full: formErr, type: 'email'}}
                    />
                    <CustomInput
                        gender='input'
                        type='password'
                        title={t('Пароль')}
                        placeholder='***********'
                        value={password}
                        setValue={setPassword}
                        errDispatch={registrationFailure}
                        errors={{full: formErr, type: 'password'}}
                    />
                    <CustomInput
                        gender='input'
                        type='password'
                        title={t('Подтвердите пароль')}
                        placeholder='***********'
                        value={password_confirmation}
                        setValue={setPassword_confirmation}
                        errDispatch={registrationFailure}
                        errors={{full: formErr, type: 'password_confirmation'}}
                    />
                    <div className={Styles.customBtnBlue} style={{width: '100%'}} onClick={()=>{sendFormHandler()}}>{t('Регистрация')}</div>
                </div>
                <div className={`${Styles.linkWrap}`}>
                    <div className={`${Styles.linkRow}`}>{t('У вас уже есть аккаунт')}? <span onClick={()=>{navigate('/sign-in')}}>{t('Вход')}</span></div>
                </div>
            </div>
        </section>
    );
};

export default RegForm;