import React from "react";
import Styles from './styles.module.scss'
import Logo from "../_UI/logo";
import SocItem from "../_UI/socItem";
import {useNavigate} from "react-router-dom";
import {scroller} from "react-scroll";
import {pageScrollHandler} from "../../helpers/pageScrollHandler";
import {setLinkScrollStatus} from "../../store/main/action";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";



const Footer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const scrollHandler = (element) =>{
        scroller.scrollTo(element, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    const navigationHandler = (page) =>{
        if (pageScrollHandler()){
            dispatch(setLinkScrollStatus(page));
            navigate('/');
        } else {
            scrollHandler(page);
        }
    }

    return (
        <section className={`${Styles.container}`} style={{backgroundColor: window.location.pathname === '/' ? '#0f0f0f' : '#222222'}}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <div className={`${Styles.logoWrap}`}>
                    <Logo/>
                    <div className={`${Styles.logoText}`}>
                        {t('Ваш мост')}<br/>
                        {t('в мир ĸриптовалют')}
                    </div>
                </div>
                <div className={`${Styles.navWrap}`}>
                    <div className={`${Styles.navBox}`}>
                        <div className={`${Styles.navBoxItem}`} onClick={()=>{navigate('/about')}}>{t('О нас')}</div>
                        <div className={`${Styles.navBoxItem}`} onClick={()=>{navigate('/services')}}>{t('Наши сервисы')}</div>
                        <div className={`${Styles.navBoxItem}`} onClick={()=>{navigationHandler('advantages')}}>{t('Преимущества')}</div>
                        <div className={`${Styles.navBoxItem}`} onClick={()=>{navigate('/contacts')}}>{t('Контакты')}</div>
                        <div className={`${Styles.navBoxItem}`} onClick={()=>{navigationHandler('faq')}}>FAQ</div>
                    </div>
                </div>
                <div className={`${Styles.socWrap}`}>
                    <SocItem type='facebook'/>
                    <SocItem type='instagram'/>
                    <SocItem type='twitter'/>
                </div>
            </div>
        </section>
    );
};

export default Footer;