
export const api = {
    host:  process.env.REACT_APP_API_URL,
    // host:  'http://api.olimp.alphadevteam.net/',
    // host:  'https://api.ssx24.net/',
    login: 'api/v1/login',
    loginWH: 'api/v1/login-via-code',
    registration: 'api/v1/register', 
    logout: 'api/v1/logout',
    about: 'api/v1/ol/about',
    contact: 'api/v1/support',
    forgotPassword: 'api/v1/forgot-password',
    resetPassword: 'api/v1/reset-password',
    latestNews: 'api/v1/posts/latest',
    allNews: 'api/v1/posts/paginated',
    post: 'api/v1/posts',
    cabinet: {
        user: 'api/v1/user',
        wallets: 'api/v1/wallets',
        partners: 'api/v1/referral-programs',
        partnersDashboard: 'api/v1/partners-dashboard',
        getDeposit: 'api/v1/deposits/cabinet',
        deposit: 'api/v1/deposits',
        topup: 'api/v1/top-up',
        withdraw: 'api/v1/withdraw',
        userDeposit: 'api/v1/user-deposits',
        translations: 'api/v1/transactions/latest',
        currencies: 'api/v1/currencies',
        stats: 'api/v1/ol/stats',
        referrals: 'api/v1/referrals/paginated',
        help: 'api/v1/support',
        changePassword: 'api/v1/settings/change-password',
        invest: 'api/v1/user-deposits',
        accruals: 'api/v1/referral-transactions/latest'
    }
}