import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import Seo from "../../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import ServicesContent from "../../../components/_UI/servicesContent";
import img1 from "../../../theme/assets/other/ssx_services_cps_img1.png";
import img2 from "../../../theme/assets/other/ssx_services_cps_img2.png";


const CardsAndPaymentSystemsPage = () => {
    const { t } = useTranslation();
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Карты и платежные системы')}/>
            <ServicesContent
                mainTitle={t('Карты и платежные системы')}
                helloParagraph={t('Наши карты и платежные системы предоставляют беспроблемные и безопасные решения для всех ваших финансовых операций. Будь то покупки в интернете, совершение покупок в магазинах или перевод средств, наши платежные решения разработаны для удовлетворения ваших потребностей с легкостью и надежностью. С акцентом на удобство и безопасность мы предлагаем широкий выбор карт и платежных услуг, адаптированных к вашему образу жизни.')}
                helloList={false}
                mainImg={img1}
                middleTitle={t('Метрики использования карты')}
                middleParagraph={t('Эти метрики подчеркивают широкое использование и удовлетворенность нашими картами. Высокие объемы транзакций и активные номера карт отражают доверие и надежность, а высокий уровень удовлетворенности указывает на положительный опыт пользователей.')}
                middleArr={[
                    {title: t('5 миллионов'), text: t('Количество активных карт')},
                    {title: t('$1.2 миллиарда'), text: t('Ежемесячный объем транзакций')},
                    {title: '$75', text: t('Средняя стоимость транзакции')},
                    {title: '95%', text: t('Уровень удовлетворенности владельцев карт')}
                ]}
                endImg={img2}
                endTitle={t('Производительность платежной системы')}
                endParagraph={t('Эти метрики демонстрируют эффективность и безопасность наших платежных систем. Высокий уровень успешных транзакций и быстрое время обработки обеспечивают бесшовные операции, а продвинутые системы обнаружения мошенничества обеспечивают надежную защиту и значительные экономические выгоды.')}
                endArr={[
                    {title: '99.8%', subTitle: t('Успешность транзакции')},
                    {title: t('2 секунды'), subTitle: t('Среднее время обработки транзакции')},
                    {title: '98.5%', subTitle: t('Точность обнаружения мошенничества')},
                    {title: t('$10 миллионов'), subTitle: t('Ежемесячные сбережения от предотвращения мошенничества')},
                ]}
            />
        </section>
    );
};

export default CardsAndPaymentSystemsPage;