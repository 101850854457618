import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';
import CustomInput from "../_UI/customInput";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {resetPassword, resetPasswordFailure, resetPasswordSuccess} from "../../store/main/action";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";



const ResetForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {email} = useParams();

    const resetPasswordStatus = useSelector(store => store.main.resetPasswordStatus);
    const resetPasswordStatusErr = useSelector(store => store.main.resetPasswordStatusErr);

    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const sendFormHandler = () =>{
        dispatch(resetPassword({email, token: code, password: newPassword}))
    }
    
    useEffect(()=>{
        if (resetPasswordStatus){
            toast(t('Ваш пароль успешно изменен!'));
            navigate('/sign-in');
            setCode('');
            setNewPassword('');
            resetPasswordSuccess(false);
        }
    },[navigate, resetPasswordStatus, t])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} `}>
                <div className={`${Styles.title2}`}>{t('Сброс пароля')}</div>
                <div className={`${Styles.contentBox}`}>
                    <CustomInput
                        gender='input'
                        type='text'
                        title={t('Почта')}
                        placeholder='example@mail.com'
                        isReadOnly={true}
                        value={email ? email : ''}
                    />
                    <CustomInput
                        gender='input'
                        type='text'
                        title={t('Код')}
                        placeholder='645645'
                        value={code}
                        setValue={setCode}
                        errDispatch={resetPasswordFailure}
                        errors={{full: resetPasswordStatusErr, type: 'token'}}
                    />
                    <CustomInput
                        gender='input'
                        type='password'
                        title={t('Новый пароль')}
                        placeholder='***********'
                        value={newPassword}
                        setValue={setNewPassword}
                        errDispatch={resetPasswordFailure}
                        errors={{full: resetPasswordStatusErr, type: 'password'}}
                    />
                    <div className={Styles.customBtnBlue} style={{width: '100%'}} onClick={()=>{sendFormHandler()}}>{t('Сброс пароля')}</div>
                </div>
                <div className={`${Styles.linkWrap}`}>
                    <div className={`${Styles.linkRow}`}>{t('Еще нет аккаунта?')}? <span onClick={()=>{navigate('/sign-up')}}>{t('Регистрация')}</span></div>
                </div>
            </div>
        </section>
    );
};

export default ResetForm;