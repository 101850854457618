import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import ServicesMain from "../../components/servicesMain";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";


const ServicesPage = () => {
    const { t } = useTranslation();
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Наши сервисы')}/>
            <ServicesMain/>
        </section>
    );
};

export default ServicesPage;