import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import CabinetHeader from "../../components/cabinetHeader";
import {
    getInvestDetails,
    getInvestDetailsOperations,
    getInvestDetailsTransactions,
    getMainStats,
    getUserData
} from "../../store/cabinet/action";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import CabinetInvestmentDetailsMain from "../../components/cabinetInvestmentDetailsMain";
import CabinetInvestmentDetailsOperations from "../../components/cabinetInvestmentDetailsOperations";
import CabinetInvestmentDetailsTransactions from "../../components/cabinetInvestmentDetailsTransactions";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const CabinetInvestmentDetailsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {uuid} = useParams();
    const { t } = useTranslation();
    
    const authStatus = useSelector(store => store.main.authorizationStatus);

    useEffect(()=>{
        if (!authStatus.authorization){
            navigate('/sign-in')
        }
    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getMainStats());
        dispatch(getUserData());
    },[dispatch])

    useEffect(()=>{
        dispatch(getInvestDetails(uuid));
        dispatch(getInvestDetailsOperations(uuid));
        dispatch(getInvestDetailsTransactions(uuid));
    },[dispatch, uuid])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Инвестиции')}/>
            <div className={`${Styles.contentWrap} ${Styles.cabinetVerticalPadding}`}>
                <CabinetHeader/>
                <CabinetInvestmentDetailsMain/>
                <CabinetInvestmentDetailsOperations/>
                <CabinetInvestmentDetailsTransactions/>
            </div>
        </section>
    );
};

export default CabinetInvestmentDetailsPage;