import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';
import CustomInput from "../_UI/customInput";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {forgotPassword, forgotPasswordFailure, forgotPasswordSuccess} from "../../store/main/action";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";



const ForgotForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const forgotPasswordStatus = useSelector(store => store.main.forgotPasswordStatus);
    const forgotPasswordStatusErr = useSelector(store => store.main.forgotPasswordStatusErr);

    const [email, setEmail] = useState('');

    const sendFormHandler = () =>{
        dispatch(forgotPassword({email}))
    }
    
    useEffect(()=>{
        if (forgotPasswordStatus){
            toast(t('Проверьте вашу почту, там вы найдете код для восстановления пароля!'));
            navigate(`/reset-password/${email}`)
            setEmail('');
            dispatch(forgotPasswordSuccess(false));
        }
    },[dispatch, email, forgotPasswordStatus, navigate, t])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} `}>
                <div className={`${Styles.title2}`}>{t('Востановить пароль')}</div>
                <div className={`${Styles.contentBox}`}>
                    <CustomInput
                        gender='input'
                        type='text'
                        title={t('Почта')}
                        placeholder='example@mail.com'
                        value={email}
                        setValue={setEmail}
                        errDispatch={forgotPasswordFailure}
                        errors={{full: forgotPasswordStatusErr, type: 'email'}}
                    />
                    <div className={Styles.customBtnBlue} style={{width: '100%'}} onClick={()=>{sendFormHandler()}}>{t('Востановить пароль')}</div>
                </div>
                <div className={`${Styles.linkWrap}`}>
                    <div className={`${Styles.linkRow}`}>{t('Еще нет аккаунта?')} <span onClick={()=>{navigate('/sign-up')}}>{t('Регистрация')}</span></div>
                </div>
            </div>
        </section>
    );
};

export default ForgotForm;