import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    getInvestDetails, getInvestDetailsTransactions, investDeposit, investDepositSuccess,
    setInvestDetailsReinvest,
    setInvestDetailsReinvestSuccess
} from "../../store/cabinet/action";
import CabinetCustomInput from "../_UI/cabinetCustomInput";
import {valueFixer} from "../../helpers/valueFixer";
import {toast} from "react-toastify";



const CabinetInvestmentDetailsMain = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {uuid} = useParams();

    const investDetails = useSelector(store => store.cabinet.investDetails);
    const reinvestStatus = useSelector(store => store.cabinet.setInvestDetailsReinvestStatus);
    const reinvestStatusStarted = useSelector(store => store.cabinet.setInvestDetailsReinvestStatusStarted);
    const investDepositStatus = useSelector(store => store.cabinet.investDepositStatus);
    const investDepositStatusStarted = useSelector(store => store.cabinet.investDepositStatusStarted);

    const [amount, setAmount] = useState('');

    const reinvestHandler = (reinvest) =>{
        if (!reinvestStatusStarted){
            dispatch(setInvestDetailsReinvest({auto_reinvest_percent: reinvest === false ? 0 : reinvest}, uuid))
        }
    }

    const sendConditionHandler = () =>{
        if (investDetails && amount > 0){
            return valueFixer(investDetails?.deposit?.deposit?.currency?.min_reinvest_amount, 4) <= Number(amount) && valueFixer(investDetails?.deposit?.deposit?.currency?.max_amount - investDetails?.deposit?.amount, 4) >= Number(amount) && Number(amount) <= Number(investDetails?.balance?.amount)
        }
    }

    const openDepositHandler = () =>{
        if (sendConditionHandler()){
            if (!investDepositStatusStarted){
                dispatch(investDeposit({amount}, uuid));
            }
        }
    }
    
    useEffect(()=>{
        if (reinvestStatus){
            dispatch(getInvestDetails(uuid));
            dispatch(setInvestDetailsReinvestSuccess(false));
        }
    }, [dispatch, reinvestStatus, uuid])
    
    useEffect(()=>{
        if (investDepositStatus){
            toast(t('Ваши средства успешно инвестированы!'));
            setAmount('');
            dispatch(getInvestDetails(uuid));
            dispatch(getInvestDetailsTransactions(uuid));
            dispatch(investDepositSuccess(false));
        }
    },[dispatch, investDepositStatus, t, uuid])


    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.cabinetLargeCardWS}`}>
                <div className={`${Styles.titleRow}`}>
                    <div className={`${Styles.cabinetTitle1}`}>{investDetails?.deposit?.deposit?.name ? investDetails?.deposit?.deposit?.name : ''}</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.detailsWrap}`}>
                        <div className={`${Styles.detailsItem}`}>
                            <div className={`${Styles.detailsItemName}`}>{t('Сумма')}:</div>
                            <div className={`${Styles.detailsItemValue}`}>{investDetails?.deposit?.amount ? investDetails?.deposit?.amount * 1 : '0'} {investDetails?.deposit?.currency?.code ? investDetails?.deposit?.currency?.code : '$'}</div>
                        </div>
                        <div className={`${Styles.detailsItem}`}>
                            <div className={`${Styles.detailsItemName}`}>{t('Доходность')}:</div>
                            <div className={`${Styles.detailsItemValue}`}>{investDetails?.deposit?.profit ? investDetails?.deposit?.profit * 1 : '0'} {investDetails?.deposit?.currency?.code ? investDetails?.deposit?.currency?.code : '$'}</div>
                        </div>
                        <div className={`${Styles.detailsItem}`}>
                            <div className={`${Styles.detailsItemName}`}>{t('Начисления')}:</div>
                            <div className={`${Styles.detailsItemValue}`}>{investDetails?.deposit?.worked_days ? investDetails?.deposit?.worked_days : 0} / {investDetails?.deposit?.duration ? investDetails?.deposit?.duration : 0}</div>
                        </div>
                        <div className={`${Styles.detailsItem}`}>
                            <div className={`${Styles.detailsItemName}`}>{t('Последний доход')}:</div>
                            <div className={`${Styles.detailsItemValue}`}>{investDetails?.deposit?.last_profit ? investDetails?.deposit?.last_profit * 1 : '0'} {investDetails?.deposit?.currency?.code ? investDetails?.deposit?.currency?.code : '$'}</div>
                        </div>
                    </div>
                    {investDetails?.deposit?.deposit?.setting?.is_auto_reinvest_available ?
                        <div className={`${Styles.reinvestBox}`}>
                            <div className={`${Styles.cabinetTitle2}`}>{t('Реинвестирование')}:</div>
                            <div className={`${Styles.reinvestRow}`}>
                                <div className={`${Styles.reinvestInput}`}>
                                    <input type="checkbox" onChange={(e)=>{reinvestHandler(e.target.checked)}} checked={investDetails?.deposit?.auto_reinvest_enabled}/>
                                </div>
                                <div className={`${Styles.reinvestNote}`}>
                                    {t('Вы можете включить функцию')} <span>"{t('Реинвестирование')}"</span> {t('чтобы автоматически реинвестировать полученные средства, обеспечивая непрерывный инвестиционный процесс')}.
                                </div>
                            </div>
                        </div>
                        : null}
                    <div className={`${Styles.amountRow}`}>
                        <div className={`${Styles.cabinetTitle2}`}>{t('Сумма')}:</div>
                        <CabinetCustomInput
                            gender='input'
                            type='number'
                            placeholder={`Min: ${investDetails?.deposit?.deposit?.currency?.min_reinvest_amount ? valueFixer(investDetails?.deposit?.deposit?.currency?.min_reinvest_amount, 4) : '0'} ${investDetails?.deposit?.currency?.code ? investDetails?.deposit?.currency?.code : ''}`}
                            value={amount}
                            setValue={setAmount}
                        />
                        <div className={`${Styles.amountNote}`}>
                            <div className={`${Styles.amountNoteItem}`}>{t('Доступно')}: <span>{investDetails?.balance?.amount ? investDetails?.balance?.amount * 1 : '0'} {investDetails?.deposit?.currency?.code ? investDetails?.deposit?.currency?.code : ''}</span></div>
                            <div className={`${Styles.amountNoteItem}`}>{`Min/Max: ${investDetails?.deposit?.deposit?.currency?.min_reinvest_amount ? valueFixer(investDetails?.deposit?.deposit?.currency?.min_reinvest_amount, 4) : '0'} - ${investDetails?.deposit?.deposit?.currency?.max_amount && investDetails?.deposit?.amount ? valueFixer(investDetails?.deposit?.deposit?.currency?.max_amount - investDetails?.deposit?.amount, 4) : '0'} ${investDetails?.deposit?.currency?.code ? investDetails?.deposit?.currency?.code : ''}`}</div>
                        </div>
                    </div>
                    <div className={`${Styles.btnRow}`} onClick={()=>{openDepositHandler()}}>
                        <div className={` ${sendConditionHandler() ? Styles.cabinetBtn : Styles.cabinetBtnDisable}`}>{t('Инвестировать')}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CabinetInvestmentDetailsMain;