import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import Seo from "../../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import ServicesContent from "../../../components/_UI/servicesContent";
import img1 from "../../../theme/assets/other/ssx_services_pd_img1.png";
import img2 from "../../../theme/assets/other/ssx_services_pd_img2.png";


const PercentOnDepositsAndLoansPage = () => {
    const { t } = useTranslation();
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Процент на депозиты и кредиты')}/>
            <ServicesContent
                mainTitle={t('Процент на депозиты и кредиты')}
                helloParagraph={t('Процентные ставки по депозитам и кредитам играют ключевую роль в обеспечении финансовой жизнеспособности банка. Депозиты служат основным источником средств, которые банк использует для предоставления кредитов и инвестиций. Кредиты, в свою очередь, генерируют процентный доход, что позволяет банку поддерживать ликвидность и покрывать операционные расходы.')}
                helloList={false}
                mainImg={img1}
                middleTitle={t('Метрики для финансового планирования')}
                middleParagraph={t('Процентные ставки по депозитам и кредитам являются критическими для эффективного финансового планирования. Вот ключевые метрики, которые помогут вам понять и управлять ставками. Понимание этих процентов помогает людям принимать обоснованные решения о сбережениях, кредитах и инвестициях, обеспечивая финансовое благополучие и стабильность.')}
                middleArr={[
                    {title: t('1.2% в год'), text: t('Текущая процентная ставка по сберегательному счету')},
                    {title: t('2.5% в год'), text: t('Текущая ставка по фиксированному депозиту')},
                    {title: t('4.5% в год'), text: t('Средняя процентная ставка по ипотечному кредиту')},
                    {title: t('6.0% в год'), text: t('Средняя процентная ставка по личному кредиту')}
                ]}
                endImg={img2}
                endTitle={t('Почему это хорошо для нас и выгодно для вас')}
                endParagraph={t('Понимание этих процентов помогает людям принимать обоснованные решения о сбережениях, кредитах и инвестициях, обеспечивая финансовое благополучие и стабильность.')}
                endArr={[
                    {title: t('Процентная ставка по депозиту'), text: t('Процентная ставка, по которой финансовые учреждения выплачивают клиентам за внесение средств на их счета.')},
                    {title: t('Процентная ставка по кредиту'), text: t('Процентная ставка, по которой финансовые учреждения взимают с клиентов за заем средств, часто варьирующаяся в зависимости от типа кредита и текущих рыночных условий.')},
                    {title: t('Годовая доходность (APY)'), text: t('Эффективная годовая доходность с учетом эффекта сложных процентов, обычно применяемая к депозитным счетам.')},
                    {title: t('Годовая процентная ставка (APR)'), text: t('Годовая процентная ставка за заем или инвестиции, исключая эффект сложных процентов.')}
                ]}
            />
        </section>
    );
};

export default PercentOnDepositsAndLoansPage;