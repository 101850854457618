import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Styles from './styles.module.scss';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {openDepositSuccess, setDepositModalStatus} from "../../store/cabinet/action";

import successIcon from '../../theme/assets/svg/oli_cabinet_modal_success.svg';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";



const DepositModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const modalStatus = useSelector(store => store.cabinet.depositModalStatus);

    const closeModal = () =>{
        dispatch(setDepositModalStatus(false));
        dispatch(openDepositSuccess(false));
    }

    return (
        <Popup
            open={modalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.modalImgWrap}`}>
                        <img src={successIcon} alt="Success!"/>
                    </div>
                    <div className={`${Styles.modalTitle}`}>{t('Успех')}!</div>
                    <div className={`${Styles.modalNote}`}>
                        {t('Мы рады сообщить Вам, что Вы успешно открыли Депозит! Теперь вы можете легко отслеживать его эффективность, перейдя в раздел')}
                        <span onClick={()=>{
                            closeModal();
                            navigate('/cabinet/investments');
                        }}> {t('Инвестиции2')}</span>. {t('Спасибо за ваш выбор и доверие!')}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default DepositModal;