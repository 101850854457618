import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Styles from './styles.module.scss';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {setWithdrawCommissionModalStatus, withdraw} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";



const WithdrawCommissionModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const modalStatus = useSelector(store => store.cabinet.withdrawCommissionModalStatus);

    const closeModal = () =>{
        dispatch(setWithdrawCommissionModalStatus({status: false, data: false}));
        // dispatch(withdrawSuccess(false));
    }

    const withdrawHandler = () =>{
        dispatch(withdraw(modalStatus.data.id, {amount: modalStatus.data.amount, address_for_withdraw: modalStatus.data.address_for_withdraw}));
        closeModal();
    }

    return (
        <Popup
            open={modalStatus?.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.modalTitle}`}>{t('Уведомление о выводе средств')}</div>
                    <div className={`${Styles.modalNote}`}>{t('Вы собираетесь совершить вывод средств. Пожалуйста, обратите внимание, что к вашему запросу будет применена комиссия со стороны блокчейна. Размер комиссии может варьироваться в зависимости от загруженности сети и выбранной криптовалюты')}.</div>
                    <div className={`${Styles.modalTable}`}>
                        <div className={`${Styles.modalTableRow}`}>
                            <div className={`${Styles.modalTableRowName}`}>{t('Сумма')}</div>
                            <div className={`${Styles.modalTableRowValue}`}>{modalStatus?.data?.amount ? modalStatus?.data?.amount : '0'} {modalStatus?.data?.currencyCode}</div>
                        </div>
                        <div className={`${Styles.modalTableRow}`}>
                            <div className={`${Styles.modalTableRowName}`}>{t('Комиссия')}</div>
                            <div className={`${Styles.modalTableRowValue}`}>{modalStatus?.data?.commission ? modalStatus?.data?.commission * 1 : '0'} {modalStatus?.data?.currencyCode}</div>
                        </div>
                        <div className={`${Styles.modalTableRow}`}>
                            <div className={`${Styles.modalTableRowName}`}>{t('Вы получите')}</div>
                            <div className={`${Styles.modalTableRowValue}`}>{modalStatus?.data?.commission && modalStatus?.data?.amount ? (Number(modalStatus?.data?.amount) - Number(modalStatus?.data?.commission)) : '0'} {modalStatus?.data?.currencyCode}</div>
                        </div>
                    </div>
                    <div className={`${Styles.modalNote}`}>{t('Пожалуйста, убедитесь, что вы ознакомлены с текущими комиссиями и временем обработки перед подтверждением вывода')}.</div>
                    <div className={`${Styles.modalNote}`}>{t('Нажимая “Подтвердить”, вы соглашаетесь с условиями вывода и осведомлены о возможных комиссиях')}.</div>
                    <div className={`${Styles.btnWrap}`}>
                        <div className={`${Styles.cabinetBtn}`} onClick={()=>{closeModal()}}>{t('Отменить')}</div>
                        <div className={`${Styles.cabinetBtn}`} onClick={()=>{withdrawHandler()}}>{t('Подтвердить')}</div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default WithdrawCommissionModal;