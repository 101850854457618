import React, {useEffect} from "react";
import {LiveChatWidget} from '@livechat/widget-react'
import {useDispatch, useSelector} from "react-redux";
import {sendChatMessage} from "../../store/cabinet/action";



const ExternalChat = () => {
    const dispatch = useDispatch();
    // const customerData = useWidgetCustomerData();
    
    const chatMessage = useSelector((store) => store.cabinet.chatMessage);

    function handleNewEvent(event) {
        console.log("LiveChatWidget.onNewEvent", event);
    }


    useEffect(() => {
        const sendMessage = () => {
            if (window.LC_API && window.LC_API.open_mobile_window) {
                window.LC_API.open_mobile_window();
                dispatch(sendChatMessage(false));
            } else {
                setTimeout(sendMessage, 100);
            }
        };

        if (chatMessage) {
            sendMessage();
        }
    }, [chatMessage, dispatch]);

    return (
        <LiveChatWidget
            license="17982108"
            visibility="minimized"
            onNewEvent={handleNewEvent}
        />
    );
};

export default ExternalChat;

