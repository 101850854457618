import React from "react";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import EmptyData from "../_UI/emptyData";
import Moment from "react-moment";



const CabinetPartnersAccruals = () => {

    const { t } = useTranslation();
    const partnersAccruals = useSelector(store => store.cabinet.partnersAccruals);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.cabinetLargeCard}`}>
                <div className={`${Styles.titleRow}`}>
                    <div className={`${Styles.cabinetTitle1}`}>{t('Начисления')}</div>
                </div>
                <div className={`${Styles.tableBox}`}>
                    <div className={`${Styles.tableContent}`}>
                        <div className={`${Styles.tableTitleRow}`}>
                            <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Имя')}</div>
                            <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3} ${Styles.disD}`}>{t('Уровень')}</div>
                            <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3} ${Styles.disD}`}>{t('Процент')}</div>
                            <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Дата')}</div>
                            <div className={`${Styles.tableTitleRowItem} ${Styles.cabinetTitle3}`}>{t('Сумма')}</div>
                        </div>
                        <div className={`${Styles.tableContentBox} ${partnersAccruals?.length > 0 ? Styles.tableContentBoxCenter : null}`}>
                            {partnersAccruals?.length > 0 ? partnersAccruals?.map((item, index)=>{
                                return (
                                    <div className={`${Styles.tableContentBoxItem}`} key={'partnersAccrualsData' + index}>
                                        <div className={`${Styles.tableContentBoxItemValue} ${Styles.tableContentBoxItemName}`}>{item.referral?.name}</div>
                                        <div className={`${Styles.tableContentBoxItemValue} ${Styles.disD} ${Styles.tableContentBoxItemValueWH} ${item.level ? Styles.colorGreen : Styles.colorRed}`}>{item.level}</div>
                                        <div className={`${Styles.tableContentBoxItemValue} ${Styles.disD}`}>{item.percent * 1}%</div>
                                        <div className={`${Styles.tableContentBoxItemValue}`}><Moment format="DD/MM/YYYY">{item.date}</Moment></div>
                                        <div className={`${Styles.tableContentBoxItemValue} ${item.amount * 1 ? Styles.colorGreen : Styles.colorRed}`}>${item.amount ? (item.amount * 1).toFixed(2) : '0'}</div>
                                    </div>
                                )
                            }) : <EmptyData text={t('У вас пока что нет реферальных начислений')}/>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CabinetPartnersAccruals;