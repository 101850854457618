import React from "react";
import Styles from './styles.module.scss';
import {useDispatch} from "react-redux";



const CustomInput = (props) => {
    const {gender, type, title, placeholder, value, setValue, errDispatch, errors, isReadOnly} = props;
    const dispatch = useDispatch();

    const inputGenderHandler = () =>{
        switch (gender){
            case 'input':
                return (
                    <input
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        readOnly={isReadOnly}
                        onChange={(e)=>{
                            if (!isReadOnly){
                                setValue(e.target.value)
                            }
                        }}
                        onKeyDown={(event)=>{
                            if (!isReadOnly){
                                if (errors){
                                    if (errors.full?.[errors.type]){
                                        delete errors.full[errors.type];
                                        dispatch(errDispatch(errors.full));
                                    }
                                }
                            }
                        }}
                    />
                )
            case 'area':
                return (
                    <textarea
                        placeholder={placeholder}
                        value={value}
                        onChange={(e)=>{setValue(e.target.value)}}
                        onKeyDown={(event)=>{
                            if (errors){
                                if (errors.full?.[errors.type]){
                                    delete errors.full[errors.type];
                                    dispatch(errDispatch(errors.full));
                                }
                            }
                        }}
                    />
                )
            default :
                return (
                    <input
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e)=>{setValue(e.target.value)}}
                        onKeyDown={(event)=>{
                            if (errors){
                                if (errors.full?.[errors.type]){
                                    delete errors.full[errors.type];
                                    dispatch(errDispatch(errors.full));
                                }
                            }
                        }}
                    />
                )
        }
    }
    return (
        <div className={`${Styles.inputWrap}`}>
            <div className={`${Styles.inputTitle}`}>{title}</div>
            {inputGenderHandler()}
            <div className={`${Styles.inputErr}`}>{errors?.full?.[errors.type] ? errors?.full[errors.type][0] : null}</div>
        </div>
    );
};

export default CustomInput;