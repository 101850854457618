import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import CabinetHeader from "../../components/cabinetHeader";
import CabinetDeposit from "../../components/cabinetDeposit";
import {useDispatch, useSelector} from "react-redux";
import {getDeposits, getMainStats, getUserData, getWallets} from "../../store/cabinet/action";
import DepositModal from "../../components/depositModal";
import {useNavigate} from "react-router-dom";
import DepositRequestModal from "../../components/depositRequestModal";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const CabinetDepositPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);



    useEffect(()=>{
        if (!authStatus.authorization){
            navigate('/sign-in')
        }
    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getDeposits());
        dispatch(getWallets());
        dispatch(getMainStats());
        dispatch(getUserData());
    },[dispatch])



    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Открыть депозит')}/>
            <div className={`${Styles.contentWrap} ${Styles.cabinetVerticalPadding}`}>
                <CabinetHeader/>
                <CabinetDeposit/>
                <DepositModal/>
                <DepositRequestModal/>
            </div>
        </section>
    );
};

export default CabinetDepositPage;