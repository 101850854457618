export const TEST = 'TEST';
export const SET_LINK_SCROLL_STATUS = 'SET_LINK_SCROLL_STATUS';
export const SET_AUTHORIZATION_STATUS = 'SET_AUTHORIZATION_STATUS';
export const FIRST_NEWS_CALL_STATUS = 'FIRST_NEWS_CALL_STATUS';

export const REGISTRATION_STARTED = 'REGISTRATION_STARTED';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const GET_ABOUT_DATA_STARTED = 'GET_ABOUT_DATA_STARTED';
export const GET_ABOUT_DATA_SUCCESS = 'GET_ABOUT_DATA_SUCCESS';
export const GET_ABOUT_DATA_FAILURE = 'GET_ABOUT_DATA_FAILURE';

export const SEND_CONTACT_FORM_STARTED = 'SEND_CONTACT_FORM_STARTED';
export const SEND_CONTACT_FORM_SUCCESS = 'SEND_CONTACT_FORM_SUCCESS';
export const SEND_CONTACT_FORM_FAILURE = 'SEND_CONTACT_FORM_FAILURE';

export const FORGOT_PASSWORD_STARTED = 'FORGOT_PASSWORD_STARTED';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_STARTED = 'RESET_PASSWORD_STARTED';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const GET_NEWS_STARTED = 'GET_NEWS_STARTED';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAILURE = 'GET_NEWS_FAILURE';

export const GET_ALL_NEWS_STARTED = 'GET_ALL_NEWS_STARTED';
export const GET_ALL_NEWS_SUCCESS = 'GET_ALL_NEWS_SUCCESS';
export const GET_ALL_NEWS_FAILURE = 'GET_ALL_NEWS_FAILURE';
export const SET_ALL_NEWS_LAST_PAGE = 'SET_ALL_NEWS_LAST_PAGE';

export const GET_POST_STARTED = 'GET_POST_STARTED';
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const GET_POST_FAILURE = 'GET_POST_FAILURE';

export const CLEAR_ALL_NEWS = 'CLEAR_ALL_NEWS';







